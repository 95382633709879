#howto-xiden {
  background-color: black;
  line-height: 1.3;
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }

  svg {
    margin: 0 !important;
    margin-left: 10px !important;
  }

  .rand {
    height: 100%;
    p {
      max-width: 440px;
      line-height: 1.1;
    }
  }

  .reward-img {
    max-width: 350px;
    width: 100%;
  }

  .corners {
    .rand {
      align-items: center !important;
      align-content: center !important;
      justify-content: center !important;
    }
  }

  .last-card {
    margin-bottom: 0;
  }

  .link-container {
    cursor: pointer;
    width: fit-content;
    width: -moz-fit-content;

    svg {
      transition: 0.2s;
      color: #22aaff;
    }

    p {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      transition: 0.2s;
    }
    @media screen and (max-width: 991px) {
      margin-top: 1rem;
    }
  }

  .link-container:hover {
    p {
      color: white;
    }
    svg {
      color: white;
    }
  }

  .left,
  .right {
    img {
      margin: auto;
    }
  }

  .title {
    h2 {
      line-height: 1;
    }
  }

  @media screen and (max-width: 991px) {
    .rand {
      flex-direction: column-reverse !important;
      p,
      h2,
      .link-container {
        max-width: unset;
        text-align: center;
        margin: auto;
      }

      .link-container {
        justify-content: center !important;
        p {
          margin: 0 !important;
        }
      }
    }
    .corners {
      height: unset;
      margin: 0 0 1.5rem 0;
    }

    .last-card {
      margin-bottom: 0;
    }

    img {
      width: 50%;
      margin-bottom: 30px !important;
    }

    .market-img {
      width: 25%;
    }

    .title {
      h2 {
        margin-bottom: 1rem;
        margin-top: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    img {
      width: 70%;
      margin-top: 30px !important;
    }

    .market-img {
      width: 40%;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      width: 90%;
      margin-top: 30px !important;
    }

    .market-img {
      width: 60%;
    }
  }
  @media screen and (max-width: 320px) {
    img {
      width: 100%;
      margin-top: 30px !important;
    }

    .market-img {
      width: 80%;
    }
  }
}
