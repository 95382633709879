#howitworks-validator {
  background-color: black;
  line-height: 1.3;
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }

  .title {
    .title-paragraph {
      max-width: 740px;
      margin-bottom: 4rem;
    }
  }

  .column-identifier {
    padding-right: 0 !important;
  }
  .btn-link {
    text-decoration: none;
    width: fit-content;
    width: -moz-fit-content;
    color: #22aaff;
    background: transparent;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.2s;

    svg {
      margin-left: 10px;
    }
    //#174164

    &:hover {
      color: white;
    }
  }

  .row-content {
    //margin-left: 75px;
    //margin-top: 5rem;

    &:first-of-type {
      margin-top: 0;
      @media screen and (max-width: 991px) {
        margin-top: 5rem;
      }
    }
  }

  .column-wrapper {
    position: relative;
  }

  .uppertitle-container {
    position: absolute;
    top: 110px;
    left: -50px;
    transform: rotate(-90deg);
    transform-origin: top left;
  }
  .rotate-uppertitle {
    height: 20px;
    width: 120px;
    // transform: rotate(-90deg) translateY(20px);
    .xideen-upper-title {
      justify-content: center !important;
    }
  }

  img {
    //margin-top: 150px;
    max-width: 320px;
    //margin: auto;
    @media screen and (max-width: 991px) {
      margin-top: 0;
      max-width: 240px;
    }
  }
  .how-it-works-coin-img {
    max-width: 200px;
    margin-left: 3rem;
    @media screen and (max-width: 991px) {
      margin-left: 0;
      margin-top: 0;
      max-width: 160px;
    }
  }

  p {
    padding-right: 1rem;
    @media screen and (max-width: 991px) {
      max-width: 662px;
    }
  }
  h4 {
    line-height: 1;
    @media screen and (max-width: 768px) {
      padding-right: 10px;
    }
  }

  // .step1{
  //         margin-bottom: 75px;
  //     }

  @media screen and (max-width: 991px) {
    .how-it-works-img {
      //width: 60%;
    }
    .uppertitle-container {
      width: 48px;
      left: -15px;
    }

    .column-wrapper {
      height: auto;
      //margin: 2rem 0;

      justify-content: flex-start !important;
      padding-left: 1.5rem;
      @media screen and (max-width: 991px) {
        padding-left: 1rem;
      }
    }

    .row-content {
      margin-left: 0;
    }

    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .title {
      .title-paragraph {
        margin-bottom: 2rem;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .uppertitle-container {
      top: 100px;
    }
  }

  @media screen and (max-width: 550px) {
    // .description-991{
    //     width: unset;
    // }
  }

  @media screen and (max-width: 480px) {
    .how-it-works-img {
      width: 100%;
    }

    .column-wrapper {
      height: 100%;
    }
    .uppertitle-container {
      //top: 135px;
      left: -15px;
    }
    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .uppertitle-container {
      width: 24px;
    }
  }
}
