#properties-guardian-section {
    // .wrapper-coloana{
    //     height: 400px;
    //     border-radius: 24px;
    //     background-color: #161616;
    //     padding-right: 1rem!important;
    // }

    // button{
    //     width: 140px;
    //     background: transparent;
    //     border: none;
    //     transition: .2s;

    //     svg{
    //         margin-left: 10px;
    //     }

    //     &:hover {
    //         color: white;
    //     }
    // }

    .feature-card {
        margin-bottom: 1.5rem;
    }

    .heading-container {
        h2 {
            line-height: 1;
            margin-bottom: 6rem;

            @media screen and (max-width: 991px) {
                margin-bottom: 4rem;
            }
        }
    }

    .pointer {
        span,
        svg {
            transition: 0.2s;
        }
    }

    .pointer:hover {
        span,
        svg {
            color: white !important;
        }
    }

    @media screen and (max-width: 991.5px) {
        .feature-card {
            max-width: unset !important;
        }
    }

    @media screen and (max-width: 580px) {
        .feature-card {
            height: 300px;
        }
    }

    h4{
        @media screen and (max-width: 1280px){
            font-size: 30px;
        }
        @media screen and (max-width: 1150px){
            font-size: 24px;
        }
    }
}
