.krater-app-section {
  background-image: url("./assets/background-desktop.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 940px;

  @media screen and (max-width: 991px) {
    background-image: none;
    min-height: unset;
    --b: 2px;
    --c: #86868b;
    --w: 20px;

    border: var(--b) solid transparent;
    --g: #0000 90deg, var(--c) 0;
    background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--g)) 0 0,
      conic-gradient(from 180deg at top var(--b) right var(--b), var(--g)) 100% 0,
      conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--g)) 0 100%,
      conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g)) 100% 100%;
    background-size: var(--w) var(--w);
    background-origin: border-box;
    background-repeat: no-repeat;
    margin: 0 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .button-download {
    background: linear-gradient(#58ff89, #09eab4);
    width: 268px;
    height: 64px;
    border-radius: 32px;
    border: none !important;

    @media screen and (max-width: 991px) {
      width: 220px;
      height: 48px;
      border-radius: 24px;
    }

    @media screen and (max-width: 340px) {
      width: 180px;
    }

    &:hover {
      background: linear-gradient(#09eab4, #58ff89);
    }
  }

  .validator-app-img {
    position: absolute;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);

    @media screen and (max-width: 1250px) {
      right: 30px;
    }

    @media screen and (max-width: 991px) {
      position: unset;
      top: unset;
      right: unset;
      transform: unset;
    }
  }
}
