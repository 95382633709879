#validator-hero {
  .page-section {
    padding: 5rem 0 6rem 0 !important;

    @media screen and (max-width: 991px) {
      padding-bottom: 0 !important;
    }
  }
  //min-height: 1080px;
  .container-main {
    display: flex;
  }

  .paragraph-container {
    p {
      max-width: 540px;
    }
  }

  h2 {
    line-height: 1;
  }

  @media screen and (max-width: 991px) {
    //height: 1080px;
    .container-main {
      flex-direction: column-reverse;
    }
    .paragraph-container {
      p {
        max-width: unset;
      }
    }
    button {
      margin: auto;
      //margin-left: 0;
    }
  }

  .validator-uppertitle-container {
    @media screen and (max-width: 767px) {
      margin: 10px 0 15px;
    }
  }

  .main-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 2rem;

    @media screen and (max-width: 991px) {
      align-items: center;
      margin-right: 0;
      justify-content: start;
      margin-top: 2rem;

      .paragraph-container {
        p {
          text-align: center;
        }
        max-width: 400px;
      }
    }

    .hero-btn-container {
      width: 100%;
      display: flex;
      align-items: center;

      @media screen and (max-width: 991) {
        width: 100%;
        justify-content: flex-start;
      }

      @media screen and (max-width: 400px) {
        flex-direction: column;
        align-items: flex-start;

        button:first-child {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .section-image-col {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .section-image {
      width: 100%;
      height: auto;
      min-width: 900px;
      transform: translateX(150px);
      @media screen and (max-width: 1750px) {
        min-width: 100%;
        transform: none;
      }
      @media screen and (max-width: 1470px) {
        min-width: 600px;
      }
      @media screen and (max-width: 991px) {
        min-width: 60%;
      }
      @media screen and (max-width: 768px) {
        min-width: 80%;
      }
      @media screen and (max-width: 500px) {
        min-width: 100%;
      }
    }

    @media screen and (max-width: 991px) {
      .section-image {
        max-width: 400px;
      }
      img {
        max-width: unset;
        width: 30% !important;
      }
    }
    @media screen and (max-width: 768px) {
      img {
        max-width: unset;
        width: 40% !important;
      }
    }
    @media screen and (max-width: 480px) {
      img {
        max-width: unset;
        width: 60% !important;
      }
    }
  }
}
