.version-card {
  background-color: #161616;
  max-width: 1440px;
  width: 100%;
  height: auto;
  border-radius: 16px;
  padding: 2rem;
  margin-bottom: 24px;
  .cursor-pointer {
    cursor: pointer;
  }
  &:last-child {
    margin-bottom: 0px;
  }

  p {
    margin-bottom: 0px;
  }

  @media screen and (max-width: 600px) {
    .flex-wrap-changelog {
      flex-wrap: wrap;
    }
    height: auto;
    max-height: unset;
  }

  @media screen and (max-width: 650px) {
    padding: 1rem 1.5rem;
  }

  @media screen and (max-width: 380px) {
    padding: 1.25rem;
  }

  .mobile-changelog-flex {
    @media screen and (max-width: 500px) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 380px) {
      margin-top: 10px;
      flex-direction: column;
      align-items: flex-start !important;
      justify-content: flex-start !important;
    }
  }
  .w-date-device {
    width: 75%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .date-container-change-log {
    background-color: #22aaff;
    color: #000000;
    max-width: 220px;
    max-height: 48px;
    min-height: 48px;
    height: 100%;
    width: 100%;
    min-width: 200px;
    border-radius: 32px;
    margin-right: 49px;
    padding: 10px;
    text-align: center;

    @media screen and (max-width: 650px) {
      max-height: 38px;
      margin-right: 30px;
    }
    @media screen and (max-width: 515px) {
      margin-right: 10px;
    }
    @media screen and (max-width: 500px) {
      margin-top: 10px;
      max-width: 100%;
      margin-right: 0;
      height: 40px;
    }
    @media screen and (max-width: 420px) {
      // max-width: 125px;
    }
    @media screen and (max-width: 380px) {
    }
  }

  .date-container-change-log-single {
    min-width: 200px;
    background-color: #22aaff;
    color: #000000;
    max-width: 220px;
    max-height: 48px;
    height: 100%;
    width: 100%;
    border-radius: 32px;
    padding: 10px;
    text-align: center;
  }
  .device-container-change-log {
    background-color: #bc6afd;
    color: #000000;
    max-width: 220px;
    max-height: 48px;
    min-height: 48px;
    height: 100%;
    width: 100%;
    border-radius: 32px;
    padding: 10px;
    text-align: center;
    margin-right: 49px;
    min-width: 200px;
    &:last-child {
      margin-right: 0px;
    }

    @media screen and (max-width: 650px) {
      max-height: 38px;
      margin-right: 30px;
    }
    @media screen and (max-width: 515px) {
      margin-right: 10px;
    }
    @media screen and (max-width: 500px) {
      margin-top: 10px;
      max-width: 100%;
      margin-right: 0;
      height: 40px;
    }
    @media screen and (max-width: 420px) {
    }
    @media screen and (max-width: 380px) {
      margin-top: 10px;
      max-width: 100%;
      margin-right: 0;
      height: 40px;
    }
  }

  .version-p {
    margin-right: 49px;
    @media screen and (max-width: 650px) {
      margin-right: 30px;
    }
    @media screen and (max-width: 515px) {
      margin-right: 10px;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
      margin-bottom: 10px;
    }

    @media screen and (max-width: 380px) {
      margin-top: 10px;
      margin-right: 0;
    }
  }

  .change-log-fix {
    color: #000000;
    background-color: #af4aff;
    max-width: 74px;
    max-height: 32px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    // margin-top: 25px;
    margin-bottom: 14.5px;
  }
  .change-log-new {
    color: #000000;
    background-color: #20deb0;
    max-width: 74px;
    max-height: 32px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 14.5px;
  }
  .change-log-update-type {
    color: #000000;
    background-color: #ddda08;
    // margin-top: 25px;
    max-height: 32px;
    height: 100%;
    width: fit-content;
    border-radius: 8px;
    margin-bottom: 14.5px;
    padding: 0 15px;
  }
  .device-changelogcard {
    background-color: #bc6afd;
    border-radius: 32px;
    text-align: center;
    height: 41px;
    color: black;
  }
  .change-log-updatetype {
    color: #000000;
    background-color: #f9ca53;
    max-width: 234px;
    max-height: 32px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    // margin-top: 25px;
    margin-bottom: 14.5px;
  }
}

.overview-card {
  background-image: url("../../../assets/images/svg/big-sphere.svg") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
