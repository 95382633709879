.uppertitle-green {
  color: #30deb0;
}

.uppertitle-blue {
  color: #07f5f7;
}

.line-upper-title-blue {
  height: 2px;
  width: 18px;
  background-color: #07f5f7;
  border-radius: 48px;
  margin-right: 15px;
}
.line-upper-title-green {
  @extend .line-upper-title-blue;

  background-color: #30deb0;
}
