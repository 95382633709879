#apps-services {
  .link-btn {
    width: 100%;
    background: transparent;
    border: none;
    transition: 0.2s;

    svg {
      margin-left: 10px;
    }

    // &:hover {
    //   color: white;
    // }
  }
  a {
    text-decoration: none;
    color: unset;
    &:hover {
      color: white;
    }
  }

  .description {
    max-width: 830px;
    margin-bottom: 6rem;

    @media screen and (max-width: 991px) {
      margin-bottom: 4rem;
    }
  }

  .column-wrapper {
    width: 100%;
    max-width: 342px;
    height: 342px;
    background-color: #161616;
    border-radius: 24px;
    margin-bottom: 1.5rem !important;

    // &:last-child {
    //   margin-bottom: 0 !important;
    // }

    @media screen and (max-width: 991px) {
      //margin: auto;
      width: 100%;
      max-width: unset;
    }
    @media screen and (max-width: 768px) {
      margin-left: 0;
    }

    @media screen and (max-width: 576px) {
      max-width: unset;
    }

   
  }

  .logo-container {
    height: 98px;
    width: 98px;
    background-color: black;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
  }

  .social {
    width: 98px;
  }

  .buttonBlue {
    margin: auto;
    margin-top: 1rem;
    svg {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 992px) {
    .link-btn {
      width: 125px;
    }
  }
  @media screen and (max-width: 990px) {
    .link-btn {
      width: unset;
    }
  }
}
