#cl-cards {
  padding: 0 1.5rem;

  // @media screen and (max-width: 350px) {
  //   padding: 15px;
  // }

  .load-more-btn {
    background-color: transparent;
    color: #22aaff !important;
    border-radius: 32px;
    border-color: #22aaff;
    padding: 10px;
    max-width: 220px;
    max-height: 64px;
    width: 100%;
    height: 100%;

    &:hover {
      background-color: #22aaff;
      color: #ffffff !important;
      transition: all 0.3s ease-in-out;
    }
  }
}
