#xiden-features {
  overflow: hidden;
  background-color: black;
  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }

  .description-991 {
    max-width: 50%;
    @media screen and (max-width: 991px) {
      max-width: 60%;
    }
    @media screen and (max-width: 767px) {
      max-width: 75%;
    }
    @media screen and (max-width: 600px) {
      max-width: 90%;
    }
    @media screen and (max-width: 450px) {
      max-width: 100%;
    }
  }

  .content {
    margin-bottom: 40px;
    // p {
    //    width: 708px;
    // }
  }
  .title {
    margin-bottom: 18px !important;
  }

  .coloana {
    margin: 40px 0 40px 0;
    border-radius: 10px;
    transition: 0.2s;
    position: relative;

    h4 {
      transition: 0.2s;
    }
  }

  .coloana:hover {
    h4 {
      //  color: #22aaff;
      text-shadow: 2px 2px 20px rgba(34, 170, 255, 0.6);
    }
  }

  .unhovered::after {
    content: "";
    position: absolute;
    top: 50px;
    left: 90px;
    height: 2px;
    border-radius: 2px;
    background: white;
    animation: underline-out ease-in-out 0.2s forwards;
  }

  .hovered:hover::after {
    content: "";
    position: absolute;
    top: 50px;
    left: 90px;
    height: 2px;
    border-radius: 2px;
    background: white !important;
    z-index: 0;
    animation: underline ease-in-out 0.2s forwards;
  }

  @keyframes underline {
    from {
      width: 0;
    }
    to {
      width: 100px;
    }
  }

  @keyframes underline-out {
    from {
      width: 100px;
    }
    to {
      width: 0px;
    }
  }

  .rand {
    div {
      width: 541px;
    }

    img {
      margin-right: 24px;
    }
  }

  .content-card {
    margin-top: 5px;
    h4 {
      z-index: 10;
    }
  }

  .img-container {
    max-width: 80px;
  }

  .dreapta {
    margin-right: 40px !important;
    margin-left: 200px;
  }

  @media screen and (max-width: 1470px) {
    .rand {
      justify-content: space-around !important;
    }

    .dreapta {
      margin-right: 0px !important;
      margin-left: 0px;
    }

    .rand {
      div {
        width: 450px;
      }
    }
  }

  @media screen and (max-width: 1430px) {
    .hovered:hover::after,
    .unhovered::after {
      top: 40px;
    }
  }

  @media screen and (max-width: 1284px) {
    .title {
      margin-bottom: 15px !important;
      margin-top: 5px !important;
    }
  }
  @media screen and (max-width: 991.5px) {
    .content {
      margin-bottom: 50px;
    }

    .rand {
      justify-content: space-around !important;
    }

    .dreapta {
      margin-right: 0px !important;
      margin-left: 0px;
    }

    .rand {
      div {
        width: 95%;
      }
    }
    // .content p {
    //    max-width: 430px;
    // }
    .unhovered::after,
    .hovered:hover::after {
      content: none;
    }
    .xiden-features-p {
      max-width: 450px;
    }
    .coloana {
      margin-bottom: 0.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    // .content {
    //    p {
    //       width: 100%;
    //       max-width: 100%;
    //    }
    // }
    .rand {
      div {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    img {
      width: 100%;
    }

    .img-container {
      margin-right: 10px;
      max-width: 50px;
      .ico-svg {
        max-width: 40px;
      }
    }

    .coloana {
      margin: 10px 0 20px 0;
    }
  }
}
