#mining-competition-hero {

   background-image: url("../assets/mining-competition-hero-bg.png");
   background-position: top center;
   background-repeat: no-repeat;
   background-size: 2000px;
   
   
   padding-top: 8rem;
   
   @media screen and (max-width: 1500px) {
      background-size: 1500px;
   }

   @media screen and (max-width: 991px) {
      background-size: 1000px;
      padding-top: 4rem;
   }

   .buttonBlue {
      margin-right: 0px !important;
   }

   .node-title-container {
      min-width: 570px;

      @media screen and (max-width: 700px) {
         min-width: unset;
      }
   }

   .staking-hero-img {
      margin-left: auto;

      @media screen and (max-width: 1400px) {
         max-width: 650px;
      }

      @media screen and (max-width: 1250px) {
         max-width: 600px;
      }

      @media screen and (max-width: 1150px) {
         max-width: 500px;
      }

      @media screen and (max-width: 991px) {
         max-width: 50%;
         margin: auto;
      }

      @media screen and (max-width: 650px) {
         max-width: 70%;
      }

      @media screen and (max-width: 450px) {
         max-width: 100%;
      }
   }

   .buttons-flex-direction {
      @media screen and (max-width: 400px) {
         flex-direction: column !important;
         align-items: center !important;
         padding-top: 0 !important;

         .buttonBlue,
         .buttonOutlineBlue {
            margin-left: 0 !important;
            margin-right: 0 !important;
         }

         .buttonBlue {
            margin-bottom: 1rem !important;
         }
      }
   }
}