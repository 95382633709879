.guardian-features {
  @media screen and (max-width: 991px) {
    padding-bottom: 0 !important;
  }

  .description-991 {
    width: 45%;
    @media screen and (max-width: 991px) {
      width: 60%;
    }
    @media screen and (max-width: 767px) {
      width: 80%;
    }
    @media screen and (max-width: 600px) {
      width: 90%;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .font-size-56 {
    font-size: 56px !important;

    @media screen and (max-width: 1500px) {
      font-size: 50px !important;
    }

    @media screen and (max-width: 1400px) {
      font-size: 47px !important;
    }

    @media screen and (max-width: 1230px) {
      font-size: 40px !important;
    }

    @media screen and (max-width: 1068px) {
      font-size: 35px !important;
    }

    // slider start

    @media screen and (max-width: 991px) {
      font-size: 26px !important;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px !important;
    }
  }

  .wrapper-coloana {
    background-color: #161616;
    border-radius: 24px;
    min-height: 388px;
    margin: 12px 0 12px 0;
    padding: 3rem;
    width: 100%;

    @media screen and (max-width: 1300px) {
      padding: 2rem;
    }

    @media screen and (max-width: 991px) {
      padding: 1.5rem;
      margin: 0;
      min-width: 300px;
      min-height: 330px;
    }
    @media screen and (max-width: 375px) {
      min-width: 257px;
    }
    @media screen and (max-width: 335px) {
      min-width: 240px;
    }
    @media screen and (max-width: 310px) {
      min-width: 220px;
    }
    @media screen and (max-width: 285px) {
      min-width: 210px;
    }
  }

  .slider-card {
    margin-right: 1rem;
  }

  .guardian-features-slider {
    .slider-height {
      @media screen and (max-width: 991px) {
        height: 425px;
      }
    }
  }

  .get-started-button {
    width: 160px;
    color: #22aaff;
    background: transparent;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.2s;

    svg {
      margin-left: 10px;
    }

    &:hover {
      color: white;
    }
  }

  // .content-card {
  //    // max-width: 322px;

  //    @media screen and (max-width: 1200px) {
  //       max-width: unset;
  //    }
  // }
  .content-card-two {
    max-width: 400px;

    @media screen and (max-width: 1200px) {
      max-width: unset;
      br {
        display: none;
      }
    }
  }

  .coloana-r2 {
    height: 580px;
    justify-content: space-between !important;
  }

  .coloana-r2-c2-c1 {
    height: 388px;
    display: flex;
    justify-content: space-between !important;
  }

  .coloana-r2-c2-c2 {
    height: 388px;
    display: flex;
    justify-content: space-between !important;
  }

  .coloana-r3 {
    height: 388px;
    justify-content: space-between !important;

    .content {
      height: 100%;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .small-cube {
      width: 25%;
    }
  }

  .transaction-card {
    height: 800px;
  }

  .coloana-r3-c2 {
    background-color: white;
    height: 388px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-image: url("../../../assets/images//png/low-energy-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;

    span {
      color: #20deb0;
    }

    button {
      color: #20deb0;
    }
    button:hover {
      color: white;
    }
  }

  .get-started-mobile {
    span {
      color: #20deb0;
    }

    button {
      color: #20deb0;
    }
    button:hover {
      color: white;
    }
  }

  .low-energy {
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0), #de7488);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .plant-link {
    background-color: transparent;
    outline: none;
    border: none;
  }

  .guardian-features-card {
    @media screen and (max-width: 991px) {
      height: 300px !important;
    }
  }

  .card-icons {
    svg {
      @media screen and (max-width: 991px) {
        width: 45px;
        height: 45px;
      }
    }
  }

  .features-description {
    @media screen and (max-width: 991px) {
      max-width: 240px;
      padding-bottom: 1rem;
    }
  }

  //BACKGROUNDS
  .bg1 {
    background-image: url("../../../assets/images/png/router-apps-bg1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg2 {
    background-image: url("../../../assets/images/png/router-apps-bg2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg3 {
    background-image: url("../../../assets/images/png/router-apps-bg3.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg4 {
    background-image: url("../../../assets/images/png/router-apps-bg4.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg5 {
    background-image: url("../../../assets/images/png/router-apps-bg5.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg6 {
    background-image: url("../../../assets/images/png/transaction-card-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg7 {
    background-image: url("../../../assets/images/png/power-card-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg8 {
    background-image: url("../../../assets/images//png/low-energy-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
    background-color: #fff;
  }
}
