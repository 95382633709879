.hero-section-guardian-node {
  &.page-section {
    padding-top: 0;

    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }
  }
  .buttonBlue {
    margin-right: 0px !important;
  }

  .node-title-container {
    min-width: 570px;

    @media screen and (max-width: 700px) {
      min-width: unset;
    }
  }

  .minter-guardian-uppertitle-container {
    @media screen and (max-width: 767px) {
      margin: 10px 0 15px;
    }
  }

  .staking-hero-img {
    margin-left: auto;
    @media screen and (max-width: 1400px) {
      max-width: 650px;
    }
    @media screen and (max-width: 1250px) {
      max-width: 600px;
    }
    @media screen and (max-width: 1150px) {
      max-width: 500px;
    }
    @media screen and (max-width: 991px) {
      max-width: 50%;
      margin: auto;
    }
    @media screen and (max-width: 650px) {
      max-width: 70%;
    }
    @media screen and (max-width: 450px) {
      max-width: 100%;
    }
  }

  .buttons-flex-direction {
    @media screen and (max-width: 400px) {
      flex-direction: row;
      align-items: center !important;
      padding-top: 0 !important;
    }
  }
}
