.reward-calculator-long {
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding: 3rem;
  border-radius: 16px;
  background-image: url("../../assets/images/png/purple-gradient.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 991px) {
    max-width: 600px;
    margin: auto;
    // margin-bottom: 3rem;
  }

  // .reward-calculator-content {
  //    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  //       opacity: 1;
  //    }
  //    opacity: 0.1;
  // }

  .calculator-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
      background-color: rgba(0, 0, 0, 0.2);
    }

    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background: rgba(0, 0, 0, 0.5);
  }

  .calculate-reward-separator {
    height: 1px;
    width: 100%;
    background-color: rgba(34, 170, 255, 0.7);
  }

  .calculate-reward-separator-middle {
    height: 1px;
    width: 100%;
    background-color: rgba(175, 74, 255, 0.3);
  }

  .total-txt {
    background: -webkit-linear-gradient(45deg, #22aaff, #af4aff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .toggle-txt {
    @media screen and (max-width: 450px) {
      text-align: center;
    }
  }

  .middle-inputs {
    @media screen and (max-width: 500px) {
      flex-direction: column;

      .calculator-checkbox {
        margin-right: 1rem;
      }
      .mi-flex-row {
        flex-direction: row !important;
        justify-content: space-between;
        margin-right: 0 !important;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        &-div {
          flex-direction: row-reverse;
          &-span {
            margin-left: 0 !important;
            margin-right: 0.5rem;
          }
        }
      }
    }
    @media screen and (max-width: 340px) {
      .calculator-checkbox {
        margin-right: 0.5rem;
      }

      .mi-flex-row-div {
        margin-right: 0.5rem;
      }
    }
  }

  .mb-last-input {
    @media screen and (max-width: 420px) {
      margin-bottom: 1rem !important;
    }
  }

  .toggle-container {
    .toggleWrapper {
      margin-bottom: auto;
      margin-top: 0.5rem;
    }
    @media screen and (max-width: 320px) {
      flex-direction: column;

      .toggleWrapper {
        margin-bottom: 0;
        margin-top: 0;
      }
      .private-public-container {
        margin-left: 0 !important;
        margin-right: 0 !important;

        input {
          text-align: center;
        }
      }
      .private-difficulty {
        margin-bottom: 8px;
      }

      .public-number {
        margin-top: 0.5rem;
      }
      span {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  .with-arrows {
    padding-right: 25px !important;
  }

  .calculateInputs {
    width: 100%;
    max-width: 160px;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 8px;
    padding: 0.5rem;
    background-color: #04043f;
    color: #ffffff;
    font-size: 20px;
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    text-align: end;

    // -webkit-user-select: none; /* Safari */
    // -moz-user-select: none; /* Firefox */
    // -ms-user-select: none; /* IE10+/Edge */
    // user-select: none; /* Standard */

    @media screen and (max-width: 1200px) {
      font-size: 25px;
    }
    @media screen and (max-width: 1100px) {
      font-size: 23px;
    }

    @media screen and (max-width: 600px) {
      height: 40px;
      max-width: 130px;
      font-size: 21px;
    }
    @media screen and (max-width: 450px) {
    }
    @media screen and (max-width: 420px) {
      // margin-left: 0.75rem;
      font-size: 19px;
    }
    @media screen and (max-width: 350px) {
      max-width: 110px;
      font-size: 17px;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .calc-button {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    span {
      width: fit-content;
      width: -moz-fit-content;
    }
  }

  @media screen and (max-width: 1080px) {
    // .calc-heading {
    //    font-size: 30px;
    // }

    .setting-item {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 400px) {
    // .calc-heading {
    //    font-size: 26px;
    // }

    .setting-item {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 300px) {
    .setting-item {
      font-size: 15px;
    }
  }

  //***************************************************** CHECK BOX
  .calculator-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid white;
  }

  input[type="number"],
  input[type="text"] {
    -webkit-opacity: 1;
    opacity: 1;
    -webkit-text-fill-color: white;
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: transparent;
    margin: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 14px;
    height: 14px;
    transform: scale(0);
    border-radius: 50%;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #22aaff;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  .darkgrey-color {
    color: #86868b !important;
    -webkit-text-fill-color: #86868b !important;
  }

  .custom-button {
    max-width: unset;
    font-size: 24px;

    @media screen and (max-width: 1200px) {
      font-size: 22px;
    }
    @media screen and (max-width: 991px) {
      font-size: 20px;
    }
    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
    @media screen and (max-width: 350px) {
      font-size: 14px;
    }
  }

  .custom-button-redirect {
    max-width: unset;
    margin-bottom: 1rem;
    border: 2px solid white;
    color: white;
    font-size: 24px;
    margin-left: 0 !important;
    margin-right: 0 !important;

    &:hover {
      background-color: white;
      color: black;
    }

    @media screen and (max-width: 1200px) {
      font-size: 22px;
    }
    @media screen and (max-width: 991px) {
      font-size: 20px;
    }
    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
    @media screen and (max-width: 350px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 400px) {
    br {
      display: inherit !important;
    }
  }

  .my-refresh-button {
    padding: 0;
    border: none;
    background: transparent;
  }

  .custom-input-container {
    .arrows {
      top: 50%;
      right: 5px;
      transform: translate(0, -50%);

      height: 80%;

      .arrows-inputs {
         width: 12px !important;
         height: 100% !important;
        cursor: pointer;
      }
    }
  }

  .warning-svg{
    width: 23px;
    height: 23px;
    color: orange;
 }
}
