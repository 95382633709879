#stake-support-announcement {
    overflow: hidden;

    .stake-support-red-color {
        color: #FF003A;
    }

    .stake-support-purple-color {
        color: #7E00FF;
    }

    .xideen-upper-title {
        position: absolute;
        top: 0;
        left: 0;

        @media screen and (max-width: 650px) {
            position: unset;
            top: unset;
            left: unset;
            margin-top: 1.5rem;
        }
    }

    .contract-card {
        width: 100%;
        min-height: 630px;
        height: 630px !important;
        display: flex;
        background-image: url("../../../assets/images/png/stake-support-announcement-bg.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @media screen and (max-width: 1300px) {
            min-height: 560px;
            height: 560px !important;
        }

        @media screen and (max-width: 1100px) {
            min-height: 530px;
            height: 530px !important;
        }

        @media screen and (max-width: 991px) {
            min-height: 400px;
            height: 400px !important;
        }

        @media screen and (max-width: 767px) {
            min-height: 360px;
            height: 360px !important;
        }

        @media screen and (max-width: 650px) {
            flex-direction: column;
            min-height: auto;
            height: auto !important;
        }

    }

    .prop-image-col {
        @media screen and (max-width: 1300px) {
            height: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media screen and (max-width: 650px) {
            width: 100%;
        }
    }

    .prop-cube-img {
        position: absolute;
        bottom: 0;
        right: 0;

        @media screen and (max-width: 1300px) {
            position: unset;
            right: unset;
            bottom: unset;
            height: 100%;
        }

        @media screen and (max-width: 1100px) {
            height: unset;
            max-width: 350px;
            width: 100%;
        }

        @media screen and (max-width: 991px) {
            max-width: 280px;
        }

        @media screen and (max-width: 767px) {
            max-width: 240px;
        }
    }

    .prop-feature-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;

        @media screen and (max-width: 650px) {
            width: 100%;
            align-items: center;
        }
    }


    .column-wrapper {
        background-color: #161616;
        border-radius: 24px;
        height: 400px;
        margin-bottom: 1.5rem;

        h4 {
            line-height: 1;

        }
    }


    .proprietary-title-main-card {
        min-width: 770px;

        @media screen and (max-width: 1484px) {
            min-width: unset;
        }

        @media screen and (max-width: 650px) {
            text-align: center;
        }

    }


    @media screen and (max-width: 650px) {
        .prop-feature-info {
            order: 2;
        }
    }

    @media screen and (max-width: 380px) {
        br {
            display: none;
        }
    }
}