#features-xiden-section-mobile {
  background: black;
  line-height: 1.3;

  @media screen and (min-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }

  .font-size-56 {
    font-size: 56px;
    @media screen and (max-width: 1400px) {
      font-size: 50px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 40px !important;
    }
    @media screen and (max-width: 991px) {
      font-size: 35px !important;
    }
    @media screen and (max-width: 767px) {
      font-size: 28px !important;
    }
    @media screen and (max-width: 600px) {
      font-size: 25px !important;
    }
  }

  .slider-div {
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    cursor: grab;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slider-height {
    height: 550px;

    @media screen and (max-width: 767px) {
      height: 371px;
    }
    @media screen and (max-width: 520px) {
      height: 400px;
    }

    @media screen and (max-width: 420px) {
      height: 350px;
    }
  }
  .descriptions-container {
    width: 100%;
    display: flex;
    margin-top: 4rem;
    flex-direction: row !important;

    @media screen and (max-width: 991px) {
      margin-top: 3rem;
    }
  }

  .link-btn {
    width: fit-content;
    width: -moz-fit-content;
    color: #22aaff;
    background: transparent;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.2s;

    svg {
      margin-left: 10px;
    }

    &:hover {
      color: white;
    }
  }

  .link-col {
    min-width: 170px;
  }

  .column-wrapper {
    background-color: #161616;
    border-radius: 24px;
    min-height: 388px;
    margin: 12px 0 12px 0;

    @media screen and (max-width: 991px) {
      margin: 0 0 1.5rem 0;
      min-height: auto;
    }

    @media screen and (max-width: 767px) {
      min-width: 350px;
      width: 350px;
      min-height: 330px;
      max-height: 330px;
      height: 330px;
      margin-right: 1rem;
    }

    @media screen and (max-width: 520px) {
      min-height: 350px;
      max-height: 350px;
      height: 350px;
    }

    @media screen and (max-width: 435px) {
      min-width: 280px;
      min-height: 330px;
      max-height: 330px;
      height: 330px;
    }
    @media screen and (max-width: 355px) {
      width: 220px;
      min-width: 220px;
      min-height: 325px;
      max-height: 325px;
      height: 325px;
    }
    @media screen and (max-width: 310px) {
      width: 220px;
      margin-right: 0.75rem;
    }

    h4 {
      line-height: 1;
    }

    .text-shadowed {
      text-shadow: 0px 0px 4px black;
    }
  }

  .crate-img {
    @media screen and (max-width: 767px) {
      max-width: 120px;
    }
  }

  .img-title {
    @media screen and (max-width: 767px) {
      max-width: 250px;
    }

    @media screen and (max-width: 435px) {
      margin-top: 2rem;
      max-width: 195px;
    }
  }

  .title {
    margin-bottom: 2rem;
  }

  .coloana-r2 {
    height: 800px;
    justify-content: space-between !important;
  }

  .img-and-title {
    @media screen and (max-width: 991px) {
      flex-direction: row !important;
      h4 {
        margin-top: 50px;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column !important;
      h4 {
        margin-bottom: 2rem;
        margin-top: 0;
      }

      .custom-margin {
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: 500px) {
      flex-direction: column-reverse !important;
      align-items: flex-start !important;
      img {
        margin-left: 0 !important;
        margin-bottom: 1rem !important;
      }
      p {
        margin-bottom: 1.5rem;
      }
      h4 {
        margin-top: 0;
        // margin-bottom: 2.5rem;
      }
      margin-top: 30px;
    }
  }

  .coloana-r2-c2-c1 {
    height: 388px;
    display: flex;
    justify-content: space-between !important;
  }

  .coloana-r2-c2-c2 {
    height: 388px;
    display: flex;
    justify-content: space-between !important;
  }

  .coloana-r3 {
    height: 388px;
    justify-content: space-between !important;

    .content {
      height: 100%;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .content-img {
      margin: auto;
    }

    .small-cube {
      width: 25%;
    }

    .validation-img {
      //margin-top: 50px;
      margin-left: 100px;
    }
  }

  .green-color {
    color: #20deb0;
  }

  //BACKGROUNDS_______________________________________________________________________________________________________
  .bg1 {
    background-image: url("../../../assets/images/png/xiden-features-blurp-bg.png");
    background-position: top left;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .bg2 {
    background-image: url("../../../assets/images/png/big-sphere.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg3 {
    background-image: url("../../../assets/images/png/XidenFeatures-small-sphere.png");
    //background-image: url("../../../assets/images/png/sphere-bg.png");
    //background-size: cover;
    background-repeat: no-repeat;
    background-position: calc(100% + 150px) calc(100% + 100px);
  }
  .bg4 {
    background-image: url("../../../assets/images/png/XidenFeatures-long-blurp.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: calc(50% - 25px) 50%;
    margin-bottom: 0;
    @media screen and (max-width: 991px) {
      margin-bottom: 24px;
    }
  }
  .bg5 {
    background-image: url("../../../assets/images/png/XidenFeatures-cryptography.png"),
      url("../../../assets/images/png/xid-features-lighten-bg.png");
    background-blend-mode: luminosity, normal;
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: cover;
  }
  .bg6 {
    background-image: url("../../../assets/images/png/xiden-features-card-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-bottom: 0;

    @media screen and (max-width: 991px) {
      background-image: none;
      height: 100% !important;
      .decentralized-img {
        display: inherit !important;
        margin: auto;
        margin-bottom: 1rem;
      }
    }
  }
  //END BACKGROUNDS_______________________________________________________________________________________________________

  .cube-img {
    max-width: 90%;
    margin-left: auto;

    @media screen and (max-width: 991px) {
      max-width: 80%;
    }
    @media screen and (max-width: 767px) {
      max-width: 120px !important;
    }
  }

  @media screen and (max-width: 1281px) {
    .titles {
      line-height: 1;
    }
  }

  @media screen and (max-width: 991px) {
    .title {
      margin-bottom: 20px;
    }

    h2 {
      margin-top: 0 !important;
    }

    .validation-img {
      margin: 0 !important;
    }
    .coloana-r2 {
      height: auto;
    }

    .coloana-r3 {
      .content-img {
        margin: auto;
      }
    }
  }
}
