.cryptodata-setup {
  background-color: #0e3953;
  color: #22aaff;
  margin-top: 1rem;
  border-radius: 16px;
  padding: 5px 5px;
  max-width: 287px;

  @media screen and (max-width: 991px) {
    padding: 8px 15px;
  }

  @media screen and (max-width: 383px) {
    align-items: flex-start !important;
    padding: 2px 12px;
    margin-bottom: 10px;
  }
}

.lock-svg-krater {
  margin-top: 4px;
}

.crypto-setup-hidr-link,
.setup-validator-link {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #fff;
    transition: 0.4s;
  }
  //   &:visited {
  //     color: #fff;
  //   }
}

.inactive-color-krater-steps {
  color: #ff4a4a;
}

.active-color-krater-steps {
  color: #20deb0;
}

.setup-validator-color {
  color: #22aaff;
  &:hover {
    color: #fff;
    transition: 0.4s;
  }
}
