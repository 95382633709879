.rewards-info-section {
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  .info-row {
    width: 100%;
    display: flex;
  }
  p {
    line-height: 1;
  }

  .box {
    height: 624px;
    padding: 3rem;

    @media screen and (max-width: 1490px) {
      height: 500px;
    }

    @media screen and (max-width: 1200px) {
      height: 400px;
    }
    @media screen and (max-width: 767px) {
      height: auto;
      padding: 1.5rem;
    }
    @media screen and (max-width: 500px) {
      padding: 1.5rem;
    }
  }

  .transparent-darkgrey-color {
    color: rgba(255, 255, 255, 0.3);
  }

  .small-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    border-radius: 24px;
    margin-bottom: 1.5rem;
    background-color: #161616;
    overflow: hidden;

    @media screen and (max-width: 991px) {
      margin-bottom: 1.5rem;
    }
  }

  .large-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-image: url("../../../assets/images/png/lg-box-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .special-bg-1 {
    background-color: #501779;
  }

  .special-bg-2 {
    background-image: url("../../../assets/images/png/big-sphere.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  .card-icon {
    width: 100%;
    max-width: 80px;
    height: auto;
    margin: 4rem 0;

    @media screen and (max-width: 1490px) {
      max-width: 70px;
      margin: 3rem 0;
    }

    @media screen and (max-width: 1200px) {
      max-width: 60px;
      margin: 2rem 0;
    }
    @media screen and (max-width: 991px) {
      margin: 2rem 0;
    }

    @media screen and (max-width: 320px) {
      max-width: 50px;
    }
  }

  .titleSize {
    font-size: 56px;

    @media screen and (max-width: 1490px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 30px;
    }
    @media screen and (max-width: 320px) {
      font-size: 24px;
    }
  }

  .large-box-titleSize {
    font-size: 80px;

    @media screen and (max-width: 1490px) {
      font-size: 60px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 50px;
    }
    @media screen and (max-width: 991px) {
      font-size: 30px;
    }
  }

  .lock-icon {
    margin: 4rem 0;

    @media screen and (max-width: 1490px) {
      margin: 3rem 0;
    }
    @media screen and (max-width: 1200px) {
      margin: 2rem 0;
    }
  }

  .gradient-text {
    @media screen and (max-width: 1490px) {
      background-size: 70%;
    }
    @media screen and (max-width: 1340px) {
      background-size: 80%;
    }
    @media screen and (max-width: 1230px) {
      background-size: 90%;
    }
    @media screen and (max-width: 991px) {
      background-size: 70%;
    }
    @media screen and (max-width: 767px) {
      background-size: 50%;
    }
    @media screen and (max-width: 450px) {
      background-size: 80%;
    }
  }
}
