.App {
   position: relative;
   background-color: #000000;

   button:focus,
   button:focus:active {
      outline: none;
      box-shadow: none;
   }
}

.pointer {
   cursor: pointer;
}

.height100vh {
   @media screen and (min-width: 768px) {
      height: 100vh;
   }
}

.line-height-11 {
   line-height: 1.1 !important;
}

// page section
.page-section {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 6rem 0;
   background-color: #000000;
   color: #ffffff;

   @media screen and (max-width: 991px) {
      padding: 4rem 0;
   }
}

// Two columns Hero sections
.hero-section-container {
   display: flex;

   @media screen and (max-width: 767px) {
      flex-direction: column;
   }

   .main-col {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 2rem;

      @media screen and (max-width: 767px) {
         // align-items: center;
         margin-right: 0;
         margin-bottom: 3rem;

         // h2 {
         //    text-align: center;
         // }

         .paragraph-container {
            width: 100%;
            max-width: 500px;
            // margin: auto;
            // text-align: center;
         }
      }

      .hero-btn-container {
         width: 100%;
         display: flex;
         align-items: center;

         // @media screen and (max-width: 767px) {
         //    width: 100%;
         //    justify-content: center;
         // }

         // @media screen and (max-width: 400px) {
         //    flex-direction: column;
         //    align-items: center;

         //    button:first-child {
         //       margin-right: 0 !important;
         //       margin-bottom: 1.5rem;
         //    }
         // }
      }
   }

   .section-image-col {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .section-image {
         width: 100%;
         max-width: 800px;
         height: auto;

         @media screen and (max-width: 767px) {
            max-width: 500px;
         }
      }
   }
}

// corners
.corners {
   height: 423px;
   padding: 40px;
   margin: 39px 0 39px 0;
   --b: 2px;
   --c: #86868b;
   --w: 20px;

   border: var(--b) solid transparent;
   --g: #0000 90deg, var(--c) 0;
   background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--g)) 0 0,
      conic-gradient(from 180deg at top var(--b) right var(--b), var(--g)) 100% 0,
      conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--g)) 0 100%,
      conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g)) 100% 100%;
   background-size: var(--w) var(--w);
   background-origin: border-box;
   background-repeat: no-repeat;

   @media screen and (max-width: 500px) {
      padding: 1.5rem;
   }
}

// get started link
.getStartedLink {
   display: flex;
   align-items: center;
   background-color: transparent;
   border: none;
   outline: none;
   color: #22aaff;
   cursor: pointer;
   transition: all 0.2s ease-in-out;

   .arrow-icon {
      font-size: 1.2rem;
   }

   &:hover {
      color: #ffffff !important;
   }
}

// react toastify
.Toastify__toast-theme--light {
   background-color: #124477 !important;
   background-image: url("./assets/images/png/toast-bg.png") !important;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   color: #ffffff !important;
   border-radius: 10px !important;
}

.Toastify__close-button--light {
   color: #ffffff !important;
}

// tooltips
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
   border-bottom-color: #22aaff !important;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
   border-top-color: #22aaff !important;
}

//fix for slider on mac

.slick-slide, .slick-slide *{ outline: none !important; }
.slick-slide {
   &:hover,
   &:focus,
   &:active,
   &:focus-visible,
   &:focus-within,
   &:focus:active {
      border: none !important;
      box-shadow: none !important;
      text-shadow: none !important;
      outline: none !important;
   }
}
