#meta-realm-slider {
  overflow: hidden;

  .page-section {
    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }
  }

  // .page-section{
  //     @media screen and (max-width: 1500px){
  //         // padding-top: 0;
  //     }
  // }

  .description-991 {
    @media screen and (max-width: 991px) {
      max-width: 600px;
      width: 100%;
    }
  }

  .meta-realm-slider {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    height: -moz-fit-content;
    .banner {
      max-width: 2500px;
      width: 100%;
      @media screen and(min-width: 2500px) {
        border-radius: 25px;
      }
    }
    .container-main {
      display: flex;
      justify-content: space-between;
      padding-bottom: 6rem;
      margin: auto;
      h2 {
        line-height: 1;
      }
      p {
        line-height: 1.1;
      }

      .paragraph-container {
        max-width: 488px;
      }

      @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
        padding-bottom: 1.5rem;
      }

      .main-col {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 2rem;

        @media screen and (max-width: 991px) {
          align-items: center;
          margin-right: 0;
          //margin-bottom: 3rem;
          justify-content: flex-start;

          h2 {
            text-align: center;
          }

          .paragraph-container {
            width: 100%;
            max-width: 400px;
            margin: auto;
            text-align: center;
          }
        }

        .hero-btn-container {
          width: 100%;
          display: flex;
          align-items: center;

          @media screen and (max-width: 991px) {
            width: 100%;
            justify-content: center;
          }

          @media screen and (max-width: 320px) {
            flex-direction: column;
            align-items: center;

            button:first-child {
              margin: 0 !important;
            }
            .buttonOutlineBlue {
              margin: 1rem 0 0 0 !important;
            }
          }
        }
      }

      .section-image-col {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .section-image {
          width: 100%;
          max-width: 500px;
          height: auto;

          @media screen and (max-width: 991px) {
            margin-bottom: 48px;
            width: 40%;
          }
          @media screen and (max-width: 768px) {
            width: 50%;
          }
          @media screen and (max-width: 480px) {
            width: 60%;
          }
          @media screen and (max-width: 350px) {
            width: 70%;
          }
        }
      }

      @media screen and(max-width: 600px) {
        min-height: unset;
      }
    }

    display: flex;

    // @media screen and (min-width: 768px) {
    // }

    .banner-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        background-color: rgba(0, 0, 0, 0.2);
      }

      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
      background: rgba(0, 0, 0, 0.5);

      .font-size-card-title,
      .dots {
        color: white;
        text-shadow: 0px 0px 10px #000000;
      }
      .letter-bg {
        background: black;
      }
    }

    .banner {
      min-height: 180px;
      position: relative;
      display: flex;
      color: black;
      background: linear-gradient(to right, #674bdd, #22aaff);
      // background-image: url("../../../assets/images/png/banner-bg.png");
      // background-position: center;
      // background-size: cover;
      // background-repeat: no-repeat;

      @media screen and (max-width: 850px) {
        flex-direction: column;
        min-height: unset;
        .banner-column {
          width: unset !important;
        }
        .banner-item {
          flex-direction: row;
          justify-content: space-between;
          text-align: left;
          border-bottom: 1px solid #000000;
        }
      }
      @media screen and (max-width: 767px) {
        margin-top: 2rem;
      }
    }

    .banner-column {
      border-right: 1px solid black;

      @media screen and (max-width: 520px) {
        flex-direction: column !important;
      }
    }

    .banner-item {
      flex: 1;
      padding: 2rem;
      display: flex;
      align-items: center;
      min-height: 180px;
      justify-content: space-between;

      &:not(:last-child) {
        border-right: 1px solid #000000;
      }

      @media screen and (max-width: 850px) {
        min-height: unset;
      }

      @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        opacity: 1;
        .blur-me {
          color: black;
          text-shadow: none;
        }
      }
      opacity: 0.2;

      .blur-me {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      }

      @media screen and (max-width: 1300px) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 1rem;
      }

      @media screen and (max-width: 600px) {
        flex-direction: row;
        //padding: 2rem;
        border-right: 0;
        text-align: left;
        justify-content: space-between;

        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        // &:not(:last-child) {
        // }
      }

      @media screen and (max-width: 520px) {
        &:not(:last-child) {
          border-right: none;
        }
      }
    }

    .number-span {
      font-size: 40px;

      @media screen and (max-width: 1550px) {
        font-size: 30px;
      }
      @media screen and (max-width: 800px) {
        font-size: 26px;
      }
    }
  }

  .slider-col {
    min-height: 500px;
    @media screen and (max-width: 991px) {
      min-height: 630px;
    }
    @media screen and (max-width: 600px) {
      min-height: 500px;
    }
  }
  .active-box-title {
    width: 200px;
  }
  .bottom-0 {
    bottom: -50px;
    position: absolute;
  }
  .pos-absolute-slider {
    height: 540px;
    min-height: 540px;
    position: relative;
    @media screen and (max-width: 991px) {
      // height: 600px;
      // min-height: 600px;
    }
    @media screen and (max-width: 600px) {
      height: 500px;
      min-height: 500px;
    }
    @media screen and (max-width: 350px) {
      height: 470px;
      min-height: 470px;
    }
  }

  @media screen and (min-width: 992px) {
    .pos-absolute-slider {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      left: 60px;
    }
  }

  .slick-track {
    height: 100%;
  }

  @media screen and (min-width: 1450px) {
    .pos-absolute-slider {
      width: 128%;
    }
  }

  .what-contianer {
    display: flex;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }

  .info-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 992px) {
      align-items: center;
      margin-right: 0;
    }
  }

  .buttons-container {
    @media screen and (max-width: 992px) {
      justify-content: center;
    }
  }

  .slider-box {
    position: relative;
    height: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    // background-color: #161616;
    background-image: url("../../../assets/images/png/meta-realm-page/slider-section-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 2rem;
    margin: 1.5rem;
    border-radius: 16px;
    transition: all 0.2s ease-in-out;
    @media screen and (max-width: 991px) {
      height: 280px;
    }
    @media screen and (max-width: 600px) {
      margin: 1rem;
    }
    @media screen and (max-width: 350px) {
      padding: 2rem;
    }
  }

  .title-dot {
    color: #86868b;
  }

  .slide-image {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -15%);
    width: 60%;
    max-width: 260px;
    // mix-blend-mode: difference;
    opacity: 0.5;
    height: auto;
    @media screen and (max-width: 991px) {
      width: 45%;
    }
  }

  .active-box-title {
    font-size: 32px;
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
  }
  .slick-active {
    .slider-box {
      background-image: url("../../../assets/images/png/meta-realm-page/slider-section-active-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 400px;
      overflow: visible;
      transition-delay: 0.5s;

      @media screen and (max-width: 991px) {
        height: 360px;
      }

      @media screen and (max-width: 600px) {
        height: 340px;
      }
      .slide-image {
        width: 85%;
        transition-delay: 0.5s;
        mix-blend-mode: normal;
      }
      .title-dot {
        color: #22aaff;
      }
    }
    .slide-image {
      width: 50%;
      transition-delay: 0.5s;
      opacity: 1;
      @media screen and (max-width: 991px) {
        width: 50%;
      }
    }
  }
  .slick-slide {
    min-height: 480px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 600px) {
      min-height: 410px;
    }
    @media screen and (max-width: 350px) {
      min-height: 440px;
    }
  }
  .slick-list {
    overflow: hidden;
  }
  .slick-track {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .slick-list {
    @media screen and (max-width: 991px) {
      max-height: 485px;
    }
  }

  .apply-effect {
    animation: fadeEffect ease-in 0.5s forwards;
  }

  @keyframes fadeEffect {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }
}
