#guardian-steps-section {
  .hts-card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    // &:not(:last-child) {
    //    margin-bottom: 4rem;
    // }

    .rotate-uppertitle {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%) rotate(-90deg);
      left: -40px;
      .xideen-upper-title {
        justify-content: center !important;
      }

      &.right-0 {
        right: -40px;
        left: unset;
      }
    }

    @media screen and (max-width: 767px) {
      min-height: auto;
      height: auto;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 1.5rem !important;
      margin-top: 0 !important;
    }
  }

  .text-start {
    max-width: 580px !important;
  }

  .link-container {
    a {
      text-decoration: none;
      transition: 0.2s;
      cursor: pointer;

      &:hover {
        color: white;
      }
    }
    text-decoration: none;
    width: fit-content;

    @media screen and (max-width: 330px) {
      flex-direction: column;
      justify-content: center !important;
      align-items: flex-start !important;
      a:last-child {
        padding-top: 0.2rem;
      }
    }
    .guardian-hover {
      text-decoration: none;
      width: fit-content;
      cursor: pointer;
      &:hover {
        color: white;
      }
      @media screen and (max-width: 767px) {
        margin-right: 0px !important;
      }
    }
  }

  .hts-card-col {
    flex: 1;
    @media screen and (max-width: 767px) {
      flex: unset !important;
    }
  }

  .marginRight {
    margin-right: 2rem;
    @media screen and (max-width: 767px) {
      margin-right: 0rem;
    }
  }

  .description-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 22px;
    @media screen and (max-width: 767px) {
      padding-left: 0;
    }
    @media screen and (max-width: 350px) {
      .font-size-40 {
        font-size: 21px;
      }
    }
  }

  .hts-p-container {
    width: 100%;
    max-width: 600px;

    @media screen and (max-width: 767px) {
      text-align: center !important;
    }
  }

  .hts-image {
    @media screen and (max-width: 991px) {
      max-width: 90%;
    }
  }
  .coins-img {
    @media screen and (max-width: 768px) {
      // max-width: 75%;
    }
    @media screen and (max-width: 450px) {
      max-width: 60%;
    }
  }

  .krater-image {
    width: 100%;
    max-width: 450px;
    height: auto;

    // @media screen and (max-width: 991px) {
    //   max-width: 350px;
    // }
  }

  @media screen and (max-width: 767px) {
    .reverse-description,
    .description-col {
      order: 2;
      justify-content: center;
      align-items: center;

      p {
        text-align: center !important;
      }
      .link-container {
        margin-right: 0px !important;
        align-items: center !important;
        justify-content: center !important;
      }
    }

    .reverse-img-col {
      order: 1;
    }
  }
}
