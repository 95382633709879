.feature-card {
   width: 100%;
   max-width: 340px;
   height: 400px;
   display: flex;
   flex-direction: column;
   padding: 3rem;
   border-radius: 16px;
   background-color: #161616;
   margin-bottom: 1.5rem;
   overflow: hidden;

   @media screen and (max-width: 1200px) {
      padding: 2rem;
   }

   @media screen and (max-width: 991px) {
      max-width: unset !important;
      height: 350px;
   }

   @media screen and (max-width: 575.5px) {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1.5rem;
   }

   @media screen and (max-width: 575px) {
      height: auto;
   }

   @media screen and (max-width: 400px) {
      height: 320px;
   }

   .card-content {
      margin-top: 4rem;

      @media screen and (min-width: 1201px) and (max-width: 1399px) {
         margin-top: 3rem;
      }
   }

   .feature-card-title {
      font-size: 35px;

      @media screen and (max-width: 1380px) {
         font-size: 32px;
      }
      @media screen and (max-width: 1200px) {
         font-size: 28px;
      }

      @media screen and (max-width: 991px) {
         max-width: 190px
      }
   }
}

.internet-card-bg {
   background-image: url("../../assets/images/png/small-cards/internet-card-bg.png");
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
}
.connection-card-bg {
   background-image: url("../../assets/images/png/small-cards/connection-card-bg.png");
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
}
.security-card-bg {
   background-image: url("../../assets/images/png/small-cards/security-card-bg.png");
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
}

.price-card-bg {
   background-image: url("../../assets/images/png/small-cards/price-card-bg.png");
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
}
