#router-section {
  .page-section {
    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }
  }
  .corners {
    height: 291px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: -moz-fit-content;

    @media screen and (max-width: 1200px) {
      padding: 1.5rem;
      height: auto;
    }
    @media screen and (max-width: 991px) {
      width: 60%;
    }
    @media screen and (max-width: 767px) {
      width: 80%;
    }
    @media screen and (max-width: 600px) {
      width: 90%;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .real-router-img {
    @media screen and (max-width: 991px) {
      max-width: 50%;
    }
    @media screen and (max-width: 767px) {
      max-width: 70%;
    }
    @media screen and (max-width: 550px) {
      max-width: 90%;
    }
    @media screen and (max-width: 400px) {
      max-width: 100%;
    }
  }

  .buttons-flex-direction {
    @media screen and (max-width: 991px) {
      justify-content: center !important;
    }

    // @media screen and (max-width: 350px) {
    //   flex-direction: column !important;
    //   align-items: center !important;
    //   padding-top: 0 !important;
    // }

    // .buttonOutlineBlue {
    //   @media screen and (max-width: 400px) {
    //     margin-top: 0px !important;
    //   }
    //   @media screen and (max-width: 350px) {
    //     margin-top: 1rem !important;
    //   }

    // }
  }

  .description-991 {
    p {
      @media screen and (max-width: 991px) {
        text-align: center;
      }
    }
  }
  .text-start {
    @media screen and (max-width: 991px) {
      text-align: center !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .tablet-center {
    align-items: center !important;
  }
}
