.homepage-countdown, .countdown-banner {
    // .flip-countdown-title {
    //     color: #d1d1d1;
    // }

    // .flip-countdown.theme-light .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top,
    // .flip-countdown.theme-light .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom,
    // .flip-countdown.theme-light .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::before,
    // .flip-countdown.theme-light .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::after {
    //     color: #fff;
    //     background-color: #2b2b2f;
    // }

    // .flip-countdown.size-medium .flip-countdown-piece {
    //     margin: 0 0.8rem;
    //     @media screen and (max-width: 550px) {
    //         margin: 0 0.5rem;
    //     }
    //     @media screen and (max-width: 430px) {
    //         margin: 0 0.3rem;
    //     }
    // }

    // .flip-countdown .flip-countdown-piece .flip-countdown-card {
    //     justify-content: center;
    //     align-items: center;
    // }

    .font-size-64 {
        @media screen and (max-width: 1200px) {
            font-size: 60px !important;
        }
        @media screen and (max-width: 1089px) {
            font-size: 56px !important;
        }
        @media screen and (max-width: 767px) {
            font-size: 50px !important;
        }
        @media screen and (max-width: 600px) {
            font-size: 45px !important;
        }
    }
    .font-size-custom {
        font-size: 80px !important;
        @media screen and (max-width: 1200px) {
            font-size: 80px !important;
        }
        @media screen and (max-width: 1089px) {
            font-size: 64px !important;
        }
        @media screen and (max-width: 767px) {
            font-size: 50px !important;
        }
        @media screen and (max-width: 600px) {
            font-size: 45px !important;
        }
        @media screen and (max-width: 550px) {
            font-size: 36px !important;
        }
        @media screen and (max-width: 365px) {
            font-size: 26px !important;
        }
    }

    .stay-tuned-gradient {
        background: linear-gradient(to right, #059efd, #4909e0, #059efd);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        width: fit-content;
        width: -moz-fit-content;
        background-size: 500%;
        animation: bg_anim ease-in-out 10s infinite;
    }

    @keyframes bg_anim {
        0% {
            background-position: left;
        }
        50% {
            background-position: right;
        }
        100% {
            background-position: left;
        }
    }

    .countdown-description {
        max-width: 550px;
        width: 100%;
        @media screen and (max-width: 991px) {
            max-width: 400px;
        }
        // @media screen and (max-width: 1100px) {
        //     max-width: 90%;
        // }
        // @media screen and (max-width: 600px) {
        //     max-width: 100%;
        // }
    }

    // .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
    //     @media screen and (max-width: 767px) {
    //         font-size: 60px;
    //     }
    //     @media screen and (max-width: 500px) {
    //         font-size: 50px;
    //     }
    //     @media screen and (max-width: 400px) {
    //         font-size: 45px;
    //     }
    //     @media screen and (max-width: 370px) {
    //         font-size: 40px;
    //     }
    //     @media screen and (max-width: 350px) {
    //         font-size: 32px;
    //     }
    //     @media screen and (max-width: 290px) {
    //         font-size: 30px;
    //     }
    // }

    // .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-title {
    //     @media screen and (max-width: 1200px) {
    //         font-size: 22px;
    //     }
    //     @media screen and (max-width: 991px) {
    //         font-size: 20px;
    //     }
    //     @media screen and (max-width: 600px) {
    //         font-size: 18px;
    //     }
    //     @media screen and (max-width: 330px) {
    //         font-size: 16px;
    //     }
    //     @media screen and (max-width: 305px) {
    //         font-size: 14px;
    //     }
    //     @media screen and (max-width: 290px) {
    //         font-size: 12px;
    //     }
    // }
    .font-size-card-title {
        font-size: 20px;
        @media screen and (max-width: 768px){
            font-size: 14px;
        }
        @media screen and (max-width: 350px){
            font-size: 12px;
        }
    }

    .dots {
        @media screen and (max-width: 991px){
            margin: 1.5rem 0rem 0.5rem 0rem !important;
        }
        @media screen and (max-width: 767px) {
            margin-top: 1.5rem!important;
        }
    }

    
    .days {
        @media screen and (max-width: 767px) {
            margin-right: 1rem !important;
        }
        @media screen and (max-width: 550px) {
            margin-right: .5rem !important;
        }
        @media screen and (max-width: 420px) {
            margin-right: .4rem !important;
        }
        @media screen and (max-width: 320px) {
            margin-right: .3rem !important;
        }
    }

    .letter-bg{
        background: #303031;
        line-height: 1.2;
        width: 65px;
        height: 100%;
        border-radius: 16px;
        margin: 0 .25rem;
        text-align: center;
        
        @media screen and (max-width: 1089px) {
             padding: .7rem 0;
            width: 60px;
            line-height: 1;
        }
        
        @media screen and (max-width: 767px) {
            width: 50px;
            border-radius: 12px;
        }

        @media screen and (max-width: 550px) {
            width: 42px;
            border-radius: 8px;
        }
        @media screen and (max-width: 420px) {
            width: 35px;
            padding: .5rem 0;
        }
        
        @media screen and (max-width: 365px){
            width: 30px;
            border-radius: 5px;
            margin: 0 .15rem;
        }
        @media screen and (max-width: 300px){
            width: 26px;
        }
    }
}
