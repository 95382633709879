#change-log-section {
  .changelog-title {
    margin-bottom: 0px;
  }
  .hero-changelog-p {
    max-width: 572px;
    text-align: center;
    margin-top: 27px;
    margin-bottom: 0px;

    @media screen and (max-width: 767px) {
      max-width: 472px;
    }
    @media screen and (max-width: 467px) {
      max-width: 292px;
    }
    @media screen and (max-width: 350px) {
      max-width: 220px;
    }
  }
}
