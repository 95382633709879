.support-unstake {
  .hero-support-unstake {
    padding-top: calc(3rem + 110px);
    background-color: black;
    min-height: 500px;
  }
  .page-section {
    // width: 100%;
  }
}
