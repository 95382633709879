#network-features {
  background: black;

  .container-main {
    .title {
      margin-bottom: 87px;
    }
  }

  .custom-padding {
    padding: 1.5rem;
    // @media screen and (max-width: 768px){
    //   padding: 1.5rem;
    // }
  }

  .worm-guard-title {
    max-width: 300px;

    @media screen and (max-width: 1200px) {
      max-width: unset !important;
    }
  }
  .column-wrapper {
    background-color: #161616;
    border-radius: 24px;
    height: 624px;
    h2 {
      line-height: 1;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 24px;
    }
  }

  .mt-custom {
    margin-top: 80px;
    @media screen and (max-width: 1200px) {
      margin-top: 2rem;
    }
    @media screen and (max-width: 1089px) {
      margin-top: 2rem;
    }
  }

  .red-color {
    color: #ff4a4a;
  }

  .background1 {
    background-image: url("../../../assets/images/png/big-sphere1.png");
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .background2 {
    background-image: url("../../../assets/images/png/private-network-worm-bg.png");
    background-position: right;
    background-size: cover;
    // background-size: cover;
    background-repeat: no-repeat;
    .line-upper-title {
      background-color: #ff4a4a;
    }
  }

  .background3 {
    background-image: url("../../../assets/images/png/Asset\ 3.png");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    h2 {
      height: 192px;
      @media screen and (max-width: 1200px) {
        height: unset;
      }
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 0;
    }
  }

  .btn-get-started {
    border: none;
    background-color: transparent;
    transition: 0.2s;
    svg {
      margin-left: 10px;
    }
  }

  .btn-get-started:hover {
    color: white;
  }

  @media screen and (max-width: 1200px) {
    .background1 {
      background-size: contain;
    }
    .column-wrapper {
      height: 500px;
    }
  }

  @media screen and (max-width: 991px) {
    .internet-title {
      padding-bottom: unset;
    }
    .network-column-svg {
      width: 65px;
    }
    .paragraph-container {
      margin: unset !important;
      margin-bottom: 85px !important;
    }
    .feature-security-card,
    .feature-privacy-card,
    .feature-internet-card {
      height: 388px;
    }
  }

  @media screen and (max-width: 650px) {
    .column-wrapper {
      height: 320px;
    }
    .network-column-svg {
      margin-top: 20px;
    }
  }

  @media screen and(max-width: 520px) {
    .background1 {
      background-size: cover;
    }
  }
  @media screen and (max-width: 480px) {
    .column-wrapper {
      h2 {
        width: unset;
        left: 25px;
        bottom: 80px;
      }
    }
    .network-column-svg {
      width: 50px;
    }
  }

  // .font-size-64 {
  //   @media screen and (max-width: 1200px) {
  //     font-size: 50px !important;
  //   }
  //   @media screen and (max-width: 1089px) {
  //     font-size: 35px !important;
  //   }
  //   @media screen and (max-width: 767px) {
  //     font-size: 30px !important;
  //   }
  //   @media screen and (max-width: 600px) {
  //     font-size: 25px !important;
  //   }
  // }
}
