#private-network-hero {
  // background-color: #124477 ;
  height: 1000px;

  @media screen and (max-width: 991px) {
    padding: 0 !important;
    padding-top: 6rem !important;
    padding-bottom: 0 !important;
  }

  .main-col {
    margin-right: 0;

    @media screen and (max-width: 991px) {
      margin-bottom: 0 !important;
    }
  }

  .hero-section-container {
    @media screen and (max-width: 991px) {
      flex-direction: column-reverse !important;
    }
  }

  .container-main {
    z-index: 1;
  }

  .paragraph-container {
    @media screen and (max-width: 500px) {
      max-width: 310px !important;
    }
    @media screen and (max-width: 400px) {
      max-width: 280px !important;
    }
  }

  .hero-video {
    top: 120px;
    z-index: -1;
    position: absolute;
    @media screen and (max-width: 991px) {
      z-index: 0;
      position: inherit;
      margin: 1rem 0;
    }
  }

  @media screen and (max-width: 1200px) {
    height: 800px;
  }

  @media screen and (max-width: 991px) {
    height: auto;
  }
  // @media screen and (max-width: 767px) {
  //     height: 600px;

  // }

  .buttonBlue {
    margin-right: 1rem !important;

    @media screen and (max-width: 480px) {
      margin-right: 0 !important;
    }
  }
  .buttonOutlineBlue {
    @media screen and (max-width: 480px) {
      margin-left: 0 !important;
      margin-top: 1rem !important;
    }
  }
  .hero-btn-container {
    text-decoration: none;

    @media screen and (max-width: 480px) {
      flex-direction: column !important;
    }
  }
}
