#howit-xiden {
  background-color: black;
  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }

  .my-auto {
    h2 {
      line-height: 1.1;
      margin-bottom: 20px;
    }
  }

  svg {
    margin: 0 !important;
    margin-left: 10px !important;
  }

  .rand {
    height: 100%;
    p {
      max-width: 440px;
      line-height: 1.1;
    }
  }

  .link-container {
    cursor: pointer;
    width: fit-content;
    width: -moz-fit-content;
    // width: 230px;

    svg {
      height: 16px;
      width: 16px;
      transition: 0.2s;
      color: #22aaff;
    }

    p {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      transition: 0.2s;
    }
  }

  .link-container:hover {
    p {
      color: white;
    }
    svg {
      color: white;
    }
  }

  .corners {
    height: 423px;
    padding: 40px;
    margin: 39px 0 39px 0;
    --b: 2px;
    --c: #86868b;
    --w: 20px;

    border: var(--b) solid transparent;
    --g: #0000 90deg, var(--c) 0;
    background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--g)) 0 0,
      conic-gradient(from 180deg at top var(--b) right var(--b), var(--g)) 100% 0,
      conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--g)) 0 100%,
      conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g)) 100% 100%;
    background-size: var(--w) var(--w);
    background-origin: border-box;
    background-repeat: no-repeat;

    @media screen and (max-width: 991px) {
      margin: 1.5rem 0 0 0;
    }
  }

  .left,
  .right {
    img {
      margin: auto;
    }
  }

  @media screen and (max-width: 1284px) {
    .my-auto {
      h2 {
        margin-top: 5px;
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 991.5px) {
    img {
      margin-top: 24px !important;
    }
    .rand {
      p {
        max-width: unset;
      }
      // .img-fluid {
      //    width: 60%;
      // }
    }

    .corners {
      height: unset;
      padding: 30px;
    }
    .flex-row-reverse {
      flex-direction: row !important;
    }
  }

  .ecosystem-how-it-info-col {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      align-items: center;
      text-align: center;
    }
  }

  .ecosystem-how-it-img {
    width: 100%;
    max-width: 550px;
    height: auto;

    @media screen and (max-width: 767px) {
      max-width: 350px;
    }
  }

  // @media screen and (max-width: 650px) {
  //    .img-fluid {
  //       width: 80% !important;
  //    }
  // }

  @media screen and (max-width: 480px) {
    .corners {
      padding: 1.5rem;
    }
  }
}
