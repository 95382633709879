#meta-realm-page{
    background-color: #070029!important;

    .page-section{
        background-color: #070029!important;
    }

   .text-center-991 {
      @media screen and (max-width: 991px) {
        text-align: center;
      }
   }

   .description-991 {
      @media screen and (max-width: 991px) {
        width: 100%;
        max-width: 600px;
      }
   }

    .buttons-flex-direction {
        width: 100%;

        @media screen and (max-width: 319px) {
            flex-direction: column;

            button {
                margin-right: 0 !important;
                margin-left: 0 !important;
            }

            button:first-child {
                margin-bottom: 1rem;
            }
        }

    }

}