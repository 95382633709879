// .btn-primary {
//    background-color: #22aaff !important;
//    border-radius: 36px !important ;
//    border-color: #22aaff !important ;
//    font-family: proxima-nova, sans-serif;
//    font-weight: 400;
//    font-style: normal;
//    min-width: 220px;
//    height: 64px;
//    margin-right: 24px;
// }
// .btn-outline-primary {
//    border-radius: 36px !important ;
//    border-color: #22aaff !important ;
//    font-family: proxima-nova, sans-serif;
//    font-weight: 400;
//    font-style: normal;
//    min-width: 220px;
//    height: 64px;
//    color: #22aaff !important;
//    margin-right: 24px;

// }

.black-color.btn-primary {
   color: black !important;
}

.buttonBlue {
   width: 100%;
   max-width: 220px;
   height: 64px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #22aaff;
   border-radius: 36px;
   border: none;
   outline: none;
   font-family: proxima-nova, sans-serif;
   font-weight: 600;
   font-size: 20px;
   color: #000000;
   text-decoration: none;
   cursor: pointer;
   margin-right: 2rem;

   &:hover {
      background-color: #81c9f7;
      color: #000000;
   }

   @media screen and (max-width: 1200px) {
      max-width: 180px;
      height: 55px;
   }
   @media screen and (max-width: 991px) {
      // margin-left: 0.75rem;
      margin-right: 0.75rem;
      height: 50px;
      font-size: 18px;
   }
   @media screen and (max-width: 600px) {
      font-size: 16px;
      max-width: 160px;
      height: 45px;
   }
   @media screen and (max-width: 500px) {
      height: 40px;
   }
   @media screen and (max-width: 400px) {
      font-size: 15px;
      // margin-left: 0.5rem;
      margin-right: 0.5rem;
   }
}

.buttonYellow {
   width: 100%;
   max-width: 220px;
   height: 64px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #fda544;
   border-radius: 36px;
   border: none;
   outline: none;
   font-family: proxima-nova, sans-serif;
   font-weight: 600;
   font-size: 20px;
   color: #000000;
   text-decoration: none;
   transition: all 0.2s ease-in-out;
   cursor: pointer;

   @media screen and (max-width: 1200px) {
      max-width: 180px;
      height: 55px;
   }
   @media screen and (max-width: 991px) {
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      height: 50px;
      font-size: 18px;
   }
   @media screen and (max-width: 600px) {
      font-size: 16px;
      max-width: 160px;
      height: 45px;
   }
   @media screen and (max-width: 400px) {
      font-size: 15px;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
   }
}

.buttonOutlineBlue {
   width: 100%;
   max-width: 220px;
   height: 64px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: transparent;
   border: 2px solid #22aaff;
   border-radius: 36px;
   font-family: proxima-nova, sans-serif;
   font-weight: 600;
   font-size: 20px;
   color: #22aaff;
   text-decoration: none;
   cursor: pointer;
   transition: all 0.2s ease-in-out;
   // margin-right: 2rem;

   &:hover {
      background-color: #22aaff;
      color: #000000;
   }

   @media screen and (max-width: 1200px) {
      max-width: 180px;
      height: 55px;
   }
   @media screen and (max-width: 991px) {
      margin-left: 0.75rem;
      // margin-right: 0.75rem;
      height: 50px;
      font-size: 18px;
   }
   @media screen and (max-width: 600px) {
      font-size: 16px;
      max-width: 160px;
      height: 45px;
   }
   @media screen and (max-width: 500px) {
      height: 40px;
   }
   @media screen and (max-width: 400px) {
      font-size: 15px;
      // margin-top: 1rem;
      margin-left: 0.5rem;
      // margin-right: 0.5rem;
   }
}
