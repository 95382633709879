
.guardian-node-page{
    padding-top: 12rem;
    background-color: #000;

    @media screen and (max-width: 991px){
        padding-top: 7rem;
    }

    .text-center-991{
        @media screen and (max-width: 991px){
            text-align: center;
        }
    }

    .description-991{
        @media screen and (max-width: 991px){
           width: 60%;
        }
        @media screen and (max-width: 767px){
           width: 80%;
        }
        @media screen and (max-width: 600px){
           width: 90%;
        }
        @media screen and (max-width: 500px){
           width: 100%;
        }
    }
    .buttons-flex-direction{
        width: 100%;
        @media screen and (max-width: 320px){
            flex-direction: column;
           
            padding-top: 1.5rem!important;

            .buttonOutlineBlue {
                margin: 1rem 0 0 0;
            }

            .buttonBlue {
                margin: 0;
            }
        }

    }
}