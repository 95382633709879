#cards-section-1 {
  @media screen and (max-width: 1440px) {
    padding-bottom: 0px;
  }

  .upper-title-consensus {
    .xideen-upper-title {
      padding-bottom: 0;
    }
  }

  .xideen-upper-title {
    padding-bottom: 3rem;

    @media screen and (max-width: 1340px) {
      padding-bottom: 2rem;
    }
    // @media screen and (max-width: 991px) {
    //    padding-bottom: 1.5rem;
    // }
  }

  .col-lg-4:last-child .staking-card {
    @media screen and (max-width: 991px) {
      margin-bottom: 0px;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .frontside {
    .plus-container {
      margin-top: auto;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .backside {
    .plus-container {
      margin-top: auto;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      img {
        transform: rotate(45deg);
      }
    }
  }

  .staking-card {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 24px;
    background-color: #161616;
    padding: 3rem;
    overflow: hidden;

    @media screen and (max-width: 1200px) {
      // height: 550px;
      padding: 2rem;
      margin-bottom: 1.5rem;
    }

    @media screen and (max-width: 991px) {
      height: 450px;
      padding: 1.5rem;
      margin-bottom: 1.5rem;
    }

    @media screen and (max-width: 850px) {
      height: 410px;
    }

    @media screen and (max-width: 767px) {
      height: auto;
    }

    @media screen and (max-width: 500px) {
      height: 320px;
    }

    br {
      display: none;
    }
  }

  .staking-card-img {
    @media screen and (max-width: 1200px) {
      max-height: 70px;
    }

    @media screen and (max-width: 991px) {
      max-height: 60px;
    }

    @media screen and (max-width: 500px) {
      max-height: 50px;
    }
  }

  .card1-bg {
    // background-image: url("../../../assets/images/png/staking-card1-bg.png");
    background-color: #161616;
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;

    .line-upper-title {
      background-color: #f9ca53;
    }
  }

  .card2-bg {
    background-image: url("../../../assets/images/png/staking-card2-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card3-bg {
    background-image: url("../../../assets/images/png/staking-card3-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .line-upper-title {
      background-color: #ff4a4a;
    }
  }

  .card4-bg {
    background-color: #161616;
  }

  .font-size-40-b {
    font-size: 36px;

    @media screen and (max-width: 1444px) {
      font-size: 32px !important;
    }

    @media screen and (max-width: 1340px) {
      font-size: 26px !important;
    }

    @media screen and (max-width: 1200px) {
      font-size: 36px !important;
    }

    @media screen and (max-width: 991px) {
      font-size: 26px !important;
    }

    @media screen and (max-width: 767px) {
      font-size: 25px !important;
    }

    @media screen and (max-width: 600px) {
      font-size: 25px !important;
    }
  }

  .staking-cards {
    @media screen and (max-width: 991px) {
      max-width: 400px;
    }
  }

  //##########################################################################################################################################################
  //##########################################################################################################################################################
  //##########################################################################################################################################################
  //##########################################################################################################################################################
  //##########################################################################################################################################################
  //##########################################################################################################################################################

  .transform-card {
    transform-style: preserve-3d;
    transition: transform 1s;
    height: 450px !important;

    @media screen and (max-width: 1340px) {
      height: 370px !important;
    }

    // @media screen and (max-width: 1200px) {
    //    height: 350px !important;
    // }
    @media screen and (max-width: 991px) {
      height: 250px !important;
    }
  }

  .plus-btn-rotate {
    img {
      transform: rotate(45deg);
      transform: -webkit-rotate(45deg);
    }
  }

  #xiden-card1-box {
    &.xiden-card1-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      // height: 388px;
    }

    .xiden-card1-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
    }

    .xiden-card1-box-front-side {
      background-color: #161616;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;

      // @media screen and (max-width: 991px) {
      //     margin-bottom:
      // }
    }

    .xiden-card1-box-back-side {
      transform: rotateY(180deg);
      background-color: #161616;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card1-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card1-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card2-box {
    @media screen and (max-width: 767px) {
      margin-top: 1.5rem;
    }

    &.xiden-card2-box-app-card {
      position: relative;
    }

    &.xiden-card2-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      // height: 388px;
    }

    .xiden-card2-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
    }

    .xiden-card2-box-front-side {
      background-color: #161616;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card2-box-back-side {
      transform: rotateY(180deg);
      background-color: #161616;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card2-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card2-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card3-box {
    @media screen and (max-width: 1199px) {
      margin-top: 1.5rem;
    }

    &.xiden-card3-box-app-card {
      position: relative;
    }

    &.xiden-card3-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
    }

    .xiden-card3-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
    }

    .xiden-card3-box-front-side {
      background-color: #161616;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card3-box-back-side {
      transform: rotateY(180deg);
      background-color: #161616;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card3-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card3-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card4-box {
    @media screen and (max-width: 1199px) {
      margin-top: 1.5rem;
    }

    &.xiden-card4-box-app-card {
      position: relative;
    }

    &.xiden-card4-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
    }

    .xiden-card4-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
    }

    .xiden-card4-box-front-side {
      background-color: #161616;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card4-box-back-side {
      transform: rotateY(180deg);
      background-color: #161616;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card4-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card4-box-back-side {
      transform: rotateY(0deg);
    }
  }

  .plus-icons {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @media screen and (max-width: 991px) {
    .staking-card-img {
      // width: 30px;
      height: 40px;
    }

    .plus-icons {
      height: 22px;
    }
  }
}
