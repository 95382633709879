#error-page {
    padding-top: 147px;
    padding-bottom: 227px;
    .error-page-title {
        margin-top: 36px;
        margin-bottom: 52px;

        @media screen and (max-width: 380px) {
            text-align: center;
        }
    
    }
    .buttonBlue {
        width: fit-content;
        width: -moz-fit-content;
        margin-right: 0;
        padding: 0 24px;

        @media screen and (max-width: 1200px) {
            max-width: unset;
            height: 55px;
        }
        @media screen and (max-width: 600px) {
            height: 45px;
        }
        @media screen and (max-width: 400px) {
            margin-right: 0;
        }
    }

    .error-img {

        @media screen and (max-width: 1050px) {
            max-width: 55%;
        }
        @media screen and (max-width: 825px) {
            max-width: 60%;
        }

        @media screen and (max-width: 663px) {
            max-width: 70%;
        }
        @media screen and (max-width: 460px) {
            max-width: 85%;
        }
    
        @media screen and (max-width: 360px) {
        max-width: 95%;
    }
}
}