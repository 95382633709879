.tooltip-inner {
  background-color: #22aaff;
  color: #000;
}

.rewards-hero {
  padding-top: 9rem;

  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    padding-top: 7rem;
  }

  .paragraph-container {
    max-width: 630px;
  }

  .rewards-hero-btn-container {
    .buttonBlue,
    .buttonOutlineBlue {
      @media screen and (max-width: 1200px) {
        max-width: 200px;
      }

      @media screen and (max-width: 600px) {
        max-width: 160px;
      }
    }
    @media screen and (max-width: 991px) {
      align-items: center !important;
      justify-content: center !important;
    }
    @media screen and (max-width: 360px) {
      flex-direction: column;
      //align-items: flex-start !important;
      .buttonBlue {
        margin-right: 0;
      }

      .buttonOutlineBlue {
        margin: 1rem 0 0 0 !important;
      }
    }
  }

  .hero-section-container {
    .main-col {
      margin-bottom: 0 !important;
    }
  }

  // .rewards-hero-buttons {
  //    //width: 200px !important;
  //    //margin-left: 0 !important;

  //    &:first-child {
  //       @media screen and (max-width: 600px) {
  //          margin-right: 1rem !important;
  //       }
  //       @media screen and (max-width: 420px) {
  //          margin-right: 0 !important;
  //          margin-bottom: 1rem;
  //       }
  //    }

  //    &:last-child {
  //       margin-right: 0 !important;

  //       @media screen and (max-width: 420px) {
  //          margin-top: 0 !important;
  //       }
  //    }
  // }
  .section-image {
    max-width: 550px !important;
  }

  @media screen and (max-width: 1200px) {
    .section-image {
      width: 90% !important;
    }
  }
  @media screen and (max-width: 991px) {
    .section-image {
      width: 35% !important;
    }
    .hero-section-container {
      flex-direction: column;
    }
    .rewards-hero-col-1 {
      order: 2;
      align-items: center;
      margin-top: 2rem;

      p {
        text-align: center;
      }
    }

    .rewards-hero-col-2 {
      order: 1;
    }
  }
  @media screen and (max-width: 767px) {
    .section-image {
      width: 50% !important;
    }
  }
  @media screen and (max-width: 600px) {
    .section-image {
      width: 60% !important;
    }
  }
  @media screen and (max-width: 450px) {
    .section-image {
      width: 70% !important;
    }
  }
}
