#computingpower-validator {
  background-color: black;
  line-height: 1.1;

  h4,
  h2 {
    line-height: 1;
    margin-bottom: 0;
  }
  .mb-10 {
    margin-bottom: 2.5rem;
  }
  button {
    width: 140px;
    background: transparent;
    border: none;
    transition: 0.2s;
    margin-top: 2rem;

    svg {
      margin-left: 10px;
    }

    &:hover {
      color: white;
    }
  }

  .corners {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .card-content {
    p {
      max-width: 440px;
    }
  }

  .card-img {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    .dapps-img {
      width: 70%;
      margin-right: auto;
    }
    .stack-img {
      width: 80%;
    }
  }

  @media screen and (max-width: 1470px) {
    .corners {
      height: 100%;
    }
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 0;
    .corners {
      margin: 1.5rem 0 0 0;
      height: unset;
    }
    .card-img {
      .dapps-img {
        width: 50%;
        margin: auto;
      }
      .stack-img {
        width: 55%;
      }
    }

    .row2 {
      flex-direction: column !important;
      h4,
      p {
        text-align: center;
        margin: auto;
      }
    }

    .mobile-row {
      flex-direction: column-reverse !important;
      h4,
      p {
        text-align: center;
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 764px) {
    .corners {
      flex-direction: column !important;
      align-items: flex-start !important;
    }

    .card-img {
      img {
        margin: 1.5rem 0 0 0;
      }
    }

    .card-content {
      p {
        width: 450px;
      }
    }
  }

  @media screen and (max-width: 550px) {
    .card-content {
      p {
        width: unset;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .corners {
      padding: 1.5rem;
    }
    .card-img {
      img {
        width: 100% !important;
      }
      .dapps-img {
        width: 100%;
      }
    }
  }
}
