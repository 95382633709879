#features-validator {
  background: black;

  .font-size-48 {
    font-size: 48px !important;

    @media screen and (max-width: 1430px) {
      font-size: 40px !important;
    }

    @media screen and (max-width: 1200px) {
      font-size: 35px !important;
    }

    @media screen and (max-width: 767px) {
      font-size: 26px !important;
    }

    @media screen and (max-width: 635px) {
      font-size: 25px !important;
    }

    @media screen and (max-width: 420px) {
      font-size: 20px !important;
    }
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }

  h4 {
    line-height: 1 !important;
    margin-bottom: 0;
  }

  .mt-4-5 {
    margin-top: 2.5rem !important;
    // @media screen and (max-width: 1040px){
    //     margin-top: 1rem!important;
    // }
    // @media screen and (max-width: 991px){
    //     margin-top: 2.5rem!important;
    // }
  }
  h2 {
    margin-bottom: 6rem;
  }

  .card-logo {
    margin-top: 4rem;
  }

  .connected-devices {
    margin-right: auto;
    margin-top: 5rem;
    @media screen and (max-width: 991px) {
      margin-top: 1rem;
    }
  }

  .big-column {
    min-height: 963px;
    background-image: url("../../../assets/images/png/fat-blurp2.png");
    background-repeat: no-repeat;
    background-position: bottom left;
    margin-bottom: 0 !important;
    @media screen and (max-width: 1040px) {
      min-height: 1104px;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 1.5rem !important;
      min-height: unset;
      height: unset !important;
      h4 {
        margin-left: 0 !important;
      }
      .card-img {
        justify-content: flex-start !important;
      }
    }
    @media screen and (max-width: 450px) {
      .card-img {
        img {
          width: 100%;
        }
      }
    }
  }

  .main-bg {
    background-image: url("../../../assets/images/png/fat-blurp2.png");
    background-repeat: no-repeat;
    background-position: bottom left;
  }

  .bg1 {
    background-image: url("../../../assets/images/png/medium-sphere.png");
    background-size: contain;
    background-position: 100% calc(50% - 50px);
    background-repeat: no-repeat;
  }

  .bg2 {
    background-image: url("../../../assets/images/png/horizontal-blurp.png");
    background-position: calc(50% + 100px) calc(50% + 100px);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg3 {
    background-image: url("../../../assets/images/png/big-sphere.png");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 0 !important;
    @media screen and (max-width: 767px) {
      margin-bottom: 1.5rem !important;
    }
  }

  .column-wrapper {
    background-color: #161616;
    border-radius: 24px;
    height: 470px;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 1040px) {
      height: 540px;
    }
    .card-img {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
    }

    @media screen and (max-width: 767px) {
      width: 350px;
      min-height: 400px;

      max-height: 400px;
      height: 400px;
      margin-right: 1rem;

      @media screen and (max-width: 520px) {
        min-height: 350px;
        max-height: 350px;
        height: 350px;
      }

      @media screen and (max-width: 420px) {
        width: 280px;
        min-height: 330px;
        max-height: 330px;
        height: 330px;
      }
      @media screen and (max-width: 355px) {
        width: 240px;
        min-height: 360px;
        max-height: 360px;
        height: 360px;
      }
      @media screen and (max-width: 305px) {
        width: 220px;
        margin-right: 0.75rem;
      }
    }
  }

  @media screen and (max-width: 355px) {
    .features-mobile-slider-validator {
      .slider-height{
        height: 380px;

      } 
    }
  }

  @media screen and (max-width: 1470px) {
    h4 {
      width: unset !important;
      margin: auto 0 auto 0;
    }
  }

  @media screen and (max-width: 991px) {
    .text-shadowed {
      text-shadow: 0px 0px 10px black;
    }
    h2 {
      margin-bottom: 1.5rem;
    }

    .column-wrapper {
      margin-bottom: 1.5rem;
      height: 380px;

      .card-logo {
        margin-top: 48px !important;
      }
    }

    .card-content {
      width: 100% !important;
      max-width: unset !important;
    }

    .card-img {
      img {
        max-width: 300px;
        width: 100%;

        @media screen and (max-width: 767px) {
          max-width: 150px;
          height: auto;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .text-shadowed {
      text-shadow: none;
    }
  }
  @media screen and (max-width: 480px) {
    .card-logo {
      width: 50px;
    }
  }

  .nice-bg {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-image: url("../../../assets/images//png/low-energy-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
    margin-bottom: 0;

    span {
      color: #20deb0;
    }

    button {
      color: #20deb0;
    }
    button:hover {
      color: white;
    }

    .low-energy {
      background: -webkit-linear-gradient(rgba(255, 255, 255, 0), #de7488);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    .titles {
      margin-top: auto;
      margin-bottom: auto;
    }

    .line-upper-title {
      background-color: #20deb0;
    }
  }
}
