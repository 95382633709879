#news-section {
  @media screen and (max-width: 1920px) {
    padding-bottom: 2rem;
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }

  .top-border,
  .bottom-border {
    background-image: linear-gradient(to right, #22aaff, #9e00c3);
    width: 100%;
    height: 100%;
    min-width: 2px;
    min-height: 2px;
    max-width: 1470px;
  }

  .z-index-10 {
    z-index: 10;

    // @media screen and (max-width: 991px) {
    //   flex-basis: 33%;
    // }
  }

  .large-svg {
    @media screen and (max-width: 1500px) {
      max-width: 160px;
      height: auto;
    }
    @media screen and (max-width: 1340px) {
      max-width: 130px;
    }
    @media screen and (max-width: 1200px) {
      max-width: 105px;
    }
    @media screen and (max-width: 420px) {
      max-width: 90px;
    }
  }

  .small-svg {
    @media screen and (max-width: 1500px) {
      max-width: 180px;
      height: auto;
    }
    @media screen and (max-width: 1340px) {
      max-width: 150px;
    }
    @media screen and (max-width: 1200px) {
      max-width: 125px;
    }
    @media screen and (max-width: 420px) {
      max-width: 145px;
    }
    @media screen and (max-width: 315px) {
      max-width: 133px;
    }
  }
  button {
    background: transparent;
    border: none;

    outline: 0 !important;

    svg {
      outline: 0 !important;
    }
  }

  .set-1 {
    min-width: 1470px;

    @media screen and (max-width: 991px) {
      min-width: unset;
    }
  }

  .marquee {
    white-space: nowrap;
    animation: loop linear 15s infinite;

    @media screen and (max-width: 991px) {
      animation-duration: 15s;
    }

    &:hover {
      animation-play-state: paused;
    }
  }

  @keyframes loop {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }

  .bloomberg-ico {
    svg {
      fill: white;
      width: 190px;
      @media screen and (max-width: 1500px) {
        width: 180px;
        height: auto;
      }
      @media screen and (max-width: 1340px) {
        width: 150px;
      }
      @media screen and (max-width: 1200px) {
        width: 125px;
      }
      @media screen and (max-width: 420px) {
        width: 145px;
      }
      @media screen and (max-width: 315px) {
        width: 133px;
      }
    }
  }
  .press-ico {
    svg {
      width: 48px;
     
      @media screen and (max-width: 480px) {
        width: 32px;
      }
    }
  }
}
