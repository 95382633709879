.line-upper-title {
   height: 2px;
   width: 18px;
   background-color: #22aaff;
   border-radius: 48px;
   margin-right: 15px;
}

.cards-smaller-text {
   @media screen and (min-width: 768px) and (max-width: 1250px) {
      font-size: 14px !important;
   }

   @media screen and (max-width: 400px) {
      font-size: 12px !important;
   }
}
