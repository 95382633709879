#metamask-support-steps-page {
    .paragraph-content {
        max-width: 656px;
    }


    .cyan-color{
        color: #02FFE9;
    }

    .ext-link{
        text-decoration: none!important;
        cursor: pointer;
        transition: .2s;

        &:hover {
            color: white;
        }
    }

    .font-size-80{
        font-size: 80px !important;
        line-height: 65px !important;
    }

    .row {
        margin-left: 0 !important;
        margin-right: 0 !important; 
    }

    .border-top{
        border-top: 1px solid #86868b!important;
    }
    
    .border-top-bottom{
        border-bottom: 1px solid #86868b!important;
        border-top: 1px solid #86868b!important;
    }

    .step-img{
        padding-left: 1.5rem;
    }
    
    @media screen and (max-width: 991px){
        .step-img{
            margin-top: 2rem;
            padding-left: 0;
        }

        .mobile-col {
            justify-content: center!important;
            align-items: center!important;
        }

    }

    @media screen and (max-width: 650px){
        .font-size-80 {
            font-size: 64px !important;
            line-height: 54px !important;
        }

        .font-size-32 {
            font-size: 28px;
        }

        .font-size-18 {
            font-size: 16px!important;
        }
    }

    @media screen and (max-width: 350px){
        br {
            display: inherit!important;
        }
    }
}