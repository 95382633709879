#proprietary {
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  overflow: hidden;

  .font-size-40 {
    font-size: 40px !important;

    @media screen and (max-width: 1430px) {
      font-size: 35px !important;
    }

    @media screen and (max-width: 1200px) {
      font-size: 30px !important;
    }

    @media screen and (max-width: 991px) {
      font-size: 21px !important;
    }

    @media screen and (max-width: 600px) {
      font-size: 20px !important;
    }
  }
  //line-height: 1;
  .link-btn {
    width: 100%;
    background: transparent;
    border: none;
    transition: 0.2s;

    svg {
      margin-left: 10px;
    }

    &:hover {
      color: white;
    }
  }

  .minter-feature-card {
    border-radius: 16px;
    padding: 3rem;
    background-color: #161616;
    @media screen and (max-width: 1200px) {
      padding: 1.5rem;
    }
  }
  .contract-card {
    width: 100%;
    min-height: 500px;
    height: 500px !important;
    display: flex;
    background-image: url("../../../assets/images/png/prop-long-blurp.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 1.5rem;
    //   @media screen and (min-width: 651px) and (max-width: 991px) {
    //      min-height: 360px;
    //   }

    @media screen and (max-width: 1300px) {
      min-height: 400px;
      height: 400px !important;
    }
    @media screen and (max-width: 1105px) {
      min-height: 340px;
      height: 340px !important;
    }

    @media screen and (max-width: 991px) {
      min-height: 400px;
      height: 400px !important;
    }
    @media screen and (max-width: 767px) {
      min-height: 360px;
      height: 360px !important;
    }

    @media screen and (max-width: 650px) {
      flex-direction: column;
      min-height: auto;
      height: auto !important;
    }
  }

  .prop-feature-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .prop-image-col {
    position: relative;
    flex: 1;
    z-index: 40;

    @media screen and (max-width: 1450px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .proprietary-slider {
    .slider-height {
      height: 290px;
    }
  }

  .column-wrapper {
    background-color: #161616;
    border-radius: 24px;
    height: 400px;
    margin-bottom: 1.5rem;
    h4 {
      line-height: 1;
    }

    @media screen and (max-width: 1300px) {
      height: 340px;
    }

    @media screen and (max-width: 991px) {
      height: 250px;
      min-width: 250px;
    }
    @media screen and (max-width: 325px) {
      min-width: 220px;
    }
    @media screen and (max-width: 295px) {
      min-width: 210px;
    }
  }

  .setup-col {
    @media screen and (max-width: 991px) {
      margin-bottom: 0px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
  }
  .setup-last-col {
    @media screen and (max-width: 991px) {
      margin-bottom: 0px;
    }
  }

  .proprietary-title-main-card {
    min-width: 770px;

    @media screen and (max-width: 1484px) {
      min-width: unset;
    }
  }
  .prop-control-title {
    @media screen and (max-width: 991px) {
      max-width: 190px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .prop-cube-img {
    width: 650px;
    transform: translate(40px, -70px);
    z-index: 50;

    // @media screen and (max-width: 1600px) {
    //     transform: translate(80px, -90px);
    // }
    @media screen and (max-width: 1550px) {
      transform: translate(0px, -90px);
      max-width: 550px;
    }
    @media screen and (max-width: 1300px) {
      max-width: 450px;
    }
    @media screen and (max-width: 1105px) {
      max-width: 380px;
    }
  }

  .bg1 {
    background-image: url("../../../assets/images/png/dapps-blurp.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .bg2 {
    background-image: url("../../../assets/images/png/dapps-big-sphere.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .bg3 {
    background-image: url("../../../assets/images/png/prop-long-blurp.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 991px) {
    br {
      display: none;
    }
  }
  @media screen and (max-width: 650px) {
    .prop-feature-info {
      order: 2;
    }

    .prop-image-col {
      order: 1;
      margin-bottom: 2rem;
    }
  }
}
