#a-network-built-section {
  .custom-text {
    @media screen and (max-width: 991px) {
      text-align: center;
      padding: 0;
    }
  }

  .font-size-40 {
    @media screen and (max-width: 700px) {
      font-size: 24px;
    }
  }

  .ico-container {
    background-color: #161616;
    border-radius: 50%;
    width: 95px;
    height: 95px;

    @media screen and (max-width: 768px) {
      width: 64px;
      height: 64px;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  .column-container {
    max-width: 430px;

    @media screen and (max-width: 991px) {
      max-width: 600px;
    }
  }

  .second-part {
    margin-top: 6rem;
  }

  .old-svg {
    svg {
      width: 65px;
      height: 65px;

      @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }
  }
}
