#what-is-spectralis-network {
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  .horizontal-line {
    width: fit-content;
    width: -moz-fit-content;
  }

  .custom-text {
    @media screen and (max-width: 768px) {
      text-align: center;
    }

    @media screen and (max-width: 500px) {
      max-width: 310px !important;
    }
    @media screen and (max-width: 400px) {
      max-width: 280px !important;
    }
  }

  .content-image {
    @media screen and (max-width: 1200px) {
      max-width: 250px;
    }
    @media screen and (max-width: 1050px) {
      max-width: 200px;
    }
    @media screen and (max-width: 991px) {
      max-width: 180px;
    }
  }
}
