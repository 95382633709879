#xiden-features-hero {
  .page-section {
    padding: 9rem 0 6rem 0 !important;

    @media screen and (max-width: 991px) {
      padding: 6rem 0 0 0 !important;
    }
  }

  .xden-uppertitle-container {
    @media screen and (max-width: 767px) {
      margin: 10px 0 15px;
    }
  }

  .xid-hero {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    height: -moz-fit-content;

    //min-height: 1080px;
    .banner {
      max-width: 2500px;
      width: 100%;

      @media screen and(min-width: 2500px) {
        border-radius: 25px;
      }
    }

    .container-main {
      display: flex;
      justify-content: space-between;
      //padding-bottom: 6rem;
      margin: auto;

      h2 {
        line-height: 1;
      }

      p {
        line-height: 1.1;
      }

      .paragraph-container {
        max-width: 488px;
      }

      @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
      }

      .main-col {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 2rem;

        @media screen and (max-width: 991px) {
          align-items: center;
          margin-right: 0;
          //margin-bottom: 3rem;
          justify-content: flex-start;

          h2 {
            text-align: center;
          }

          .paragraph-container {
            width: 100%;
            max-width: 400px;
            margin: auto;
            text-align: center;
          }
        }

        .hero-btn-container {
          width: 100%;
          display: flex;
          align-items: center;

          @media screen and (max-width: 991px) {
            width: 100%;
            justify-content: center;
          }

          @media screen and (max-width: 320px) {
            flex-direction: column;
            align-items: center;

            button:first-child {
              margin: 0 !important;
            }

            .buttonOutlineBlue {
              margin: 1rem 0 0 0 !important;
            }
          }
        }
      }

      .section-image-col {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .section-image {
          width: 100%;
          // max-width: 500px;
          height: auto;

          @media screen and (max-width: 991px) {
            // margin-bottom: 48px;
            // width: 40%;
            width: 50%;
          }

          @media screen and (max-width: 768px) {
            width: 45%;
          }

          @media screen and (max-width: 480px) {
            // width: 60%;
            width: 80%;
          }

          @media screen and (max-width: 350px) {
            // width: 70%;
            width: 100%;
          }
        }
      }

      @media screen and(max-width: 600px) {
        min-height: unset;
      }
    }

    display: flex;

    // @media screen and (min-width: 768px) {
    // }

    .banner {
      min-height: 120px;
      position: relative;
      display: flex;
      color: black;
      background: linear-gradient(to right, #674bdd, #22aaff);
      // background-image: url("../../../assets/images/png/banner-bg.png");
      // background-position: center;
      // background-size: cover;
      // background-repeat: no-repeat;

      // @media screen and (max-width: 1010px) {
      //    flex-direction: column;
      //    min-height: unset;
      //    // .banner-column {

      //    //    width: unset!important;
      //    // }
      //    .banner-item{
      //       flex-direction: row;
      //       justify-content: space-between;
      //       text-align: left;
      //       border-bottom: 1px solid #000000;
      //    }
      // }
      @media screen and (max-width: 767px) {
        margin-top: 2rem;
      }
    }

    .banner-column {
      border-right: 1px solid rgba(0, 0, 0, 0.1);

      @media screen and (max-width: 520px) {
        flex-direction: column !important;
      }
    }

    .banner-item {
      flex: 1;
      padding: 2rem;
      display: flex;
      align-items: center;
      min-height: 90px;
      justify-content: space-between;

      &:not(:last-child) {
        border-right: 1px solid #000000;
      }

      @media screen and (max-width: 850px) {
        min-height: unset;
      }

      @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        opacity: 1;

        .blur-me {
          color: black;
          text-shadow: none;
        }
      }

      @media screen and (max-width: 1400px) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 1rem;
      }

      @media screen and (max-width: 600px) {
        flex-direction: row;
        //padding: 2rem;
        border-right: 0;
        text-align: left;
        justify-content: space-between;

        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        // &:not(:last-child) {
        // }
      }

      @media screen and (max-width: 520px) {
        &:not(:last-child) {
          border-right: none;
        }
      }
    }

    .number-span {
      font-size: 32px;

      @media screen and (max-width: 1550px) {
        font-size: 30px;
      }

      @media screen and (max-width: 800px) {
        font-size: 26px;
      }

      @media screen and (max-width: 400px) {
        font-size: 20px;
        text-align: right;
      }
    }
  }

  .banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
      background-color: rgba(0, 0, 0, 0.2);
    }

    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background: rgba(0, 0, 0, 0.5);

    .font-size-card-title,
    .dots {
      color: white;
      text-shadow: 0px 0px 10px #000000;
    }

    .letter-bg {
      background: black;
    }
  }

  .w-33 {
    width: 33.3333% !important;
  }

  .accordion-button {
    min-height: 80px !important;
    position: relative !important;
    display: flex !important;
    color: black !important;
    background: linear-gradient(to right, #674bdd, #22aaff) !important;
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
  }

  .banners-mobile {
    flex-direction: column;
    min-height: 80px !important;

    .banner-item {
      min-height: 80px !important;
      border: none !important;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      border-bottom: 1px solid #000000;
    }

    .banner-column {
      width: 100% !important;
    }

    .accordion-body {
      padding: 0px !important;
      color: black !important;
      background: linear-gradient(to right, #674bdd, #22aaff) !important;
      width: 100% !important;
      border: none !important;
    }

    .accordion-header svg {
      width: unset !important;
      height: unset !important;
    }

    .banner-column {
      border-right: none !important;
      border-bottom: 1px solid black;
    }
    .acordeon-plus {
      svg {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
}
