#prizes-pool-section {
    .title-container {
        img {
            width: 800px;
        }
    }

    .corners {
        height: 100%;
        padding-top: 0 !important;
    }

    .description-container {
        max-width: 770px;
    }

    .prize-img {
        width: 50px;

        @media screen and (max-width: 1100px) {
            width: 30px;
        }
    }

    .design-title{
        color: #5A40EE;
    }
    .performance-title{
        color: #A944F8;
    }

    .place-container{
        min-width: 150px;

        @media screen and (max-width: 991px) {
            min-width: 130px;
        }
    }

    .prize-design-1st{
        max-height: 80px;
        @media screen and (max-width: 1100px) {
            max-height: 60px;
        }
    }
    .prize-design-2nd{
        max-height: 48px;
        @media screen and (max-width: 1100px) {
            max-height: 36px;
        }
    }
    .prize-design-3rd{
        max-height: 65px;
        @media screen and (max-width: 1100px) {
            max-height: 46px;
        }
    }

    .gradient-1st {
        background: linear-gradient(#F9CA53, #EE694B);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
    }

    .gradient-2nd {
        background: linear-gradient(#AF4AFF, #477BFF);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
    }

    .gradient-3rd {
        background: linear-gradient(#20DEB0, #22AAFF);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
    }

    .performance-col {
        @media screen and (max-width: 767px){
            height: 100%!important;
        }
    }

    

    @media screen and (max-width: 400px){
        .prizes-container{
            justify-content: center !important;
            align-items: center !important;
        }

        .prize-container{
            flex-direction: column !important;
            align-items: center !important;
        }

        .place-container{
            margin-bottom: 1rem;
        }
    }
}