.private-withdraw {
  .private-title {
    h2 {
      margin: 0;
    }
  }
  .column-wrapper {
    height: 580px;
    border-radius: 24px;
    min-height: 350px;

    @media screen and (max-width: 991px) {
      height: auto;
    }
  }
  .buy-minter-1 {
    background: url(./assets/background-card-stanga.png) no-repeat center/cover;
    //
  }

  .warning-please {
    background: url(./assets/background-dreapta.png) no-repeat center/cover;
  }

  .xiden-warning {
    color: #ee694b;
  }
  .big-bang-section-title,
  .warning-please {
    .line-upper-title {
      background-color: #ee694b;
    }
    @media screen and (max-width: 991px) {
      br {
        display: none;
      }
    }
  }
}
