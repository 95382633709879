#hidr-device {
    h2,h3,h4,p{
        line-height: 1;
    }
    .first-section {
        img {
            @media screen and (max-width: 991px) {
                width: 20%;
            }
            @media screen and (max-width: 768px) {
                width: 30%;
            }
            @media screen and (max-width: 480px) {
                width: 50%;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .col-lg-7 {
            align-items: flex-start !important;
            justify-content: flex-start !important
        }
    }
}
