#newsletter-component {
  // background: black;
  @media screen and (max-width: 1440px) {
    padding-bottom: 0px !important;
  }
  &.gray-bg-color {
    background: #000000;
  }
  &.blue-bg-color {
    background: #070029;
  }

  .newsletter-section {
    // background-color: #161616;
    border-radius: 24px;
    // padding: 15px;

    &.gray-bg-color {
      background: #161616;
    }
    &.blue-bg-color {
      background: #13045f;
    }

    .custom-search {
      position: relative;
      width: 684px;
    }
    .custom-search-input {
      width: 100%;
      border: none;
      border-radius: 100px;
      padding: 20px 100px 20px 20px;
      line-height: 1;
      box-sizing: border-box;
      outline: none;
      // background: black;
      min-width: 400px;
      &.gray-bg-color {
        background: #000000;
      }
      &.blue-bg-color {
        background: #0b0334;
      }
      @media screen and (max-width: 991px) {
        padding: 16px 120px 16px 20px;
      }
      @media screen and (max-width: 767px) {
        padding: 12px 120px 12px 20px;
      }
    }
    .custom-search-button {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border: 0;
      background: #22aaff;
      color: #000000;
      outline: none;
      margin: 0;
      padding: 0 10px;
      border-radius: 100px;
      z-index: 2;
      width: 202px;
    }

    .search-button-gold {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border: 0;
      background: #fda544;
      color: #000000;
      outline: none;
      margin: 0;
      padding: 0 10px;
      border-radius: 100px;
      z-index: 2;
      width: 202px;
    }

    .arrow-svg {
      width: 13.6px;
      height: 13.6px;
      margin-left: 4.3px;
    }

    @media screen and (max-width: 1420px) {
      flex-direction: column !important;
      .custom-search {
        width: 700px;
      }
    }

    .newsletter-title {
      padding-bottom: 10px;
      padding-top: 10px;
    }

    @media screen and (max-width: 767px) {
      .custom-search {
        max-width: 600px;
        width: 100%;
      }
    }

    @media screen and (max-width: 737px) {
      .newsletter-title {
        font-size: 25px !important;
        padding-bottom: 20px;
      }

      .custom-search-button {
        width: auto;
        padding: 0 30px;
      }
    }

    @media screen and (max-width: 644px) {
      .font-size-24 {
        font-size: 18px !important;
      }
      // .custom-search {
      //   width: auto;
      // }
    }

    @media screen and (max-width: 587px) {
      .font-size-24 {
        font-size: 14px !important;
      }
    }

    @media screen and (max-width: 500px) {
      .newsletter-title {
        font-size: 24px !important;
        text-align: center;
      }
      // .custom-search {
      //   width: 220px;
      // }
      .custom-search-input {
        padding: 10px 10px 10px 20px !important;
        min-width: 200px !important;
      }

      .font-size-40 {
        font-size: 24px !important;
      }

      .custom-search {
        position: unset;
        flex-direction: column;
      }
      .newsletter-title {
        position: relative;
      }
      .custom-search-button,
      .search-button-gold {
        position: unset;
        padding: 10px 10px 10px 20px !important;
        min-width: 150px;
        margin-top: 20px;
      }
    }

    @media screen and (max-width: 312px) {
      .font-size-40 {
        font-size: 24px !important;
      }

      // .custom-search {
      //   width: 175px;
      // }
      .custom-search-input {
        padding: 10px 10px 10px 20px !important;
        min-width: 210px !important;
      }
    }
  }
}
