.get-rewards-section {
  .page-section {
    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }
  }
  .font-size-12 {
    font-size: 12px;
  }

  .rewards-p-container {
    @media screen and (max-width: 680px) {
      text-align: center;
    }
  }

  .get-rewards-corners-txt {
    @media screen and (max-width: 680px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .difficulty-card {
    position: relative;
    min-height: 374px;
    border-radius: 16px;
    background-color: #161616;
    padding: 3rem;

    &:not(:last-child) {
      margin-bottom: 65px;

      @media screen and (max-width: 1530px) {
        margin-bottom: 1.5rem;
      }
    }

    @media screen and (min-width: 1531px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (min-width: 501px) and (max-width: 991px) {
      display: flex;
      align-items: center;
      min-height: 255px;
    }

    @media screen and (max-width: 991px) {
      padding: 1.5rem;
    }

    @media screen and (max-width: 500px) {
      padding: 1.5rem;
    }
  }

  .card-image-absolute {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: -100px;
    max-width: 40%;
    @media screen and (max-width: 1700px) {
      left: -80px;
    }
    @media screen and (max-width: 1600px) {
      left: -65px;
    }
    @media screen and (max-width: 1550px) {
      display: none;
    }
  }

  .top-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;

    @media screen and (min-width: 1551px) {
      display: none;
    }

    @media screen and (min-width: 501px) and (max-width: 991px) {
      margin-right: 1.5rem;
      margin-bottom: 0;
    }
  }

  .top-card-image {
    width: 100%;
    max-width: 40%;
    height: auto;
    @media screen and (max-width: 1200px) {
      max-width: 50%;
    }
    @media screen and (max-width: 991px) {
      max-width: 100%;
    }
    @media screen and (max-width: 500px) {
      max-width: 60%;
    }
    @media screen and (max-width: 350px) {
      max-width: 80%;
    }
  }

  .top-paragraph {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 1550px) {
      justify-content: flex-start;
    }
  }

  .heading-container {
    width: 100%;
    max-width: 450px;
  }

  .get-rewards-corners {
    width: 100%;
    max-width: 586px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0;
    margin-left: auto;

    @media screen and (max-width: 1550px) {
      height: auto;
      margin-left: unset;
    }

    @media screen and (max-width: 991px) {
      max-width: 100%;
      margin-left: unset;
      flex-direction: row;
      padding: 1.5rem;
    }

    @media screen and (max-width: 680px) {
      flex-direction: column;
    }
  }

  .get-rewards-corners-col {
    @media screen and (max-width: 1550px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .bottom-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 600px) {
      justify-content: flex-start;
    }
  }

  .bottom-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 10px;
    overflow: hidden;

    @media screen and (max-width: 600px) {
      align-items: flex-start;
    }
  }

  .coin-image-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 991px) {
      margin-right: 1.5rem;
    }

    @media screen and (max-width: 680px) {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  .coin-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    @media screen and (max-width: 680px) {
      max-width: 60%;
    }
    @media screen and (max-width: 575px) {
      max-width: 80%;
    }
    @media screen and (max-width: 400px) {
      max-width: 100%;
    }
  }
}
