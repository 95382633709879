#router-functions-section {
  .page-section {
    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }
  }
  .router-functions-description {
    @media screen and (max-width: 767px) {
      max-width: 60%;
    }
    @media screen and (max-width: 640px) {
      max-width: 70%;
    }
    @media screen and (max-width: 520px) {
      max-width: 85%;
    }
    @media screen and (max-width: 430px) {
      max-width: 100%;
    }
  }

  .main-col {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
  }

  .router-functions-container {
    width: 100%;
    display: flex;
    margin-top: 4rem;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 3rem;
    }
  }

  .router-functions-description {
    max-width: 410px;
  }

  .description-col {
    flex: 1;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    @media screen and (max-width: 767px) {
      align-items: center;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 1rem;
      }

      p {
        text-align: center;
      }
    }
  }

  .box-title {
    margin: 36px 0 14px 0;
    @media screen and (max-width: 767px) {
      text-align: center;
    }

    @media screen and (max-width: 480px) {
      margin: 20px 0 12px 0;
    }
  }

  .image-container {
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1150px) {
      height: 250px;
    }

    @media screen and (max-width: 767px) {
      justify-content: center;
      height: unset;
    }
  }

  .consensus-first-section {
    padding-top: 100px;
  }

  .consensus-padding-title {
    padding-top: 26px;
    padding-bottom: 34px;
  }

  .consensus-card {
    padding-right: 48px;
    padding-top: 87px;
  }

  .read-more-link {
    width: 160px;
    color: #22aaff;
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.2s;

    svg {
      margin-left: 10px;
    }

    &:hover {
      color: white;
    }

    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }

  .col-image {
    width: 100%;
    height: auto;
  }

  .guardian-image {
    max-width: 160px;
    @media screen and (max-width: 1080px) {
      max-width: 150px;
    }
    @media screen and (max-width: 900px) {
      max-width: 110px;
    }

    @media screen and (max-width: 767px) {
      max-width: 180px;
    }

    @media screen and (max-width: 388px) {
      max-width: 130px;
    }
  }
  .router-image {
    max-width: 180px;
    @media screen and (max-width: 1080px) {
      max-width: 170px;
    }
    @media screen and (max-width: 900px) {
      max-width: 130px;
    }

    @media screen and (max-width: 767px) {
      max-width: 200px;
    }

    @media screen and (max-width: 388px) {
      max-width: 150px;
    }
  }
  .validator-image {
    max-width: 502px;

    @media screen and (max-width: 767px) {
      max-width: 300px;
    }
  }
}
