.meta-xiden-partner {
  &.page-section {
    @media screen and (max-width: 991px) {
      padding-bottom: 0 !important;
    }
  }
  .xiden-partner-img {
    width: 100%;
    max-width: 500px;
    height: auto;

    @media screen and (max-width: 767px) {
      max-width: 300px;
    }
  }

  .meta-centered-text {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px;
    margin-bottom: 28px;
  }
}
