.support-validator-steps-section {
    .text-start {
        margin: unset !important;

        @media screen and (max-width: 767px) {
            margin: auto !important;
        }
    }

    .img-bg{
        background: linear-gradient(#171a3d, #004b41);
        background: -webkit-linear-gradient(#171a3d, #004b41);
        padding: 2rem;
        border-radius: 16px;
    }

    .border-radius-16 {
        border-radius: 16px;
    }
}