#audits-section {
  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }
  .corners {
    max-width: 464px;
    height: 300px;
    margin: 1rem 0;

    @media screen and (max-width: 991px) {
      height: auto;
      min-height: 232px;
      aspect-ratio: 1 / 1;
    }
  }

  .column-wrapper {
    max-width: 300px;

    @media screen and (max-width: 991px) {
      margin: 0 auto !important;
    }
  }

  .wrapper-1 {
    margin-right: 2rem;
  }
  .wrapper-2 {
    margin-left: 2rem;
  }
  .custom-btn {
    max-width: 296px;

    @media screen and (max-width: 600px) {
      max-width: 210px;
    }

    @media screen and (max-width: 400px) {
      max-width: 190px;
    }
  }

  .disabled-btn {
    border: 2px solid #86868b;
    color: #86868b;

    &:hover {
      background-color: transparent;
      color: #86868b;
    }
  }

  //slider

  .slick-slide {
    display: flex;
    justify-content: center;
  }

  .audit-div {
    width: 100%;
    // height: 800px;
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .audit-event {
    width: 430px;
    min-width: 430px;
    cursor: grab;

    @media screen and (max-width: 570px) {
      width: 350px;
      min-width: 350px;
    }
    @media screen and (max-width: 500px) {
      width: 280px;
      min-width: 280px;
    }
    @media screen and (max-width: 400px) {
      width: 220px;
      min-width: 220px;
    }
  }

  .me-audit-slider-custom {
    margin-right: 3rem !important;
    @media screen and (max-width: 600px) {
      margin-right: 1rem !important;
    }
  }
}
