#what-is-xiden {
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    padding-bottom: 0px;
  }

  @media screen and (max-width: 991px) {
    padding-top: 2rem;
  }

  @media screen and (max-width: 600px) {
    padding-top: 0;
  }

  .description-991 {
    @media screen and (max-width: 991px) {
      max-width: 600px;
      width: 100%;
    }
  }

  .slider-col {
    min-height: 480px;

    @media screen and (max-width: 600px) {
      min-height: 460px;
      padding-bottom: 2rem;
    }

    @media screen and (max-width: 450px) {
      min-height: 430px;
    }
  }

  .active-box-title {
    width: 166px;
  }

  .bottom-0 {
    bottom: -50px;
    position: absolute;
  }

  .pos-absolute-slider {
    height: 480px;
    min-height: 480px;
    position: relative;

    @media screen and (max-width: 991px) {
      height: 450px;
      min-height: 450px;
    }

    @media screen and (max-width: 600px) {
      height: 420px;
      min-height: 420px;
    }

    @media screen and (max-width: 450px) {
      height: 400px;
      min-height: 400px;
    }
  }

  @media screen and (min-width: 992px) {
    .pos-absolute-slider {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      left: 60px;
    }
  }

  .slick-track {
    height: 100%;
  }

  @media screen and (min-width: 1450px) {
    .pos-absolute-slider {
      width: 128%;
    }
  }

  .what-contianer {
    display: flex;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }

  .info-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 992px) {
      align-items: center;
      margin-right: 0;
    }
  }

  .buttons-container {
    @media screen and (max-width: 992px) {
      justify-content: center;
    }
  }

  // .slider-col {
  //    flex: 1;
  //    overflow: hidden;
  //    display: flex;
  //    flex-direction: column;
  //    justify-content: center;
  //    // align-items: center;
  // }

  .slider-box {
    position: relative;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #161616;
    padding: 2rem 4rem;
    margin: 1.5rem;
    border-radius: 16px;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 991px) {
      padding: 2rem;
      height: 230px;
      margin: 1rem;
    }

    @media screen and (max-width: 600px) {
      margin: 1rem;
    }

    @media screen and (max-width: 350px) {
      padding: 2rem;
    }
  }

  .title-dot {
    color: #86868b;
  }

  .slide-image {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 45%;
    max-width: 320px;
    height: auto;

    @media screen and (max-width: 991px) {
      width: 40%;
    }

    // &.sdr-img {
    //   width: unset !important;
    //   max-width: 280px;
    //   top: -20px !important;
    // }
  }

  .blockchain-img {
    width: 70% !important;
    top: -20px !important;
  }

  .sdr-img {
    width: 70% !important;
    top: -20px !important;
  }

  .router-image {
    width: 40% !important;
  }

  .active-box-title {
    font-size: 32px;
    font-family: proxima-nova, sans-serif;
    font-weight: 700;

    @media screen and (max-width: 767px) {
      font-size: 28px;
    }

    @media screen and (max-width: 500px) {
      font-size: 24px;
    }
  }

  .hotspot-image {
    top: -60px !important;
    width: 70%;
  }

  .coin-image {
    width: 60%;
  }

  .new-validator {
    top: -70px;
    width: 60%;
  }

  .validator-img {
    top: 10px;
  }

  .slick-active {
    .slider-box {
      background-image: url("../../../assets/images/png/slide-box-bg.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 320px;
      overflow: visible;
      transition-delay: 0.5s;

      @media screen and (max-width: 991px) {
        height: 270px;
      }

      // @media screen and (max-width: 600px) {
      //   height: 280px;
      // }

      .slide-image {
        top: -60px;
        right: 50px;
        transition-delay: 0.6s;
      }

      .router-image {
        width: 45% !important;
      }

      .new-validator {
        width: 65%;
      }

      .validator-img {
        top: 10px;
        width: 80%;

        @media screen and (max-width: 991px) {
          width: 70%;
        }
      }

      .title-dot {
        color: #22aaff;
      }
    }

    .sdr-img {
      width: 90% !important;
    }

    .blockchain-img {
      width: 90% !important;
    }

    .slide-image {
      width: 50%;
      transition-delay: 0.5s;

      @media screen and (max-width: 991px) {
        width: 50%;
      }
    }

    .coin-image {
      width: 80%;
    }

    .hotspot-image {
      width: 80%;
    }
  }

  .slick-slide {
    min-height: 430px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 991px) {
      min-height: 400px;
    }

    @media screen and (max-width: 600px) {
      min-height: 360px;
    }

    // @media screen and (max-width: 350px) {
    //   min-height: 440px;
    // }
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .slick-list {
    @media screen and (max-width: 991px) {
      max-height: 485px;
    }
  }

  .apply-effect {
    //transition: .2s;
    animation: fadeEffect ease-in 0.5s forwards;
  }

  @keyframes fadeEffect {
    from {
      opacity: 0;
    }

    to {
      opacity: 100;
    }
  }
}