#meta-realm-steps {

   .line-height-1{
      line-height: 1!important;
   }
   // .container-main {
   //    max-width: 1200px;
   // }

   .light-red-txt-color {
      color: #f43b64;
   }

   .light-green-txt-color {
      color: #b6ff9b;
   }

   .column-wrapper {
      background-color: #0c023d;
      border-radius: 24px;
      height: 650px;
      margin-top: 1.5rem;

      @media screen and (max-width: 1089px) {
         height: 550px;
      }
      @media screen and (max-width: 991px){
         height: 500px;
      }
      @media screen and (max-width: 767px) {
         height: 400px;
      }
      @media screen and (max-width: 650px) {
         height: 370px;
      }
      @media screen and (max-width: 635px) {
         height: 390px;
      }
      @media screen and (max-width: 600px) {
         height: 350px;
      }
      @media screen and (max-width: 575px) {
         height: auto;
      }
   }

   .neon-cube {
      max-width: 200px;
      @media screen and (max-width: 991px) {
         max-width: 150px;
      }
      @media screen and (max-width: 767px) {
         max-width: 100px;
      }
   }

   .steps-card-icon {
      @media screen and (max-width: 991px) {
         width: 100%;
         max-width: 100px;
         height: auto;
      }

      @media screen and (max-width: 767px) {
         max-width: 80px;
      }
      @media screen and (max-width: 600px) {
         max-width: 60px;
      }
   }

   .configure-card {
      background-color: #0c023d;
      background-image: url("../../../assets/images/png/meta-realm-page/card-1-bg.png");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: contain;
      @media screen and (max-width: 600px){
         // background-size: cover; 
         background-image: url("../../../assets/images/png/meta-realm-page/card-1-bg2.png");
      }

      .inner-configure-cards {
         padding: 3rem;

         @media screen and (max-width: 991px) {
            padding: 3rem 1.5rem;
         }
      }
   }

   .activate-card {
      background-image: url("../../../assets/images/png/meta-realm-page/activate-card-bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
   }

   .stake-card{
      .stake-img{
         // path#_147{
         //    // fill: linear-gradient( #2DB3DE, #22AAFF);
         // }
         // @media screen and (max-width: 767px){
         //    max-width: 50%;
         // }
         position: relative;
         @media screen and (max-width: 575px){
            width: 60%!important;
            margin: auto;
         }
         @media screen and (max-width: 450px){
            width: 90%!important;
         }
         @media screen and (max-width: 400px){
            width: 100%!important;
         }

         .stake-coming-soon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }

         .stake-img-overlay{
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -60%);
            width: 45%;
            height: 110px;
            border-radius: 24px;
            // background-color: rgba(0, 0, 0, 0.5);

            @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
               -webkit-backdrop-filter: blur(10px);
               backdrop-filter: blur(10px);
               background-color: rgba(0, 0, 0, 0.2);
            }
            -webkit-backdrop-filter: saturate(180%) blur(20px);
            backdrop-filter: saturate(180%) blur(20px);
            background: rgba(0, 0, 0, 1);

            @media screen and (max-width: 1500px){
               width: 48%;
            }

            @media screen and (max-width: 1300px){
               width: 52%;
            }
            @media screen and (max-width: 1200px){
               width: 58%;
            }

            @media screen and (max-width: 1100px){
               width: 67%;
               height: 120px;
            }
            @media screen and (max-width: 870px){
               height: 95px;
            }
            @media screen and (max-width: 730px){
               height: 80px;
            }
            @media screen and (max-width: 640px){
               height: 70px;
            }
            @media screen and (max-width: 575px){
               height: 85px;
               border-radius: 16px;
            }
            @media screen and (max-width: 520px){
               height: 70px;
            }
            @media screen and (max-width: 450px){
               height: 95px;
            }
            @media screen and (max-width: 400px){
               height: 85px;
            }
            @media screen and (max-width: 340px){
               height: 70px;
            }
            @media screen and (max-width: 290px){
               height: 55px;
            }
         }
      }
   }

   .community-card {
      background-image: url("../../../assets/images/png/meta-realm-page/community-card-bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      @media screen and (max-width: 575px){
         background-image: unset;
         background-repeat: unset;
         background-position: unset;
         background-size: unset; 
         background-color: #0c023d;
      }

      .community-gradient {
         background: -webkit-linear-gradient(45deg, #9d2d7d, #e1969d, #3daedf);
         background-clip: text;
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
      }

      .community-card-img-mobile{
         @media screen and (max-width: 576px){
            max-width: 70%;
         }
         @media screen and (max-width: 450px){
            max-width: 90%;
         }
         @media screen and (max-width: 350px){
            max-width: 100%;
         }
      }
   }

   .protected-card {
      .protected-gradient {
         background: -webkit-linear-gradient(45deg, #85cec8, #244fc2);
         background-clip: text;
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
      }
   }

   .rewards-card {
      .rewards-gradient {
         background: -webkit-linear-gradient(45deg, #dc7768, #8f2da8);
         background-clip: text;
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
      }
   }

   .withdraw-card {
      background-image: url("../../../assets/images/png/meta-realm-page/withdraw-card-bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      @media screen and (max-width: 767px){
         background-image: unset;
         background-repeat: unset;
         background-position: unset;
         background-size: unset; 
         background-color: #0c023d;
      }

      .poker-chip-img{
         @media screen and (max-width: 767px){
            max-width: 120px;
         }
         @media screen and (max-width: 600px){
            max-width: 100px;
         }
         @media screen and (max-width: 400px){
            max-width: 80px;
         }
      }
      .br-withdraw{
         @media screen and (min-width: 501px){
            display: none!important;
         }
      }
      .withdraw-gradient {
         background: -webkit-linear-gradient(45deg, #9d2d7d, #e1969d, #3daedf);
         background-clip: text;
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
      }
   }
}
