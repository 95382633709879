#homepage-xiden {
  padding: 9rem 0 6rem 0;

  @media screen and (max-width: 991px) {
    padding: 7rem 0 0 0;
  }

  .ecosystem-uppertitle-container {
    @media screen and (max-width: 767px) {
      margin: 10px 0 15px;
    }
  }

  h2 {
    width: 586px;
    line-height: 1;
  }
  p {
    line-height: 1.2;
  }
  img {
    max-width: unset;
  }
  .section-image-col {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    // .section-image {
    //    height: auto;
    //    width: fit-content;
    // }
  }

  .ecosystem-hero-image {
    @media screen and (max-width: 991px) {
      max-width: 400px;
    }
  }

  @media screen and (max-width: 1850px) {
    h2 {
      width: unset;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 991.5px) {
    .container-main {
      flex-direction: column-reverse;
    }
    // .section-image {
    //    margin-bottom: 30px;
    //    width: 45% !important;
    // }
    .main-col {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 2rem;
      align-items: center;
      margin-right: 0;

      @media screen and (max-width: 991px) {
        margin-bottom: 0;
      } // margin-bottom: 3rem;
      h2 {
        text-align: center;
      }
      .paragraph-container {
        width: 100%;
        max-width: 600px;
        margin: auto;
        text-align: center;
      }
      .hero-btn-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      h2 {
        max-width: 586px;
      }
    }
  }

  // @media screen and (max-width: 991.5px) {
  //   .paragraph-container {
  //     width: 100%;
  // }

  // @media screen and (max-width: 767.5px) {
  //    .section-image {
  //       width: 100% !important;
  //    }
  // }

  @media (max-width: 462px) {
    .buttonOutlineBlue {
      margin-top: 0px !important;
    }
  }

  @media screen and (max-width: 320px) {
    .hero-btn-container {
      flex-direction: column;
      align-items: center;
    }
    .hero-custom-btn:first-child {
      margin: 0 0 15px 0 !important;
    }
    .buttonOutlineBlue {
      margin: 0 0 0 0 !important;
    }
  }
}
