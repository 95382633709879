#download-app {
  line-height: 1.3;
  background-color: #000;
  h2 {
    margin: 0 0 31px 0;
    padding: 0;
    line-height: 1;
  }
  p {
    line-height: 1.1;
  }

  .text-content {
    max-width: 708px;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .media-btn {
    background: transparent;
    border-radius: 12px;
    border: none !important;
    padding: 0 !important;
    margin: 53px 24px 0 0 !important;
    @media screen and (max-width: 991px){
      margin: 53px 0 0 0 !important;
    }
  }

  .media-btn:hover {
    img {
      border-radius: 8px;
      box-shadow: 0px 0px 20px 3px rgba(34, 170, 255, 0.6);
    }
  }

  .phone-img {
    max-width: 400px;
    width: 100%;
  }

  .download-img{
    @media screen and (max-width: 991px){
      width: 90%;
    }
  }

  @media screen and (max-width: 1040px) {
    button {
      margin: 30px 24px 0 0 !important;
    }
  }

  @media screen and (max-width: 991.5px) {
    .phone-img {
      margin-bottom: 55px;
      max-width: 350px;
      width: 100%;
    }
    .xideen-upper-title{
      justify-content: center;
      align-items: center;
    }

    .download-app-title{
      text-align: center;
    }
    .phone-col {
      justify-content: center !important;
    }

    .rand {
      flex-direction: row-reverse !important;
    }

    .text-content {
      max-width: 400px;
      margin: auto;
      text-align: center;
    }

    .buttons-media {
      justify-content: center;
    }
  }

  @media screen and (max-width: 771px) {
    .text-content {
      max-width: unset !important;
    }
  }

  @media screen and (max-width: 767px){
    .phone-img {
      max-width: 280px;
    }
  }

  @media screen and (max-width: 480px) {
    .buttons-media {
      width: 100%;
      // flex-direction: column;
      margin-top: 40px;
    }


    .media-btn {
      margin: 12px 0 0 0 !important;
    }
  }

}
