#howto-guardian {
    background-color: black;
    h2,h3,h4,p{
        line-height: 1;
    }

    svg{
        margin: 0!important;
        margin-left: 10px!important;
    }

    .rand {
        height: 100%;
        p {
            max-width: 440px;
        }
    }

    .corners {
        .rand{
            align-items: center!important;
            align-content: center!important;
            justify-content: center!important;
        }
    }

    .link-container {
        cursor: pointer;
        width: 220px;

        svg {
            transition: 0.2s;
            color: #22AAFF;
        }

        p {
            margin-bottom: 0 !important;
            margin-top: 0 !important;
            transition: 0.2s;
        }
    }

    .link-container:hover {
        p {
            color: white;
        }
        svg {
            color: white;
        }
    }

    .left,
    .right {
        img {
            margin: auto;
        }
        .daps-img{
            margin-left: 0;
            @media screen and (max-width: 991px){
                margin: auto;
            }
        }
    }

    @media screen and (max-width: 991px) {
        .rand {
            p {
                max-width: unset;
            }
        }
        .corners {
            height: unset;
            margin: 0 0 1.5rem 0;
        }

        img{
            width: 50%;
            margin-top: 30px!important;
        }

        .market-img{
            width: 25%;
        }

        .title {
            h2 {
                margin-bottom: 3rem;
                margin-top: 0;
            }
        }
    }

    @media screen and (max-width: 480px){

        img{
            width: 80%;
            margin-top: 30px!important;
        }

        .market-img{
            width: 50%;
        }
    }
}
