#faq-validator {
  .accordion-body {
    //padding-left: 0 !important;
    padding-right: 24px !important;
    padding-bottom: 24px !important;

    @media screen and (max-width: 991px) {
      padding-bottom: 12px !important;
      padding-right: 12px !important;
      padding-left: 12px !important;
    }
  }
  .accordion-button {
    padding-left: 0;
    padding-right: 0;

    div {
      padding-right: 1rem;
    }
  }

  p {
    margin-bottom: 0 !important;
  }

  svg {
    min-width: 12px;
    min-height: 12px;
    color: white !important;
  }

  .accordion-item {
    border-width: 1px !important;
    border-style: solid !important;
    border-image: linear-gradient(to left, rgba(34, 170, 255, 0.5), rgba(175, 74, 255, 0.5)) 0 0 1 0 !important;
  }

  h2 {
    margin-bottom: 0;
  }

  .btn-all-questions {
    margin: auto;
    width: 187px;
    color: #22aaff;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;

    svg {
      margin-left: 10px;
      color: #22aaff !important;
      transform: rotate(90deg);
      transition: 0.2s;
      margin-bottom: 0;
      margin-top: 0;
    }
    //#174164

    &:hover {
      color: white !important;
      svg {
        color: white !important;
      }
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: row;
  }

  .img-btn {
    border-radius: 50%;
    &:hover {
      box-shadow: 0px 0px 20px rgba(34, 170, 255, 1);
    }
  }

  .pg {
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid rgba(34, 170, 255, 1);
  }

  .btn-container {
    .active {
      background-color: rgba(34, 170, 255, 1);
    }
  }

  .hide-questions {
    svg {
      transform: rotate(270deg);
    }
  }
}
