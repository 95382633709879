#boosters-section {
  background-image: url("../../../assets/images/png/rewardsPage/boosters-section-bg.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  // padding-bottom: 0;
  // margin-bottom: 6rem;
  background-color: #000;

  @media screen and (max-width: 991px) {
    background-size: cover;
    padding-bottom: 0;
    padding-top: 0;
  }

  .boosters-section-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cards-control-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .rewards-p-container-lg {
    max-width: 900px;

    @media screen and (max-width: 991px) {
      max-width: 600px;
    }
  }

  .rewards-boosters-overlay {
    z-index: 50;
    border-radius: 16px;
  }

  .transparent-card {
    max-width: unset;
  }

  .custom-transparent-card {
    width: 25%;

    @media screen and (max-width: 860px) {
      width: 50%;
    }

    @media screen and (max-width: 460px) {
      width: 100%;
    }
  }

  .w-80 {
    width: 80%;

    @media screen and (max-width: 1000px) {
      width: 100%;

      .custom-first-card {
        width: 100%;
      }
    }
  }

  .transparent-cards-first-row,
  .transparent-cards-second-row {
    @media screen and (max-width: 860px) {
      flex-wrap: wrap;
    }
  }

  .custom-cards-container {
    @media screen and (max-width: 1000px) {
      flex-direction: column !important;
    }
  }

  .transparent-cards-first-row {
    .custom-transparent-card {
      padding: 0.75rem;
      padding-top: 0;

      @media screen and (max-width: 1000px) {
        padding-top: 0.75rem;
      }

      .transparent-card {
        margin-bottom: 0;
        min-height: unset;
        height: 120px;
      }

      @media screen and (max-width: 460px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .transparent-cards-second-row {
    .custom-transparent-card {
      padding: 0.75rem;
      //padding-bottom: 0;

      .transparent-card {
        margin-bottom: 0;
        min-height: unset;
        height: 120px;
      }

      @media screen and (max-width: 460px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .custom-first-card {
    width: 20%;
    padding: 0 0.75rem 0 0;
    height: 264px;

    @media screen and (max-width: 1000px) {
      width: 100%;
      height: 120px;
      margin-bottom: 12px;
      padding: 0;
    }

    @media screen and (max-width: 460px) {
      padding-left: 0;
      padding-right: 0;
    }

    .transparent-card {
      height: 100%;
      margin-bottom: 0;
      // min-height: 264px;
      // max-width: 268px;
      color: white !important;
      text-align: center;
      background-image: url("../../../assets/images/png/rewardsPage/booster-card-bg.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      @media screen and (max-width: 1000px) {
        min-height: 120px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .padding-start-custom {
      padding-left: 0 !important;
    }

    .padding-end-custom {
      padding-right: 0 !important;
    }
  }

  @media screen and (max-width: 860px) {
    .padding-start-custom {
      padding-left: 12px !important;
    }

    .padding-end-custom {
      padding-right: 12px !important;
    }

    .padding-odd {
      padding-right: 0 !important;
    }

    .padding-even {
      padding-left: 0 !important;
    }
  }
}
