#metamask-support-hero-page {
    padding-top: calc(3rem + 110px) !important;
    background-color: black;
    min-height: 500px;

    .jump-button{
        background-color: transparent;
        border: none;
        color: white;
        width: fit-content;
        width: -moz-fit-content;
        padding-left: 0;
    }

    .row {
        margin-left: 0 !important;
        margin-right: 0 !important; 
    }

    a {
        text-decoration: none;
        cursor: pointer;
    }

    .mobile-col{
        padding: 0;
        @media screen and (max-width: 991px){
            justify-content: center!important;
            margin: 0;
            padding: 0;
            .hero-img{
                margin: auto!important;
                margin-bottom: 2rem!important;
            }
        }
    }

    .content-col {
        padding: 0;
        @media screen and (max-width: 991px){
            margin: 0;
            padding: 0;
            justify-content: center!important;
            align-items: center!important;

            .title-hero {
                text-align: center;
            }
        }
    }

    .hero-row {
        @media screen and (max-width: 991px){
            flex-direction: column-reverse!important;
        }
    }
}