#rewards-xiden-page {
  background-color: black;
  line-height: 1.3;
  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }

  svg {
    margin: 0 !important;
    margin-left: 10px !important;
  }

  .rand {
    height: 100%;
    p {
      //  max-width: 440px;
      line-height: 1.1;
    }
  }

  .corners {
    .rand {
      align-items: center !important;
      align-content: center !important;
      justify-content: center !important;
    }
  }

  .link-container {
    cursor: pointer;
    width: fit-content;
    width: -moz-fit-content;
    //   width: 220px;

    svg {
      transition: 0.2s;
      color: #22aaff;
    }

    p {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      transition: 0.2s;
    }
  }

  .link-container:hover {
    p {
      color: white;
    }
    svg {
      color: white;
    }
  }

  .left,
  .right {
    img {
      margin: auto;
    }
  }

  .title {
    h2 {
      line-height: 1;
    }
  }

  .ecosystem-rewards-info-col {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      width: 100%;
      max-width: 500px;
    }

    @media screen and (max-width: 767px) {
      align-items: center;
      text-align: center;
    }
  }

  .ecosystem-rewards-img {
    width: 100%;
    max-width: 500px;

    @media screen and (max-width: 767px) {
      max-width: 350px;
    }
  }

  .eco-dapps-img {
    max-width: 370px;

    @media screen and (max-width: 767px) {
      max-width: 300px;
    }
  }

  @media screen and (max-width: 991px) {
    // .rand {
    //     p {
    //         max-width: unset;
    //     }
    // }
    .corners {
      height: unset;
      margin: 0 0 1.5rem 0;
    }

    //   img {
    //      width: 50%;
    //      margin-top: 30px !important;
    //   }

    .market-img {
      width: 25%;
    }

    .title {
      h2 {
        margin-bottom: 1rem;
        margin-top: 0;
      }
    }
  }

  //    @media screen and (max-width: 480px) {
  //       img {
  //          width: 80%;
  //          margin-top: 30px !important;
  //       }

  //       .market-img {
  //          width: 50%;
  //       }
  //    }
}
