.white-color {
   color: #ffffff !important;
}
.lightgrey-color {
   color: #d1d1d1;
}
.darkgrey-color {
   color: #86868b;
}
.black-color {
   color: #000000;
}
.blue-color {
   color: #22aaff;
}
.purple-color {
   color: #af4aff;
}
.lightgreen-color {
   color: #00e0b8;
}
.pink-text {
   color: #af4aff;
}

.yellow-text {
   color: #fda544;
}
.medium-green-text {
   color: #20deb0;
}

.pinkGradientText {
   background-color: #22aaff;
   background-image: linear-gradient(to right, #22aaff, #8571db, #e23bb9);
   // background-size: 70%;
   background-size: 100%;
   background-repeat: repeat;
   background-clip: text;

   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   -moz-background-clip: text;
   -moz-text-fill-color: transparent;
}

.redGradientText {
   background-color: #22aaff;
   background-image: linear-gradient(to bottom, #ee4bb6, #f72837);
   background-size: 100%;
   background-repeat: repeat;
   background-clip: text;

   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   -moz-background-clip: text;
   -moz-text-fill-color: transparent;
}

.purpleGradientText {
   background-color: #22aaff;
   background-image: linear-gradient(to left, #af4aff, #6e69ff 60%, #477bff);

   background-size: 100%;
   background-repeat: repeat;
   // background-clip: text;

   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   -moz-background-clip: text;
   -moz-text-fill-color: transparent;
}
