#benefits-ecosystem {
  @media screen and (max-width: 1440px) {
    padding-bottom: 0px;
  }
  .title-description {
    max-width: 600px;
  }
  p {
    line-height: 1.1;
  }
  .column-wrapper {
    max-width: 260px;
    height: 250px;
    //border: 1px solid red;
    margin: 1.5rem 0 1.5rem 0;
  }

  .logo-container {
    width: 72px;
    min-height: 72px;
    background-color: #161616;
    border-radius: 50%;
  }

  .cards-row {
    p {
      margin-bottom: 0;
    }
  }

  .gradient-subtitle {
    max-width: 380px;
    background: linear-gradient(to right, #22aaff, #af4aff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 1350px) {
    // .font-size-32 {
    //     font-size: 27px;
    // }
  }
  @media screen and (max-width: 1300px) {
    .column-wrapper {
      height: 280px;
    }
  }
  @media screen and (max-width: 1200px) {
    .column-wrapper {
      height: 240px;
    }
  }

  @media screen and (max-width: 991px) {
    .column-wrapper {
      height: 250px;
    }

    // h4{
    //     font-size: 28px;
    // }

    .title-description {
      max-width: 450px;
    }
  }
  @media screen and (max-width: 768px) {
    .column-wrapper {
      max-width: unset;
      //height: 180px;

      p {
        max-width: 450px;
        font-size: 16px !important;
      }
    }
  }

  @media screen and (max-width: 580px) {
    .column-wrapper {
      height: unset;
    }
  }
}
