#developer-section {
  background-color: black;
  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }

  .developers-section {
    .row > * {
      padding: 0px !important;
    }
    .container-fluid {
      padding: 0px !important;
    }
    .rewards-wrapper {
      padding-right: 12px !important;
      padding-left: 12px !important;
    }

    .developers-first-section {
      .developers-col {
        background-color: #161616;
        border-radius: 24px;
        // height: 159px;
        width: 100%;
        max-width: 159px;
        padding: 30px 20px;

        &:not(:first-child) {
          margin-left: 12px;
        }
        &:not(:last-child) {
          margin-right: 12px;
        }

        // .framework-img {
        //    // width: 65%;
        //    width: 80px;
        //    @media screen and (max-width: 767px) {
        //       // width: 55%;
        //    }

        //    &.solidity-img {
        //       // width: 50%;
        //       @media screen and (max-width: 767px) {
        //          // width: 45%;
        //       }
        //    }
        // }

        @media screen and (max-width: 1200px) {
          &:not(:first-child) {
            margin-left: 8px;
          }
          &:not(:last-child) {
            margin-right: 8px;
          }
        }
        @media screen and (max-width: 767px) {
          max-width: 130px;
          padding: 20px;
        }
      }

      .developers-laptop-img {
        width: 80%;
        @media screen and (max-width: 991px) {
          width: 40%;
        }
        @media screen and (max-width: 550px) {
          width: 60%;
        }
        @media screen and (max-width: 400px) {
          width: 80%;
        }
      }

      .container {
        padding: 0px !important;
      }

      .developers-img-container {
        text-align: center;
        background-image: url("../../../components/Assets//images/png/big-sphere.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @media screen and (max-width: 991.5px) {
          // text-align: unset;
        }
      }
    }
    .designed-for-developers {
      h2 {
        margin-bottom: 27px;
      }
      p {
        margin-bottom: 25px;
      }
    }
    .get-started-btn {
      background-color: transparent;
      border: none;
      // font-size: 20px;
    }
    .get-started-btn:hover {
      color: white;
    }

    .framework-container {
      @media screen and (max-width: 991px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      h4 {
        margin-top: 64px;
        margin-bottom: 26px;
        @media screen and (max-width: 991px) {
          margin-top: 25px;
        }
      }
    }
    .developers-second-section {
      .rewards-background {
        background-color: #161616;
        border-radius: 24px;
      }

      .developer-six-col {
        height: 480px;
        border-radius: 24px;
      }
      .developer-three-col {
        height: 480px;
        @media screen and (max-width: 1200px) {
          height: 370px;
        }
        @media screen and (max-width: 991px) {
          height: 400px;
        }
        @media screen and (max-width: 767px) {
          height: 370px;
        }
        @media screen and (max-width: 575px) {
          height: auto;
        }
      }

      .graphic-leverage-img {
        //max-width: 70%;
        height: 120%;
        @media screen and (max-width: 1400px) {
          height: 100%;
          // max-width: 45%;
          //max-width: 40%;
        }
      }

      .graphic-execution-img {
        //max-width: 70%;
        height: 120%;
        @media screen and (max-width: 1400px) {
          // max-width: 60%;
          //max-width: 40%;
          height: 100%;
        }
      }

      .first-row-col {
        padding-bottom: 24px !important;
      }

      #background-one {
        background-image: url("../../../components/Assets/images/png/fat-blurp.png");
        background-repeat: no-repeat;
        background-size: cover;
      }

      #background-two {
        background-image: url("../../../components/Assets/images/png/big-blurp.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      #background-three {
        background-image: url("../../../components/Assets/images/png/long-blurp.png");
        background-size: cover;
        background-repeat: no-repeat;
      }

      #background-four {
        background-image: url("../../../components/Assets/images//png//small-sphere.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    @media (max-width: 1634px) {
      .second-row {
        margin-top: 0px !important;
      }
    }

    @media screen and (max-width: 1200px) {
      .developers-second-section .developer-six-col {
        height: 430px;
      }
    }

    @media (max-width: 1176px) {
      .developers-first-section {
        flex-direction: column;
      }
    }

    @media (max-width: 1086px) {
      .developers-second-section .developer-six-col {
        br {
          display: none;
        }
      }
    }

    @media only screen and (max-width: 991.5px) {
      .developers-second-section .developer-six-col {
        margin-bottom: 1.5rem;
        height: 400px;
        .rewards-wrapper-text {
          width: 50%;
        }
        .graphic-leverage-img,
        .graphic-execution-img {
          max-width: unset;
          height: 100%;
        }
      }
      .developers-second-section .first-row-col {
        padding-bottom: 0px !important;
      }
      .developers-first-section {
        flex-direction: column;
      }
      .framework-row {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column !important;
      }
      .designed-for-developers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        // h2 > br {
        //    display: none;
        // }
      }

      // .rewards-background {
      //    br {
      //       display: none;
      //    }
      // }
      .developer-three-col {
        margin-bottom: 1.5rem !important;
      }
    }
    // @media screen and (max-width: 767px){
    //    .developers-second-section .developer-six-col {
    //       .graphic-leverage-img,
    //       .graphic-execution-img {
    //          max-width: 35%;
    //       }
    //    }
    // }
    @media (max-width: 640px) {
      // br {
      //    display: none;
      // }
    }

    @media screen and (max-width: 600px) {
      .developers-second-section .developer-six-col {
        height: 340px;
      }
    }
    @media screen and (max-width: 450px) {
      .developers-second-section .developer-six-col {
        height: auto;
        flex-direction: column;

        .rewards-wrapper-text {
          width: 100%;
        }

        .graphic-leverage-img {
          align-self: flex-end;
          max-width: 300px;
          height: auto;
        }

        .graphic-execution-img {
          align-self: flex-start;
          max-width: 300px;
          height: auto;
        }
      }
      .developers-second-section .second-row-col .developer-six-col {
        padding-bottom: 0 !important;
        .rewards-wrapper-text {
          padding-right: 1.5rem;
        }
      }
      .developers-second-section .second-row-col-two .developer-six-col {
        padding-top: 0 !important;
        .rewards-wrapper-text {
          padding-left: 1.5rem;
        }
      }
    }

    // @media screen and (min-width: 461px) {
    //    .framework-section-mobile {
    //       display: none !important;
    //    }
    // }

    .framework-icon {
      width: 140px;

      &:not(:last-child) {
        margin-right: 24px;
      }

      @media screen and (min-width: 992px) and (max-width: 1500px) {
        width: 100px;
      }

      @media screen and (max-width: 991px) {
        width: 130px;
      }
      @media screen and (max-width: 767px) {
        width: 120px;
      }
      @media screen and (max-width: 650px) {
        width: 90px;
      }
      @media screen and (max-width: 520px) {
        width: 70px;
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
      @media screen and (max-width: 380px) {
        width: 65px;
      }
      @media screen and (max-width: 350px) {
        width: 55px;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      @media screen and (max-width: 320px) {
        width: 50px;
      }
    }

    @media screen and (max-width: 460px) {
      .framework-section-mobile {
        .developers-col {
          width: 90px;
          height: 90px;
        }
        .framework-section-mobile-row1 {
          padding-bottom: 16px;
        }
      }
    }

    @media screen and (max-width: 450px) {
      .rewards-background {
        overflow: hidden;
      }
      .developers-second-section .developer-six-col {
        .graphic-leverage-img {
          // max-width: 200px;
          // height: auto;
          height: 250px;
        }
        .graphic-execution-img {
          height: 250px;
          // max-width: 200px;
          // height: auto;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
