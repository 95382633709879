.modal-steps{
    color: white !important;

    .modal-content{
        background: #131313 !important;
    }

    .modal-header {
        border: none !important;
        padding: 1rem !important;
    }

    .modal-body {
        padding: 1rem !important;
    }

    .pointer {
        cursor: pointer;
    }

    .modal-dialog {
        max-width: 1500px !important;
        min-width: unset !important;
        padding: 0 1rem !important;
    }

    svg {
        fill: white !important;
    }

    .close-btn{
        position: absolute;
        top: 10px;
        right: 24px;
        z-index: 999;
        width: 16px;
        height: auto;

        @media screen and (max-width: 991px){
            width: 18px;
        }
    }
}