#performer-guardian-hero {
    h2,h3,h4,p{
        line-height: 1;
    }
    //min-height: 1080px;
    .container-main {
        display: flex;
    }

    .paragraph-container {
      
        min-width: 620px !important;
        @media screen and (max-width: 1100px) {
            min-width: 500px !important;
        }
        @media screen and (max-width: 991px) {
            min-width: unset !important;
            text-align: center;
        }

        @media screen and (max-width: 660px) {
            max-width: 400px !important;
          }
    }
    @media screen and (max-width: 660px) {
        max-width: unset !important;
      }

    padding: 12rem 0 6rem 0;
    @media screen and (max-width: 768px){
        padding: 10rem 0 6rem 0;
    }

   

    @media screen and (max-width: 991px) {
        .container-main {
            flex-direction: column-reverse;
            align-items: center;
        }
        button {
            margin: auto;
        }
    }

    .main-col {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 640px;


        @media screen and (max-width: 991px) {
            align-items: center;
            margin-right: 0;
            margin-bottom: 3rem;
            justify-content: start;
            margin-top: 2rem;
        }

        @media screen and (max-width: 480px){
            margin-bottom: 24px;
        }

        .hero-btn-container {
            width: 100%;
            display: flex;
            align-items: center;

            @media screen and (max-width: 991) {
                width: 100%;
                justify-content: flex-start;
            }

            @media screen and (max-width: 400px) {
                flex-direction: column;
                align-items: flex-start;

                button:first-child {
               
                    margin-bottom: 1.5rem;
                }
            }
        }
    }

    .section-image-col {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        img{

            max-width: unset!important;
            transform: translateX(70px);
            
            @media screen and (max-width: 1850px){
                max-width: 700px;
                transform: none;

            }
        }
       

        .section-image {
            //width: 90%;
            height: auto;
           
            @media screen and (max-width: 1750px){
                width: 110%;
                overflow: unset;
            }
           
        }

        @media screen and (max-width: 991px) {
            .section-image {
                max-width: 400px;
            }
            img{
                max-width: unset;
                width: 60%!important;
            }
        }
        // @media screen and (max-width: 768px) {
        //     img{
        //         max-width: unset;
        //         width: 60%!important;
        //     }
        // }
        @media screen and (max-width: 480px) {
            img{
                max-width: unset;
                width: 90%!important;
            }
        }
        @media screen and (max-width: 340px) {
            img{
                max-width: unset;
                width: 90%!important;
            }
        }
    }
}
