#terms-of-use-section {
  .m-custom {
    margin: 5rem 0 3rem 0;

    @media screen and (max-width: 991px) {
      margin: 2rem 0 2rem 0;
    }
  }

  li {
    list-style-type: none;

    &:hover {
      color: #d1d1d1 !important;
    }
  }

  ul {
    @media screen and (max-width: 991px) {
      padding-left: 1rem;
    }
  }

  .terms-link {
    text-decoration: underline;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: #22aaff;
    }
  }
}
