#sdr-hero {
  @media screen and (max-width: 991px) {
    &.page-section {
      padding-bottom: 0;
    }
  }

  padding-top: 100px;

  @media screen and (max-width: 767px) {
    padding-top: 100px;
  }

  .main-col {
    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  .hero-btn-container {
    text-decoration: none;

    .get-started-btn {
      text-decoration: none;
      max-width: 220px;
      width: 100%;
      margin-right: 3rem;

      @media screen and (max-width: 1200px) {
        max-width: 180px;
        margin-right: 1.5rem;
      }
    }

    @media screen and (max-width: 380px) {
      flex-direction: column;

      .get-started-btn {
        max-width: 160px;
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 0 !important;

        button {
          margin-right: 0 !important;
        }
      }
    }
  }

  .button-container {
    // width: 500px;
    margin-bottom: 0;

    @media screen and (max-width: 991px) {
      margin-bottom: 0;
    }

    // @media screen and (max-width: 1020px) {
    //     width:auto;
    // }
  }

  .buttonBlue {
    margin: 0 !important;
    // margin-right: 130px !important;

    @media screen and (max-width: 440px) {
      // margin-right: 100px !important;
    }
  }

  .buttonOutlineBlue {
    @media screen and (max-width: 440px) {
      margin-left: 0px !important;
    }
  }

  .sdr-hero-video {
    max-width: 2000px;

    @media screen and (max-width: 991px) {
      overflow: hidden;
      width: 100%;
      justify-content: center !important;
      align-items: center !important;
    }
  }

  .hero-video {
    @media screen and (max-width: 991px) {
      max-width: 700px;
    }
  }
}
