.xiden-ecosystem-section {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 991px) {
    padding-bottom: 0px !important;
  }
  .realm-image {
    width: 100%;
    max-width: 350px;
    height: auto;
    @media screen and (max-width: 991px) {
      max-width: 280px;
    }
  }

  .overlay {
    position: relative;
    height: 450px;
    background-image: linear-gradient(#161616, transparent, 60%);

    @media screen and (max-width: 1300px) {
      height: unset;
    }
  }

  .cubes-image {
    position: absolute;
    top: 0;
    left: 46%;
    transform: translateX(-50%, -50%);
    z-index: 10;

    @media screen and (max-width: 1300px) {
      display: none;
    }
  }

  .realm-row {
    @media screen and (min-width: 1301px) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 20;
    }
  }

  .realms-subsection {
    width: 100%;
    padding-top: 4rem;
  }

  // .model-3d-subsection {
  //    width: 100%;
  //    height: 1200px;
  //    // height: 1000px;
  //    // background-image: url("../../../assets/images/png/metarealm-ecosystem-page.png");
  //    background-image: url("../../../assets/images/png/ecosystem/realm.jpg");
  //    background-position: center;
  //    background-size: contain;
  //    background-repeat: no-repeat;

  //    @media screen and (min-width: 1921px) {
  //       // height: 900px;
  //       height: 1200px;
  //       background-image: url("../../../assets/images/png/ecosystem/realm-lg.jpg");
  //    }

  //    // @media screen and (max-width: 1600px) {
  //    //    background-size: cover;
  //    // }

  //    @media screen and (max-width: 1300px) {
  //       height: 800px;
  //    }
  //    @media screen and (max-width: 1024px) {
  //       height: 600px;
  //    }
  //    @media screen and (max-width: 767px) {
  //       // height: 500px;
  //       background-size: cover;
  //    }
  // }

  .btn-container {
    display: flex;

    @media screen and (max-width: 320px) {
      flex-direction: column;

      button:first-child {
        margin-right: 0 !important;
        margin-bottom: 15px;
      }
      button:last-child {
        margin-left: 0 !important;
      }
    }
  }

  .ecosystem-large-container {
    @media screen and (min-width: 1920px) {
      max-width: 100%;
    }
    @media screen and (min-width: 2600px) {
      max-width: 100%;
      padding-top: 8rem;
    }
  }

  .mt-custom {
    margin-top: 8rem;
    @media screen and (max-width: 1250px) {
      margin-top: 1rem;
    }
  }

  .video-fluid {
    max-width: 100%;
    height: auto;

    @media screen and (max-width: 1250px) {
      max-width: 1920px;
    }
    @media screen and (max-width: 991px) {
      max-width: 1024px;
    }
  }

  .video-container {
    overflow: hidden;
  }
}
