#meta-realm-hero {
  overflow: hidden;

  &.page-section {
    padding-bottom: 0;
    padding-top: 0;
    @media screen and (max-width: 991px) {
      padding-bottom: 0;
      // padding-top: 8rem;
    }
  }

  .metarealm-uppertitle-container {
    @media screen and (max-width: 767px) {
      margin: 10px 0 15px;
    }
  }
  .container-main {
    @media screen and (max-width: 991px) {
      padding-right: 0;
    }
  }
  .hero-img {
    @media screen and (max-width: 1600px) {
      max-height: 900px;
    }
    @media screen and (max-width: 1400px) {
      max-height: 800px;
    }
    @media screen and (max-width: 1100px) {
      max-height: 700px;
    }
    @media screen and (max-width: 991px) {
      padding-bottom: 2rem;
      max-height: unset;
      width: 85%;
      margin-left: auto;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 991px) {
    padding-top: 6rem;
    .description-991 {
      margin: auto;
    }
  }
  @media screen and (max-width: 991px) {
    .xideen-upper-title {
      justify-content: center;
      align-items: center;
    }
    .buttons-flex-direction {
      width: 100%;
      justify-content: center !important;

      @media screen and (max-width: 319px) {
        flex-direction: column;

        button {
          margin-right: 0 !important;
          margin-left: 0 !important;
        }

        button:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .meta-hero-video {
    max-width: 1700px;
  }
}
