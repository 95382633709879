
.staking-page{
    // padding-top: 10rem;
    background-color: #000;

    @media screen and (max-width: 991px){
        padding-top: 7rem;
    }

    .text-center-991{
        @media screen and (max-width: 991px){
            text-align: center;
        }
    }

    .description-991{
        @media screen and (max-width: 991px){
           width: 60%;
        }
        @media screen and (max-width: 767px){
           width: 80%;
        }
        @media screen and (max-width: 600px){
           width: 90%;
        }
        @media screen and (max-width: 500px){
           width: 100%;
        }
    }

    .buttons-flex-direction{
        width: 100%;
        @media screen and (max-width: 400px){
            flex-direction: column;
            align-items: flex-start!important;
            padding-top: 1.5rem!important;
        }
    }
}