#prizes-steps-section {
    .design-title{
        color: #5A40EE;
    }
    .performance-title{
        color: #A944F8;
    }
    .pool-number{
        border: 10px solid #A944F8 !important;

        @media screen and (max-width: 991px) {
            border: 5px solid #A944F8 !important;
        }
    }
    .corners {
        height: 100%;
        padding-top: 0 !important;
    }

    .step-number{
        min-width: 90px;
        height: 90px;
        border-radius: 50%;
        border: 10px solid #5A40EE;

        @media screen and (max-width: 991px) {
            min-width: 50px;
            height: 50px;
            border: 5px solid #5A40EE;
        }
    }

    .step-container{
        @media screen and (max-width: 991px) {
            align-items: flex-start !important;
        }
    }

    .step-description{
        padding-left: 15px;
    }

    .step-title{
        position: relative;
        margin-left: 15px;

        &::after {
            content: "";
            position: absolute;
            top:50%;
            left: -15px;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #ffffff;
        }
    }
}