#help-network {
    h2,h3,h4,p{
        line-height: 1;
    }
    .description{
        max-width: 700px;
    }

    .wrapper-coloana{
        .container-img{
            min-height: 480px;

            img{
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        @media screen and (max-width: 767px){
            .container-img{
                min-height: 300px;
                //justify-content: flex-start!important;
            }
            img{
                width: 45%;
            }

            .basm-img{
                width: 70%;
            }
            .pc-img{
                width: 70%;
            }
            
         
            
        }
        @media screen and (max-width: 575px){
            img{
                width: 35%;
            }

            .basm-img{
                width: 70%;
            }
            .pc-img{
                width: 65%;
            }
            .container-img{
                min-height: unset;
            }
            .basm-title {
                padding-top: 20px;
            }
           
        }

     
        @media screen and (max-width: 340px){
            img{
                width: 45%;
            }

            .basm-img{
                width: 90%;
            }
            .pc-img{
                width: 75%;
            }
            
        }
    }
}