#types-section {
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  button {
    width: fit-content;
    background: transparent;
    border: none;
    transition: 0.2s;

    @media screen and (max-width: 991px) {
      width: auto;
    }

    svg {
      margin-left: 10px;
    }

    &:hover {
      color: white;
    }
  }

  .btn2,
  .btn4 {
    width: fit-content;

    @media screen and (max-width: 991px) {
      width: auto;
    }
  }
  .btn3 {
    width: fit-content;

    @media screen and (max-width: 991px) {
      width: auto;
    }
  }
  .p-types-width {
    max-width: 425px;

    @media screen and (max-width: 991px) {
      text-align: center !important;
    }
  }

  .rand {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .corners {
    margin-bottom: 80px !important;
    &:last-child {
      margin-bottom: 0px !important;
    }
    img {
      margin: auto;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 1.5rem !important;
      height: 100%;
      align-items: center;
      justify-content: center;

      img {
        width: 40%;
        margin-top: 2rem;
      }

      .contact-img {
        width: 30%;
      }

      .col-img {
        display: flex;
      }
    }

    @media screen and (max-width: 600px) {
      img {
        width: 90%;
      }
      .contact-img {
        width: 60%;
      }
    }
  }
  .mobile-flex {
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .types-img {
    @media screen and (max-width: 991px) {
      margin-bottom: 1rem !important;
    }
  }

  .smart-ct-title {
    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }
}
