#prizes-timeline-section {

    background-image: url("../assets/digger.png");
    background-position: calc(50% + 350px) center;
    background-repeat: no-repeat;
    background-size: 600px;
    
    @media screen and (max-width: 1350px){
        background-position: calc(50% + 250px) center;
    }
    
    @media screen and (max-width: 1150px){
        background-position: calc(50% + 200px) center;
    }
    @media screen and (max-width: 1050px){
        background-image: none;
    }


    .line-height-1 {
        line-height: 1;
    }

    .title-container {
        img {
            width: 800px;
        }

    }

    .timeline-container {
        @media screen and (max-width: 850px) {
            display: none !important;
        }

    }

    .text-corner {
        height: fit-content;
        height: -moz-fit-content;
        padding: 0 .5rem;
        width: fit-content;
        width: -moz-fit-content;
        margin: .5rem 0;
    }

    .info-container {
        height: 728px;
    }

    .timeline-mobile-container {
        @media screen and (max-width: 850px) {
            display: flex !important;
        }
    }

    .mt-custom {
        margin-top: 2rem;

        @media screen and (max-width: 1200px){
            margin-top: 2.8rem;
        }
        @media screen and (max-width: 991px){
            margin-top: 3.8rem;
        }
    }

    .timeline-mobile-container{
        .corners {
            --w: 15px;
            min-height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    

    //SLIDER
    .slider-div{
        width: 100%;
        height: 430px;
        overflow: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    // .slide-element{
    //     margin-right: 200px;
    // }

    

    .start-part{
        left: 40px;
        top: 257px;
    }
    
    .middle-part{
        left: 462px;
        top: 257px;
    }

    .end-part {
        left: 813px;
        top: 257px; 
    }

    @media screen and (max-width: 600px) {
        .font-size-32 {
            font-size: 16px;;
        }

        .slider-div {
            height: 340px;
        }

        .slide-element {
            width: 700px;
        }

        .start-part{
            top: 180px;
            left: 30px;
        }

        .middle-part{
            top: 180px;
            left: 270px;
        }

        .end-part {
            top: 180px; 
            left: 478px;
        }

        .corners {
            --w: 10px !important;
            min-height: 35px !important;
        }
    }
}