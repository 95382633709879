.rewards-page {
  //   .page-section {
  //     @media screen and (max-width: 991px) {
  //       padding-bottom: 0;
  //     }
  //   }
  background-color: #000;
  .rewards-p-container-lg {
    width: 100%;
    max-width: 700px;

    @media screen and (max-width: 991px) {
      max-width: 600px;
    }
  }

  h2 {
    line-height: 1 !important;
  }

  // coming soon blur overlay
  .rewards-blur-overlay > *:not(.coming-soon-span) {
    background: #000000;
    filter: blur(10px);

    @supports not (backdrop-filter: blur(10px)) {
      & > *:not(.coming-soon-span) {
        filter: blur(4px);
      }
    }
  }

  .coming-soon-span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: 10;
  }
}
