#framweorks {
  .custom-font-size-fw {
    font-size: 18px !important;

    @media screen and (max-width: 1400) {
      font-size: 17px !important;
    }

    @media screen and (max-width: 1200) {
      font-size: 16px !important;
    }

    @media screen and (max-width: 767) {
      font-size: 14px !important;
    }
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  background: black;
  overflow: hidden;
  line-height: 1.3;

  background-image: url("../../../assets/images/png/Mask\ Group\ 6.png");
  background-position: calc(50% - 800px) calc(100% - 250px);
  background-repeat: no-repeat;

  .description {
    max-width: 830px;
    margin-bottom: 6rem;
    line-height: 1.1;
    @media screen and (max-width: 991px) {
      margin-bottom: 1rem;
    }
  }

  .description-991-custom {
    @media screen and (max-width: 991px) {
      max-width: 280px;
    }
    @media screen and (max-width: 768px) {
      max-width: 450px;
    }
  }

  .row {
    display: flex !important;
    flex-direction: row-reverse;
    align-items: flex-start !important;
    align-content: flex-start !important;
    justify-content: flex-start !important;
    //border: 1px solid red;
  }

  .coloana {
    height: 239px;
    margin-bottom: 2rem;

    @media screen and (max-width: 575px) {
      margin-bottom: 0;
    }

    @media screen and (max-width: 400px) {
      height: 220px;
    }

    @media screen and (max-widh: 300px) {
      height: 239px;
    }
  }

  .buttonBlue {
    svg {
      margin-left: 10px !important;
    }
  }

  .content-photo {
    width: 98px;
    height: 98px;
    border-radius: 16px;
    background-color: #161616;
    //margin-bottom: 28px;
  }

  .developer-button {
    @media screen and (max-width: 1200px) {
      max-width: 200px !important;
      height: 55px;
    }
    @media screen and (max-width: 991px) {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      height: 50px;
      font-size: 18px;
    }
    @media screen and (max-width: 600px) {
      font-size: 16px;
      max-width: 160px;
      height: 45px;
    }
    @media screen and (max-width: 400px) {
      font-size: 15px;
    }
  }

  .wrapper-coloana {
    p {
      max-width: 342px;

      @media screen and (max-width: 768px) {
        max-width: 450px;
      }
    }
  }

  @media screen and (max-width: 1470px) {
    background-position: -500px calc(100% - 250px);
  }

  @media screen and (max-width: 1150px) {
    background-position: -550px calc(100% - 250px);
  }

  @media screen and (max-width: 991px) {
    background-image: none;
    .wrapper-coloana {
      padding: 0 !important;
    }

    .coloana,
    .rand-buton {
      display: flex !important;
      align-items: center !important;
      align-content: center !important;
      justify-content: flex-start !important;
    }

    .coloana-btn {
      button {
        border: none;
        margin: auto;
      }
      .wrapper-coloana {
        margin-top: 0 !important;
      }
    }
  }

  // @media screen and (max-width: 768px) {
  //   .font-size-18 {
  //     font-size: 16px;
  //     line-height: 1.1;
  //   }
  // }

  @media screen and (max-width: 480px) {
    .content-photo {
      width: 66px;
      height: 66px;
      border-radius: 12px;
      background-color: #161616;
      //margin-bottom: 28px;

      .framework-svg {
        width: 66%;
      }
      .solidity-img {
        width: 46%;
      }

      .evm-img,
      .remix-img {
        width: 86%;
      }
    }
  }
}
