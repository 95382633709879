.diagonal-corners {
    .box {
        --b: 2px;
        --c: #707070;
        --w: 25px;
        border: var(--b) solid transparent;
        --g: #0000 90deg, var(--c) 0;
        background: conic-gradient(from 180deg at top var(--b) right var(--b), var(--g)) 100% 0,
            conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--g)) 0 100%;
        background-size: var(--w) var(--w);
        background-origin: border-box;
        background-repeat: no-repeat;
        width: 340px;
        height: 150px;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .rotate {
        position: absolute;
        transform-origin: top left;
        left: -9px;
        top: 50%;
        transform: rotate(-90deg) translateX(-50%);
    }

    .font-size-120 {
        font-size: 120px;
        line-height: 120px;
        position: relative;
    }

    .text {
        position: absolute;
        right: 0;
        bottom: -65px;
    }

    .size-18 {
        font-size: 18px;
    }

    .title{
        min-width: 130px;
        text-align: end;
    }

    @media screen and (max-width: 1470px) {
        .font-size-120 {
            font-size: 100px;
            line-height: 100px;
        }
        .box {
            width: 290px;
        }
        .text {
            bottom: -55px;
        }
    }

    @media screen and (max-width: 1250px) {
        .font-size-120 {
            font-size: 80px;
            line-height: 80px;
        }
        .box {
            width: 250px;
        }
        .text {
            bottom: -45px;
        }
        .size-18 {
            font-size: 16px;
        }
        .rotate{
            left: -8px;
        }
    }
    @media screen and (max-width: 1050px) {
        .font-size-120 {
            font-size: 70px;
            line-height: 70px;
        }
        .box {
            width: 220px;
        }
        .text {
            bottom: -40px;
        }
    }

    @media screen and (max-width: 991px) {
        .box {
            width: 340px;
        }
        .text {
            bottom: -55px;
        }
    }
    @media screen and (max-width: 768px) {
        .font-size-120 {
            font-size: 55px;
            line-height: 55px;
        }
        .text {
            font-size: 14px;
            bottom: -45px;
        }
        .box {
            width: 280px;
        }
    }
    @media screen and (max-width: 400px) {
        width: 100%;

        .box {
            width: 100%;
            height: 120px;
        }
    }
}
