.mh-432 {
    max-height: 432px;
}

.get-reward-title {
    @media screen and (max-width: 767px) {
        text-align: center;
    }
}

.reward-steps-section {
    .text-start {
        margin: unset !important;

        @media screen and (max-width: 767px) {
            margin: auto !important;
        }
    }
}