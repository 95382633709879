#be-part {
  background: black;
  line-height: 1.3;
  color: white;

  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }

  // .text-shadow{
  //      text-shadow: 0px 0px 2px #DE7488;
  // }

  .ethernytree-card {
    .xideen-upper-title {
      .line-upper-title {
        background-color: #20deb0;
      }
    }
  }

  .wrapper-coloana {
    background-color: #161616;
    border-radius: 24px;
    padding: 3rem;
    margin: 12px 0 12px 0;
    @media screen and (max-width: 1200px) {
      padding: 1.5rem;
    }
  }
  .read-more-btn {
    // width: 160px;
    width: fit-content;
    width: -moz-fit-content;
    color: #22aaff;
    background: transparent;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.2s;

    svg {
      margin-left: 10px;
    }
    //#174164
  }

  // .title{
  //     margin-bottom: 85px;
  // }

  .coloana-r1 {
    width: 100%;
    height: 600px;
    justify-content: space-between;

    .content-img {
      // margin-bottom: 170px;
      margin-left: auto;
      margin-right: auto;
    }

    h4 {
      max-width: 512px;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      button:hover {
        color: white;
      }
    }
  }

  .coloana-r2 {
    background-color: #174164;
    height: 800px;
    justify-content: space-between !important;
    .content-card {
      img {
        margin: 15px 0 15px 0;
        max-width: 188px;
        width: 100%;
        @media screen and (max-width: 767px) {
          max-width: 150px;
        }
        @media screen and (max-width: 320px) {
          max-width: 120px;
        }
      }
    }
    button:hover {
      color: white;
    }
  }

  .coloana-r2-c2-c1 {
    color: #af4aff;
    height: 388px;
    display: flex;
    justify-content: space-between !important;

    // button {
    //     color: #af4aff;
    // }
    button:hover {
      color: white;
    }
  }

  .coloana-r2-c2-c2 {
    height: 388px;
    display: flex;
    justify-content: space-between !important;

    button:hover {
      color: white;
    }
  }

  .coloana-r2-r2 {
    height: 388px;

    .content {
      height: 100%;
    }

    img {
      margin-bottom: 50px;
      margin-left: 50px;

      @media screen and (max-width: 1550px) {
        margin-left: 20px;
      }
    }
    button:hover {
      color: white;
    }
  }

  .coloana-r3 {
    height: 388px;
    justify-content: space-between !important;

    .content {
      margin-left: 67px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    button:hover {
      color: white;
    }
  }

  .coloana-r3-c2 {
    background-color: white;
    height: 388px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-image: url("../../../assets/images//png/low-energy-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;

    span {
      color: #20deb0;
    }

    button {
      color: #20deb0;
    }
    button:hover {
      color: white;
    }

    .low-energy {
      background: -webkit-linear-gradient(rgba(255, 255, 255, 0), #de7488);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  @media screen and (max-width: 1470px) {
    img {
      max-width: 100%;
      height: auto;
    }

    .coloana-r1 {
      width: unset;
    }
  }

  @media screen and (max-width: 1281px) {
    .titles {
      line-height: 1;
    }
  }

  @media screen and (max-width: 991px) {
    .coloana-r1 {
      height: 500px;
      .content-img {
        margin-bottom: 0;
        // text-align: center;
      }
      img {
        //max-width: 80%;
      }
    }
    .coloana-r2 {
      height: 600px;
    }

    .coloana-r2-c2-c1,
    .coloana-r2-c2-c2 {
      height: 300px;
    }
  }

  @media screen and (max-width: 767px) {
    .wrapper-coloana {
      padding: 24px;
      // margin: 8px 0 8px 0;
      margin: 0 0 1.5rem 0;
    }

    .coloana-r1 {
      height: auto;
      min-height: auto;
      .content-img {
        margin-bottom: 0px;
      }
    }
    .coloana-r2 {
      height: unset;
    }

    .coloana-r1 {
      height: auto;
      padding-bottom: 24px;
      flex-direction: column-reverse !important;
      align-items: flex-start !important;
      .content-img {
        width: 100%;
        // text-align: center;
        img {
          max-width: 50%;
        }
      }
    }

    .coloana-r2-r2 {
      height: unset;
      flex-direction: column-reverse !important;
      justify-content: space-between !important;
      align-items: flex-start !important;
      .content-img {
        display: flex;
        width: 100%;
        align-items: center;
        // justify-content: center;
        text-align: center;
        img {
          max-width: 60%;
          // margin: auto;
        }
      }
      img {
        margin: 0;
      }
    }

    .coloana-r3 {
      height: unset;
      flex-direction: column !important;
      align-items: flex-start !important;
      .content-img {
        margin-left: auto;
        margin-right: auto;
        // text-align: center;
        width: 100%;
        img {
          max-width: 40%;
          // margin: auto;
        }
      }

      .content {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 550px) {
    .coloana-r1 {
      .content-img {
        img {
          max-width: 70%;
        }
      }
    }
    .coloana-r2-r2 {
      .content-img {
        img {
          max-width: 80%;
        }
      }
    }
    .coloana-r3 {
      .content-img {
        img {
          max-width: 55%;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .coloana-r2-c2-c1,
    .coloana-r2-c2-c2 {
      height: 260px;
    }
    .coloana-r1,
    .coloana-r2-r2 {
      .content-img {
        img {
          max-width: 90%;
        }
      }
    }
    .coloana-r3 {
      .content-img {
        img {
          max-width: 70%;
        }
      }
    }
  }

  .card-heading {
    width: 537px;

    @media screen and (max-width: 1400px) {
      width: 530px;
    }
    @media screen and (max-width: 1200px) {
      width: 500px;
    }
    @media screen and (max-width: 1050px) {
      width: 430px;
    }
    @media screen and (max-width: 991px) {
      width: 350px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (max-width: 380px) {
      display: none;
    }
  }

  .sm-heading {
    @media screen and (min-width: 381px) {
      display: none;
    }
  }

  //card image animation

  .top-image {
    position: absolute;
    top: -60%;
    left: 50%;
    transform: translate(-50%, 0);
    animation: wifi-anim ease-in-out 3s infinite;

    @media screen and (max-width: 768px) {
      left: 0;
      transform: none;
      animation: wifi-anim-mobile ease-in-out 3s infinite;
    }

    @media screen and (max-width: 300px) {
      animation: wifi-anim-mobile-sm ease-in-out 3s infinite;
    }
  }

  .top-image,
  .bottom-image {
    max-width: 80%;
    @media screen and (max-width: 991px) {
      max-width: 350px;
    }
  }

  .bottom-image {
    margin: auto;
    @media screen and (max-width: 768px) {
      margin: unset;
      margin-bottom: 2rem;
    }
  }

  @keyframes wifi-anim {
    0% {
      top: -60%;
    }

    50% {
      top: -75%;
    }

    100% {
      top: -60%;
    }
  }
  @keyframes wifi-anim-mobile {
    0% {
      top: -40%;
    }

    50% {
      top: -55%;
    }

    100% {
      top: -40%;
    }
  }
  @keyframes wifi-anim-mobile-sm {
    0% {
      top: -30%;
    }

    50% {
      top: -45%;
    }

    100% {
      top: -30%;
    }
  }

  .title {
    @media screen and (max-width: 768px) {
      margin-bottom: 7rem;
    }

    @media screen and (max-width: 350px) {
      margin-bottom: 5rem;
    }
  }
}
