#blockchain-section {
  overflow: hidden;
  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }

  .heading-container {
    h2 {
      line-height: 1;
      margin-bottom: 3rem;
    }

    @media screen and (max-width: 991px) {
      h2 {
        margin-bottom: 1.5rem;
      }
    }
  }

  .pointer:hover {
    span,
    svg {
      color: white;
    }
  }

  @media screen and (max-width: 991.5px) {
    .feature-card {
      max-width: unset !important;
    }
  }

  // slider  ________________________________________________________________________________________________

  .cardSlider {
    width: 100%;
    min-width: 340px;
    height: 400px;
    margin-right: 1.5rem;
    cursor: grab;

    @media screen and (max-width: 991px) {
      margin-right: 0;
    }

    @media screen and (max-width: 767px) {
      min-width: 300px;
    }

    @media screen and (max-width: 655px) {
      min-width: 270px;
    }

    @media screen and (max-width: 560px) {
      height: 320px;
    }
    @media screen and (max-width: 330px) {
      min-width: 255px;
    }
    @media screen and (max-width: 310px) {
      min-width: 238px;
    }
    @media screen and (max-width: 290px) {
      min-width: 224px;
    }
  }

  .blockchain-slider {
    width: 100%;
    padding-right: 3.5rem;
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    @media screen and (min-width: 1800px) {
      width: 120%;
    }
  }

  .inner-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #000000;
    padding: 2rem;
    border-radius: 24px;
    transition: all 0.3s ease-in-out;
  }

  // card  ______________________________________________________________________________________________________
  .blockchain-card {
    width: 100%;
    max-width: 340px;
    height: 400px;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    border-radius: 16px;
    background-color: #161616;
    // margin-bottom: 1.5rem;
    overflow: hidden;

    @media screen and (max-width: 991px) {
      max-width: 320px;
      height: 360px;
    }

    @media screen and (max-width: 767px) {
      padding: 1.5rem;
      max-width: 280px;
      height: 320px;
    }
    @media screen and (max-width: 655px) {
      max-width: 250px;
      height: 300px;
    }

    @media screen and (max-width: 367px) {
      max-width: 260px;
    }
    @media screen and (max-width: 330px) {
      max-width: 240px;
    }
    @media screen and (max-width: 310px) {
      max-width: 220px;
    }
    @media screen and (max-width: 290px) {
      max-width: 210px;
    }

    .card-content {
      margin-top: 4rem;
    }

    .blockchain-card-title {
      font-size: 35px;

      @media screen and (max-width: 1380px) {
        font-size: 32px;
      }
      @media screen and (max-width: 991px) {
        font-size: 30px;
      }
      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
      @media screen and (max-width: 310px) {
        font-size: 24px;
      }
      // @media screen and (max-width: 500px) {
      //   font-size: 26px;
      // }
    }
  }
}

.internet-card-bg {
  background-image: url("../../../assets/images/png/small-cards/internet-card-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.connection-card-bg {
  background-image: url("../../../assets/images/png/small-cards/connection-card-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.security-card-bg {
  background-image: url("../../../assets/images/png/small-cards/security-card-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.price-card-bg {
  background-image: url("../../../assets/images/png/small-cards/price-card-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
