#properties-section {
  @media screen and (max-width: 1450px) {
    display: none;
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }

  .heading-container {
    h2 {
      line-height: 1;
      margin-bottom: 6rem;
    }
  }

  .pointer {
    span,
    svg {
      transition: 0.2s;
    }
  }

  .pointer:hover {
    span,
    svg {
      color: white !important;
    }
  }

  .cards-row .col:last-child .feature-card-title {
    max-width: 195px;
  }

  .bg5,
  .bg6,
  .bg7,
  .bg8 {
    margin-bottom: 0;
  }
}

#properties-section-mobile {
  @media screen and (min-width: 1450px) {
    display: none;
  }

  .slider-div {
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
    margin-left: 0 !important;
    margin-right: 0 !important;
    cursor: grab;
    position: absolute;
    top: 0;
    left: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slider-height {
    height: 290px;

    @media screen and (max-width: 767px) {
      height: 270px;
    }
  }
  .descriptions-container {
    width: 100%;
    display: flex;
    margin-top: 4rem;
    flex-direction: row !important;

    @media screen and (max-width: 1450px) {
      margin-top: 1rem;
    }
  }
}
