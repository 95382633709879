.rewards-apps-section {
  .rewards-apps-corners {
    height: unset;
    margin-bottom: 0;
    margin-top: 0;
  }

  .apps-image {
    width: 100%;
    max-width: 700px;
    height: auto;

    @media screen and (max-width: 767px) {
      margin-bottom: 2rem;
      max-width: 500px;
    }
  }

  .rewards-dapps-button {
    max-width: 260px !important;
    margin-left: 0 !important;

    @media screen and (max-width: 1200px) {
      max-width: 240px !important;
    }
    @media screen and (max-width: 991px) {
      max-width: 200px !important;
    }
  }

  .paragraph-container {
    max-width: 520px;
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }
}
