.mining-competition{
    .pointer {
        cursor: pointer;
    }

    .title-container {
        img {
            width: 800px;
        }
    }

    @media screen and (max-width: 480px){
        .page-section {
            padding: 2.5rem 0;
        }
    }
}