.daps-hero {
  .container-main {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
  }

  .page-section {
    padding: 9rem 0 6rem 0;

    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }
  }

  .paragraph-container {
    p {
      max-width: 540px;
    }
  }

  h2 {
    line-height: 1;
  }

  @media screen and (max-width: 991px) {
    .container-main {
      flex-direction: column-reverse;
    }
    .paragraph-container {
      p {
        max-width: unset;
      }
    }
  }

  .main-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 2rem;

    @media screen and (max-width: 991px) {
      align-items: center;
      margin-right: 0;
      justify-content: start;
      margin-top: 2rem;

      h2 {
        text-align: center;
      }

      .paragraph-container {
        p {
          text-align: center;
        }
        max-width: 600px;
      }
    }

    .container-butoane {
      @media screen and (max-width: 400px) {
        flex-direction: column !important;
      }
    }

    .hero-btn-container {
      //width: 100%;
      // display: flex;
      // align-items: center;
      button {
        width: 220px;
        svg {
          margin-left: 10px;
        }
      }

      @media screen and (max-width: 991) {
        width: 100%;
        justify-content: flex-start;
        // button {
        //     width: unset;
        // }
      }

      @media screen and (max-width: 400px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  .buttons-flex-direction {
    @media screen and (max-width: 991px) {
      justify-content: center !important;
    }

    @media screen and (max-width: 350px) {
      flex-direction: column;
      align-items: center !important;
      padding-top: 0 !important;
    }

    .buttonOutlineBlue {
      @media screen and (max-width: 400px) {
        margin-top: 0px !important;
      }
      @media screen and (max-width: 350px) {
        margin-top: 1rem !important;
        margin-left: 0px !important;
      }
    }

    .buttonBlue {
      @media screen and (max-width: 350px) {
        margin-right: 0px;
      }
    }
  }

  .section-image-col {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .section-image {
      width: 90%;
      height: auto;
    }

    @media screen and (max-width: 991px) {
      .section-image {
        max-width: 400px;
      }
      img {
        max-width: unset;
        width: 30% !important;
      }
    }
    @media screen and (max-width: 768px) {
      img {
        max-width: unset;
        width: 40% !important;
      }
    }
    @media screen and (max-width: 480px) {
      img {
        max-width: unset;
        width: 60% !important;
      }
    }
  }
}
