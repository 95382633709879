#cards-section2 {
  h4 {
    margin-bottom: 0px;
    line-height: 1;
  }

  .font-size-56 {
    font-size: 56px !important;

    @media screen and (max-width: 1400px) {
      font-size: 50px !important;
    }

    @media screen and (max-width: 1200px) {
      font-size: 40px !important;
    }

    @media screen and (max-width: 991px) {
      font-size: 30px !important;
    }
    @media screen and (max-width: 850px) {
      font-size: 28px !important;
    }
    @media screen and (max-width: 575px) {
      font-size: 24px !important;
    }
    @media screen and (max-width: 480px) {
      font-size: 20px !important;
    }
    @media screen and (max-width: 330px) {
      font-size: 18px !important;
    }
  }

  .column-wrapper {
    background-color: #161616;
    border-radius: 24px;
    height: 624px;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1200px) {
      height: 500px;
    }
    @media screen and (max-width: 991px) {
      height: 350px;
    }
    @media screen and (max-width: 767px) {
      height: 300px;
      margin-right: 0 !important;
    }
    @media screen and (max-width: 577px) {
      height: 250px;
    }
    @media screen and (max-width: 480px) {
      height: 250px;
    }
  }

  .validator-text {
    br {
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  .section-end-col {
    margin-bottom: 0px;
    height: 624px;
    @media screen and (max-width: 1200px) {
      height: 388px;
    }

    @media screen and (max-width: 991px) {
      height: 350px;
    }
    @media screen and (max-width: 577px) {
      height: 250px;
    }
    @media screen and (max-width: 480px) {
      height: 210px;
    }
    @media screen and (max-width: 410px) {
      flex-direction: column-reverse !important;
      height: 310px;
      align-items: center !important;
      justify-content: center !important;
    }
    @media screen and (max-width: 350px) {
      flex-direction: column-reverse !important;
      height: 330px;
      align-items: center !important;
      justify-content: center !important;
    }
  }

  .network-reward-container {
    @media screen and (max-width: 767px) {
      width: 100%;
      flex-direction: column;
    }
  }

  .column-wrapper-img {
    @media screen and (max-width: 991px) {
      max-width: 120px;
    }
    @media screen and (max-width: 767px) {
      max-width: 100px;
    }

    @media screen and (max-width: 480px) {
      margin: 0.75rem 0 0 0 !important;
      max-width: 70px;

      &:last-of-type {
        max-width: 80px;
      }
    }
  }

  .validators-cooldown-img {
    transform: translate(-21px, -113px);
    max-width: 462px;

    @media screen and (max-width: 1200px) {
      transform: unset;
      max-width: 320px;
    }

    @media screen and (max-width: 767px) {
      max-width: 220px;
    }
    @media screen and (max-width: 410px) {
      max-width: 130px;
      margin-bottom: 1rem;
    }
  }

  .withdraw-text {
    margin-top: auto;

    @media screen and (max-width: 410px) {
      padding-top: 1rem !important;
    }

    br {
      @media screen and (max-width: 410px) {
        display: none;
      }
    }
  }

  .bg1 {
    background-image: url("../../../assets/images/png/staking-small-sphere.png");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg2 {
    background-image: url("../../../assets/images/png/staking-long-blurp.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .bg3 {
    background-image: url("../../../assets/images/png/staking-cards-section2-bg3.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
