.rewards-power-section {
  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }
  .power-spects-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 4rem;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      .power-image {
        width: 80%;
      }
      .img-row {
        min-height: unset;
        margin-bottom: 1.5rem;
      }
      p {
        margin-bottom: 0;
      }
      .first-p {
        margin-bottom: 3rem;
      }
    }
  }

  .power-col {
    width: 100%;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:not(:last-child) {
      margin-right: 2rem;

      @media screen and (max-width: 450px) {
        margin-right: 0;
      }
    }
  }

  .power-image {
    width: 100%;
    max-width: 268px;
    height: auto;
    margin-bottom: auto;
    margin-top: auto;
  }
  .computing-image {
    max-width: 336px;
  }

  .img-row {
    min-height: 300px;
  }

  .rewards-p-container-lg {
    max-width: 920px;
    @media screen and (max-width: 991px) {
      max-width: 600px;
    }
  }
}
