$blue_color: #22aaff;
$breakpoint-tablet: 991px;

#full-power {
  background-color: black;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }

  .diff-buy {
    color: white !important;
    border: 1px solid white;
    transition: 0.5s;
  }
  .diff-buy:hover {
    color: black !important;
    transition: 0.5s;
    border: 1px solid white;
    background-color: rgb(194, 194, 194) !important;
  }

  .xideen-upper-title {
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
  }

  .full-power-img {
    width: 100%;
    max-width: 650px;
    height: auto;

    @media screen and (max-width: 991px) {
      max-width: 450px;
    }

    // @media screen and (max-width: 991.5px) {
    //   width: 55%;
    // }
    // @media screen and (max-width: 771px) {
    //   width: 60%;
    // }
    // @media screen and (max-width: 522px) {
    //   width: 75%;
    // }
    // @media screen and (max-width: 390px) {
    //   width: 95%;
    // }
  }

  // .power-title {
  //   max-width: 0px;
  // }

  button {
    border: none;

    img {
      margin-left: 5.4px;
    }
  }

  @media screen and (max-width: 1600px) {
    .section-img {
      img {
        transform: none !important;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .xideen-upper-title h1,
    .section-title,
    .section-content,
    .buttonBlue {
      text-align: center;
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .section-img {
      display: flex;
      img {
        margin: auto;
      }
    }

    .buttonBlue {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media screen and (max-width: 769px) {
    // .power-title {
    //    margin-bottom: 20px;
    // }

    .section-content {
      font-size: 18px !important;
    }
  }
  // @media screen and (max-width: 481px) {
  //    .power-title {
  //       margin-bottom: 10px;
  //    }
  // }

  // @media screen and (max-width: 320px) {
  //    .power-title {
  //       margin-bottom: 10px;
  //    }
  // }

  .first-paragraph {
    max-width: 580px;
  }

  @media screen and (min-width: 992px) {
    .padding-start-custom {
      padding-left: 2rem;
    }
    .padding-end-custom {
      padding-right: 2rem;
    }

    .image-desktop1 {
      margin-right: auto;
    }

    .image-desktop-2 {
      margin-left: auto;
    }
  }

  .column-wrapper {
    height: 580px;
    border-radius: 24px;
    min-height: 350px;

    @media screen and (max-width: 991px) {
      height: auto;
    }
  }

  .big-bang-section-title,
  .locked-xden-title-container {
    @media screen and (max-width: 991px) {
      br {
        display: none;
      }
    }
  }

  .bg-1 {
    background-image: url("../../../assets/images/png/homePage/big-bang-bg.png"),
      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;

    h4 {
      line-height: 1 !important;
    }

    @media screen and (max-width: 400px) {
      h4 {
        font-size: 24px !important;
      }
    }
    @media screen and (max-width: 350px) {
      h4 {
        font-size: 20px !important;
      }
    }
  }

  .bg-2 {
    background-image: url("../../../assets/images/png/homePage/big-bang-bg2.png"),
      url("../../../assets/images/png/homePage/big-bang-bg3.png"), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: soft-light, overlay;

    h4 {
      line-height: 1 !important;
    }

    @media screen and (max-width: 600px) {
      svg {
        width: 50px;
        height: 50px;
      }
    }

    @media screen and (max-width: 400px) {
      h4 {
        font-size: 24px !important;
      }
    }
    @media screen and (max-width: 350px) {
      h4 {
        font-size: 20px !important;
      }
    }
  }
}
