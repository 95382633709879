.container-main {
   margin-left: auto;
   margin-right: auto;
   width: 100%;
   max-width: 1470px;
   padding: 0 1.5rem;
   height: 100%;

   // @media screen and (max-width: 767px) {
   //    padding: 0 1rem;
   // }
}

.large-container {
   width: 100%;
   max-width: 1920px;
   margin-left: auto;
   margin-right: auto;
}

.paragraph-container {
   width: 100%;
   max-width: 600px;
   // margin: auto;
   // text-align: center;
}
