#sector-section {
  background-color: black;

  .sector-background {
    background-color: #161616;
  }

  .slider-div {
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
    margin-left: 0 !important;
    margin-right: 0 !important;
    cursor: grab;
    position: absolute;
    top: 0;
    left: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slider-height {
    height: 290px;

    @media screen and (max-width: 767px) {
      height: 250px;
    }
  }
  .descriptions-container {
    width: 100%;
    display: flex;
    margin-top: 4rem;
    flex-direction: row !important;

    @media screen and (max-width: 991px) {
      margin-top: 3rem;
    }
  }
  .sector-img {
    top: -20px;
    left: 60%;
    position: absolute;

    @media screen and (max-width: 1600px) {
      left: 55%;
    }
    @media screen and (max-width: 1510px) {
      left: 60%;
      max-width: 260px;
    }
    @media screen and (max-width: 1300px) {
      // left: 55%;
      top: -10px;
      max-width: 200px;
    }
    @media screen and (max-width: 1068px) {
      max-width: 180px;
    }
    @media screen and (max-width: 991px) {
      max-width: 130px;
      top: 5px;
    }
    @media screen and (max-width: 375px) {
      left: 45%;
      max-width: 120px;
    }
  }

  .small-section-wrapper {
    .sector-btn {
      display: block;
      position: relative;
    }

    .sector-btn::after {
      content: "";
      width: calc(100% - 52px);
      position: absolute;
      height: 2px;
      background-color: #86868b;
      top: -70px;
      left: 52px;
    }
  }

  .where-container {
    width: 57%;

    @media screen and (max-width: 991px) {
      width: 70%;
    }
    h3 {
      margin-bottom: 18px;
    }
  }

  .letter-wrapper {
    width: 40px;
    height: 40px;
    background-color: #333333;
    border-radius: 4px;
    margin-right: 12px;
    font-size: 28px;
  }

  .sector-btn {
    margin-top: 49px;
    background: transparent;
    border: none;
  }

  .column-wrapper {
    margin-bottom: 48px;
    height: 240px;
    border-radius: 24px;
    padding: 3rem;
  }
  .content-margin {
    margin-top: 77px;

    @media screen and (max-width: 991px) {
      margin-top: 0;
    }
  }

  .wrapper-left {
    margin-right: 12px;
  }
  .wrapper-right {
    margin-left: 12px;
  }

  .min-width-sector {
    min-width: 340px;
    margin-right: 1.5rem;
    height: 200px;
    border-radius: 24px;
    padding: 3rem;

    @media screen and (max-width: 520px) {
      min-width: 280px;
      padding: 1.5rem;
    }
    @media screen and (max-width: 375px) {
      min-width: 250px;
      padding: 1rem;
    }
    @media screen and (max-width: 340px) {
      min-width: 210px;
    }
    @media screen and (max-width: 300px) {
      min-width: 200px;
    }
  }

  svg {
    margin-left: 9.5px;
    width: 17px;
    height: 17px;
  }

  .where-container h3 {
    @media screen and (max-width: 1284px) {
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1243px) {
    .wrapper-left {
      margin-right: 0px;
    }
    .wrapper-right {
      margin-left: 0px;
    }

    .column-wrapper {
      margin-bottom: 24px !important;
    }
  }
}
