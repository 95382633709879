#prizes-register-section {
    .buttonBlue {
        max-width: unset;
    }

    .pool-form {
        background-image: url("../assets/pool-register.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 16px;
    }

    .performance-form {
        background-image: url("../assets/performance-register.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 16px;
    }

    .font-size-12 {
        font-size: 12px;
    }

    .error-msg {
        color: #ff0000;
    }

    input[type="text"] {
        width: 100%;
        -webkit-opacity: 1;
        opacity: 1;
        color: #ffffff;
        -webkit-text-fill-color: #ffffff;
        background-color: #000537;
        height: 56px;
        border: none;
        outline: none;
        border-radius: 8px;
        position: relative;

        &::placeholder {

            color: #848484 !important;

        }


    }

    [placeholder]::-webkit-input-placeholder {
        opacity: .5;
    }

    [placeholder]:focus::-webkit-input-placeholder {
        transition: text-indent 0.3s 0.3s ease;
        text-indent: -100%;
        opacity: .5;
    }

    .btn-disabled {
        background-color: #235c80;
        cursor: not-allowed !important;
    }

    .unselectable {
        /* Safari */
        -webkit-user-select: none;
        /* Firefox */
        -moz-user-select: none;
        /* IE10+/Edge */
        -ms-user-select: none;
        /* Standard */
        user-select: none;
    }
}