.statistics-section {
   @media screen and (max-width: 290px) {
      .font-size-40 {
         font-size: 23px;
      }
   }

   .circulating-supply {
      width: 100% !important;
      display: flex;
      div {
         width: 100%;
         margin-top: auto;
         margin-bottom: auto;
      }
   }

   .box-graph {
      display: flex;
   }
   #supply-chart {
      .line-upper-title {
         background-color: #20deb0;
      }
   }

   #private-difficulty-card {
      .line-upper-title {
         background-color: #ee694b;
      }
   }
   #public-difficulty-card {
      .line-upper-title {
         background-color: #1d4aff;
      }
   }
   #blocks-card {
      .line-upper-title {
         background-color: #8a2fe2;
      }
   }
   #validation-card {
      .line-upper-title {
         background-color: #fa3bbf;
      }
   }
   .age-card {
      .line-upper-title {
         background-color: #f96295;
      }
   }

   #epoch-card {
      .xideen-upper-title {
         h6 {
            flex-direction: row-reverse;

            .line-upper-title {
               margin-right: 0 !important;
               margin-left: 1rem !important;
               background-color: #83f7fe;
            }
         }
      }
   }

   .graphs-container {
      display: flex;

      @media screen and (max-width: 991px) {
         flex-direction: column;
      }
   }

   .buttons-flex-direction {
      @media screen and (max-width: 400px) {
         align-items: center !important;
      }
   }

   // .graphs-second-row {
   //    @media screen and (max-width: 767px) {
   //       &-col1,
   //       &-col3 {
   //          padding-right: 0.5rem;
   //       }
   //       &-col2,
   //       &-col4 {
   //          padding-left: 0.5rem;
   //       }
   //    }
   //    @media screen and (max-width: 575px) {
   //       &-col1,
   //       &-col3 {
   //          padding-right: 1rem;
   //       }
   //       &-col2,
   //       &-col4 {
   //          padding-left: 1rem;
   //       }
   //    }
   // }

   .box-graph {
      position: relative;
      height: 400px;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 16px;
      background-color: #161616;
      overflow: auto;

      @media screen and (max-width: 991px) {
         flex: unset;
         width: 100%;
      }
      @media screen and (max-width: 500px) {
         height: 350px;
      }

      @media screen and (max-width: 400px) {
         .graphs-last-row-flexd {
            flex-direction: column;
            justify-content: center !important;
            align-items: center !important;
         }
         .graphs-last-row-flexd-reverse {
            flex-direction: column-reverse;
            justify-content: center !important;
            align-items: center !important;
         }
         .graphs-last-row-img {
            padding-bottom: 1.5rem;
            max-width: 70%;
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
         }
      }
      @media screen and (max-width: 320px) {
         .graphs-last-row-img {
            max-width: 90%;
         }
      }
   }

   .price-graph {
      margin-bottom: 1.5rem;
      width: 100%;
   }

   .chart-box {
      height: 252px !important;
   }

   .total-supply-card {
      width: 33%;
      margin-right: 1.5rem;
      @media screen and (max-width: 991px) {
         width: 100%;
         margin-right: 0;
         margin-bottom: 1.5rem;
         height: 250px;
      }
      @media screen and (max-width: 767px) {
         height: 200px;
      }
   }
   .supply-graph {
      width: 67%;
      @media screen and (max-width: 991px) {
         width: 100%;
      }
   }

   .price-graph,
   .supply-graph {
      @media screen and (max-width: 500px) {
         overflow: hidden;
      }
   }

   .inner-container {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3rem;
      background-color: transparent;
      z-index: 200;

      @media screen and (max-width: 1200px) {
         padding: 1.5rem;
      }
   }

   .svg-control {
      svg {
         min-width: 29px;
         margin-left: 0.75rem;
      }
   }

   .shadow {
      background-image: linear-gradient(transparent 60%, #161616);
   }

   // .numbers-span {
   //    // font-size: 56px;

   //    @media screen and (max-width: 1200px) {
   //       font-size: 40px;
   //    }
   // }

   .percent {
      font-size: 18px;
      color: #00fc4a;
   }

   .control-container {
      @media screen and (max-width: 450px) {
         flex-direction: column;
      }
   }

   .toggle-container {
      position: relative;
      width: 210px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: transparent;
      border: 2px solid #22aaff;
      border-radius: 48px;
   }

   .activeButton {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: #22aaff;
      color: #000000;
      font-size: 15px;
      border-radius: 48px;
      transition: all 0.2s ease-in-out;
   }

   .dayActive {
      left: 0;
      transform: unset;
   }

   .weekActive {
      left: 50%;
      transform: translateX(-50%);
   }

   .monthActive {
      right: 0;
      transform: translateX(35px);
   }

   .statistic-box {
      width: 100%;
      max-width: 340px;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3rem;
      background-color: #161616;
      border-radius: 16px;
      align-items: flex-start;

      @media screen and (max-width: 1200px) {
         padding: 1.5rem;
         height: 320px;
      }
      @media screen and (max-width: 991px) {
         // margin: auto;
         max-width: 100%;
         height: 350px;
         margin-bottom: 1.5rem;
      }
      @media screen and (max-width: 767px) {
         height: 320px;
      }
      @media screen and (max-width: 575px) {
         height: 300px;
      }
   }

   .third-row {
      @media screen and (max-width: 991px) {
         margin-top: 0 !important;
      }
   }

   .buttons-flex-direction {
      button {
         margin-left: 0.5rem;
         margin-right: 0.5rem;
      }
   }

   // charts
   .highcharts-axis-labels,
   .highcharts-legend,
   .highcharts-grid,
   .highcharts-axis {
      display: none;
   }

   .epoch-bg {
      background-image: linear-gradient(transparent, #3e3e3e), url("../../../assets/images/png/homepage-stats-bg2.png");
      background-position: top left;
      background-repeat: no-repeat;
      background-blend-mode: lighten;
   }
   .age-bg {
      background-image: linear-gradient(transparent, #3e3e3e), url("../../../assets/images/png/homepage-stats-bg.png");
      background-position: top right;
      background-repeat: no-repeat;
      background-blend-mode: lighten;
   }

   #second-upperTitle {
      .line-upper-title {
         height: 2px;
         width: 18px;
         background-color: #ee694b;
         border-radius: 48px;
         margin-left: 15px;
      }

      .cards-smaller-text {
         @media screen and (min-width: 768px) and (max-width: 1250px) {
            font-size: 14px !important;
         }

         @media screen and (max-width: 400px) {
            font-size: 12px !important;
         }
      }
   }

   .price-span {
      line-height: 1;
   }

   .statistic-card-icon {
      width: 90px;

      @media screen and (max-width: 1400px) {
         width: 80px;
      }
      @media screen and (max-width: 1200px) {
         width: 70px;
      }
   }

   .legend-container {
      @media screen and (max-width: 380px) {
         flex-direction: column;
         align-items: flex-start !important;

         #supply-chart {
            margin-bottom: 1rem;
         }

         #second-upperTitle {
            .upperTitle-span {
               order: 2;
            }

            .uppertitle-line {
               order: 1;
               margin-left: 0;
            }
         }
      }
   }
}
