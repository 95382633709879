.device-card {
   width: 100%;
   max-width: 268px;
   height: 120px;
   padding: 1.5rem;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-radius: 16px;
   background-color: #fda544;
   color: #000000;
}

.computing-card {
   @media screen and (max-width: 575px) {
      max-width: 100%;
   }
}

.marginX {
   margin: 0 1.5rem;

   @media screen and (max-width: 500px) {
      margin: 0 1rem;
   }
   @media screen and (max-width: 400px) {
      margin: 0 0.5rem;
   }
}

.upperTitleSize {
   font-size: 16px;
}

.titleSize {
   font-size: 32px;
}

.devicesUpperTitleSize {
   @media screen and (max-width: 767px) {
      font-size: 12px;
   }
   @media screen and (max-width: 450px) {
      font-size: 10px;
   }
}

.devicesTitleSize {
   @media screen and (max-width: 1200px) {
      font-size: 20px;
   }
   // @media screen and (max-width: 767px) {
   //    font-size: 20px;
   // }
   @media screen and (max-width: 450px) {
      font-size: 14px;
   }
   @media screen and (max-width: 360px) {
      font-size: 12px;
   }
}

// .icon-container {
//    svg {
//       @media screen and (max-width: 1200px) {
//          width: 30px;
//          height: auto;
//       }
//    }
// }

.devicesIcon {
   @media screen and (max-width: 767px) {
      display: none;
   }
}

.devicesCardSizes {
   @media screen and (max-width: 767px) {
      height: 64px;
      padding: 0.5rem;
      border-radius: 8px;
   }

   @media screen and (max-width: 450px) {
      height: 50px;
   }
}
