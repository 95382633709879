#build-apps {
  h2 {
    max-width: 749px;
  }

  h4 {
    line-height: 1;
  }

  br {
    @media screen and (max-width: 767px) {
      display: none;
    }

    @media screen and (max-width: 570px) {
      display: unset;
    }
  }

  .column-wrapper {
    background-color: #161616;
    border-radius: 24px;
    min-height: 360px;

    @media screen and (max-width: 991px) {
      min-height: 270px;
    }

    @media screen and (max-width: 767px) {
      min-height: 230px;
      height: fit-content;
      margin-bottom: 1.5rem;
    }
    @media screen and (max-width: 415px) {
      min-height: 210px;
    }
  }

  .content-card {
    max-width: 360px;
    margin: auto 0 auto 0;

    @media screen and (max-width: 991px) {
      max-width: 230px;
    }

    svg {
      width: 32px;
      height: 32px;
      margin-left: 10px;
      transform: rotate(-45deg);
      transition: 0.2s;

      &:hover {
        color: white;
      }

      @media screen and (max-width: 991px) {
        width: 27px;
        height: 27px;
      }
      @media screen and (max-width: 767px) {
        width: 22px;
        height: 22px;
      }
    }
  }

  .last-col {
    @media screen and (max-width: 767px) {
      margin-bottom: 0px;
    }
  }

  .bg1 {
    background-image: url("../../../assets/images/png/dapps-big-blurp.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: calc(50% - 130px) 50%;
  }

  .bg2 {
    background-image: url("../../../assets/images/png/dapps-sphere.png");
    background-position: calc(100% + 250px) 100%;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 991px) {
    h2 {
      max-width: 450px;
    }
  }

  @media screen and (max-width: 467px) {
  }
  @media screen and (max-width: 362px) {
    .content-card {
      h4 {
        max-width: 240px !important;
        font-size: 22px !important;
      }
    }
  }
  @media screen and (max-width: 334px) {
    .content-card {
      h4 {
        max-width: 200px !important;
      }
    }
  }
}
