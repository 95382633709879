#network-features {

  .network-square {
    margin-bottom: 40px;

    @media screen and (max-width: 700px) {
      width: 60px;
    }

    @media screen and (max-width: 650px) {
      margin-top: 40px;
    }
  }

  .font-size-56-b{
    font-size: 56px;
    @media screen and (max-width: 1440px) {
       font-size: 50px!important;
    }
    @media screen and (max-width: 1335px) {
       font-size: 40px!important;
    }
    @media screen and (max-width: 1135px) {
       font-size: 35px!important;
    }
    @media screen and (max-width: 1040px) {
       font-size: 30px!important;
    }
    @media screen and (max-width: 991px) {
       font-size: 35px!important;
    }
    @media screen and (max-width: 767px) {
       font-size: 30px!important;
    }
    @media screen and (max-width: 600px) {
       font-size: 25px!important;
    }
  }

  @media screen and (max-width: 1100px){
    .free-card,
    .crypto-card,
    .decentralized-card{
      height: 310px!important;
      
    }
    .p2p-card{
      height: 644px!important;
    }

  }

  .connection-container {
    max-width: 315px;

    @media screen and (max-width: 991px) {
      max-width: unset !important;
    }
  }

  // cards
  .cards-wrapper {
    width: 100%;
    display: flex;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  .network-card {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #161616;
    border-radius: 16px;
    overflow: hidden;
    // @media screen and (max-width: 768px){
    //   padding: 1.5rem;
    // }
  }

  .p2p-card {
    flex: 1;
    height: 800px;
    background-image: url("../../../assets/images/png/p2p-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 1.5rem;

    @media screen and (max-width: 991px) {
      width: 100%;
      min-height: auto;
      height: auto !important;
      margin-right: 0;
      margin-bottom: 1.5rem;
    }

    // @media screen and (max-width: 650px) {
    //   min-height: auto;
    //   height: auto;
    // }
  }

  .others-cards-container {
    flex: 2;
  }

  .two-cards-container {
    display: flex;

    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
  }
  .green-color{
    color: #20DEB0;
  }

  .crypto-card,
  .free-card{
    flex: 1;
    height: 388px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      min-height: 350px;
      height: 350px;
    
    }

    @media screen and (max-width: 650px) {
      width: 100%;
      min-height: auto;
      height: auto;
      .card-headings{
       margin-top: 3rem;
       margin-bottom: 3rem!important; 
      }
    }
  }

  .crypto-card {
    margin-right: 1.5rem;
    background: url("../../../assets/images/png/private-network-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .line-upper-title {
      background-color:#20DEB0;
    }

    @media screen and (max-width: 650px) {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  .free-card {
    background-image: url("../../../assets/images/png/free-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .decentralized-card {
    width: 100%;
    margin-top: 1.5rem;
    height: 388px;
    display: flex;
    flex-direction: row;
    background-image: url("../../../assets/images/png/private-network-page/private-network-broken-multiple-cubes.png");
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    // @media screen and (max-width: 600px) {
    //   min-height: 450px;
    //   flex-direction: column;
    // }
    @media screen and (max-width: 991px){
      height: auto!important;
      h4{
        margin: 3rem 0 3rem 0!important;
      }
    }

    .first-col {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 1.5rem;

      @media screen and (max-width: 600px) {
        order: 2;
      }
    }


  }

  .font-size-56 {
    @media screen and (max-width: 1277px) {
      font-size: 40px !important;
    }
    @media screen and (max-width: 1089px) {
      font-size: 36px !important;
    }
    @media screen and (max-width: 991px) {
      font-size: 35px !important;
    }
    @media screen and (max-width: 767px) {
      font-size: 30px !important;
    }
    @media screen and (max-width: 600px) {
      font-size: 25px !important;
    }
  }
}

@media screen and (max-width: 576px) {
  #network-features {
    .network-card {
      padding: 1.5rem !important;
    }
  
    .network-title-container {
      width: 100%;
    }
  }
}
