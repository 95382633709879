.consensus-section-homepage {
  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }

  .main-col {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 991px) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .consensus-title,
  .algorithm-title {
    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

  .dsc-container {
    @media screen and (max-width: 991px) {
      text-align: center;
      width: 100%;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }

  .slider-div {
    width: 100%;
    overflow: auto;
    scrollbar-width: none;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    cursor: grab;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slider-height {
    height: 550px;

    @media screen and (max-width: 767px) {
      height: 450px;
    }
  }

  .descriptions-container {
    width: 100%;
    display: flex;
    margin-top: 4rem;
    flex-direction: row !important;

    @media screen and (max-width: 991px) {
      margin-top: 3rem;
    }
  }

  .description-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 400px;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    @media screen and (max-width: 600px) {
    }
    min-width: 330px;

    @media screen and (max-width: 520px) {
      min-width: 310px;
    }

    @media screen and (max-width: 415px) {
      min-width: 250px;
    }
    @media screen and (max-width: 360px) {
      min-width: 210px;
    }
    @media screen and (max-width: 310px) {
      min-width: 195px;
    }
  }

  .box-title {
    margin: 48px 0 34px 0;

    @media screen and (max-width: 767px) {
      text-align: left !important;
      margin: 24px 0 16px 0;
    }
  }

  .image-container {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .cns-ut-container {
    @media screen and (max-width: 991px) {
      text-align: center !important;
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  .description-image {
    width: 100%;
    max-width: 300px;
    height: auto;
  }
  .description-first-image {
    width: 100% !important;
    height: auto !important;
    max-width: 275px !important;

    @media screen and (max-width: 410px) {
      max-width: 200px !important;
    }
  }
  .consensus-first-section {
    padding-top: 100px;
  }

  .consensus-padding-title {
    padding-top: 26px;
    padding-bottom: 34px;
  }

  .consensus-card {
    padding-right: 48px;
    padding-top: 87px;
  }

  .font-size-40 {
    font-size: 40px !important;

    @media screen and (max-width: 1430px) {
      font-size: 35px !important;
    }

    @media screen and (max-width: 1200px) {
      font-size: 30px !important;
    }

    @media screen and (max-width: 767px) {
      font-size: 28px !important;
    }

    @media screen and (max-width: 600px) {
      font-size: 24px !important;
    }
    @media screen and (max-width: 400px) {
      font-size: 20px !important;
    }
    // @media screen and (max-width: 310px) {
    //   font-size: 20px !important;
    // }
  }
}
