#xiden-attributes {
  background-color: black;

  .corners {
    height: 100%;
    margin: 0 0 0 0;
    max-width: 586px;
    margin-left: auto;
  }

  h4 {
    line-height: 1;
  }

  .description-991 {
    max-width: 586px;
  }

  .read-more-btn {
    background-color: transparent;
    border: none;
    margin-top: 2rem;
    transition: 0.2s;

    &:hover {
      color: white;
    }
  }
  .xiden-background {
    background-color: #161616;
    border-radius: 16px;
    //padding: 30px;
    margin-bottom: 32px;
    max-height: 271px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .xiden-container-img {
    transform: translate(-40%, 0%);
    //width: 100%;
  }

  .xiden-spacer {
    margin-bottom: 2rem;
  }

  .xiden-arrow-svg {
    margin-left: 10px;
  }

  .sect-title {
    line-height: 1;
  }

  .gas-container-img {
    margin-bottom: 42px;
    width: 100%;
  }

  .special-margin {
    p {
      max-width: 421px;
      margin: 0;
    }
  }

  @media screen and (max-width: 1520px) {
    .xiden-container-img {
      transform: none;
      width: 100%;
    }

    .xiden-background {
      max-height: unset;
    }
  }

  @media (max-width: 1290px) {
    .xiden-section-container {
      flex-direction: column-reverse;
    }
  }

  @media screen and (max-width: 1199px) {
    .special-margin {
      p {
        max-width: unset;
      }
    }
    .xiden-background {
      margin-bottom: 1.5rem;
      &:last-of-type {
        margin-bottom: 1.5rem;
      }
    }
    .corners {
      max-width: unset;
    }
    .gas-container {
      flex-direction: row !important;
      img {
        margin: auto;
        margin-right: 50px;
        height: fit-content !important;
        height: -moz-fit-content !important;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .gas-container {
      flex-direction: column !important;
      text-align: center;
      p {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
      img {
        width: 50%;
        margin: auto;
      }
      .xideen-upper-title {
        justify-content: center !important;
        margin-top: 1rem;
      }
      .read-more-btn {
        margin: auto;
      }
    }
  }

  // @media (max-width: 741px) {
  //   .xiden-container-img {
  //     width: 150px;
  //   }
  // }
  @media (max-width: 620px) {
    .xiden-background {
      flex-direction: column;
      // text-align: center;
    }
    .xiden-container-img {
      transform: unset;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    br {
      display: none;
    }
    .gas-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .special-margin {
      margin-left: 0px;
    }

    .img-container {
      margin: auto;
    }
  }

  @media screen and (max-width: 575px) {
    .img-container {
      margin-right: auto;
      margin-left: 0;

      .xiden-container-img {
        max-width: 160px;
      }
      .xiden-container-img {
        margin-top: 0;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .gas-container-img {
      width: 75%;
    }
    .gas-container {
      img {
        width: 80%;
      }
    }
  }

  //fix for no paragraph

  // .img-container{
  //   @media screen and (max-width: 1520px){
  //     img{
  //       max-width: 200px;
  //     }

  //     width: 450px;
  //   }
  //   @media screen and (max-width: 1200px){
  //     width: 600px;
  //   }
  //   @media screen and (max-width: 700px){
  //     width: unset;
  //   }
  //   @media screen and (max-width: 575px){
  //     img{
  //       max-width: 150px;
  //     }
  //   }
  // }
}
