@import "https://code.highcharts.com/css/highcharts.css";
.price-chart:first-child {
   width: 100% !important;
}
.price-chart {
   .highcharts-background {
      fill: #161616;
   }

   .highcharts-color-0 {
      fill: #22aaff !important;
      stroke: #22aaff !important;
   }

   .highcharts-area {
      fill: #1b5377;
   }

   .highcharts-alternate-grid {
      display: none;
   }

   .highcharts-grid {
      display: none;
   }

   .highcharts-markers {
      display: none;
   }

   .my-chart {
      width: 100% !important;
      margin: 0;
   }
}
.price-chart {
   overflow: hidden;
   display: flex;
   width: 100%;
   div {
      margin-top: auto;
      width: 100%;
   }
}
#supply-chart {
   .highcharts-background {
      fill: #161616;
   }

   // .highcharts-color-0 {
   //    fill: #af4aff !important;
   //    stroke: #af4aff !important;
   // }

   // .highcharts-area {
   //    fill: #502a6e;
   // }

   .highcharts-color-0 {
      fill: #22aaff !important;
      stroke: #20deb0 !important;
   }
   .highcharts-color-1 {
      fill: #22aaff !important;
      stroke: #ee694b !important;
   }

   .highcharts-alternate-grid {
      display: none;
   }

   .highcharts-grid {
      display: none;
   }

   .highcharts-markers {
      display: none;
   }
}
