#connected-devices {
  background-image: url("../../../assets/images/png/sdr-layer/xiden-cut.png");
  background-repeat: no-repeat;
  background-position: 50% calc(100% - 130px);
  background-size: 2500px;
  height: 1000px;

  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }

  @media screen and (max-width: 1400px) {
    background-size: 2000px;
    background-position: 50% calc(100% - 230px);
  }
  @media screen and (max-width: 1100px) {
    background-size: 1500px;
    background-position: 50% calc(100% - 320px);
  }

  @media screen and (max-width: 991px) {
    height: auto;
    background-image: none;
  }

  .font-size-28 {
    font-size: 28px;

    @media screen and (max-width: 1200px) {
      font-size: 26px;
    }

    @media screen and (max-width: 991px) {
      font-size: 22px;
    }

    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }

  .mt-custom {
    margin-top: 10rem !important;

    @media screen and (max-width: 1400px) {
      margin-top: 5rem !important;
    }
    @media screen and (max-width: 991px) {
      margin-top: 1.5rem !important;
    }
  }

  .connected-row {
    .font-size-80 {
      @media screen and (max-width: 300px) {
        font-size: 30px !important;
      }
    }
  }

  .devices-gradient {
    background: linear-gradient(180deg, #20deb0, #22aaff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    width: fit-content;
    width: -moz-fit-content;
  }

  .resources-gradient {
    background: linear-gradient(180deg, #af4aff, #477bff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    width: fit-content;
    width: -moz-fit-content;
  }

  .rewards-gradient {
    background: linear-gradient(180deg, #f9ca53, #ee694b);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    width: fit-content;
    width: -moz-fit-content;
  }

  .description-sdr {
    text-align: end;

    @media screen and (max-width: 767px) {
      text-align: center !important;
      max-width: 600px;
    }
  }

  @media screen and (max-width: 767px) {
    .connected-row {
      flex-direction: column;
    }

    h3 {
      text-align: center;
    }

    .first-title {
      margin-top: 2rem;
    }

    .connected-xid-img {
      width: 65%;
      align-self: center;
    }

    .mobile-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 550px) {
    .connected-xid-img {
      width: 80%;
    }
  }

  @media screen and (max-width: 450px) {
    .connected-xid-img {
      width: 100%;
    }
  }
}
