.mh-432 {
    max-height: 432px;
}

.get-reward-title {
    @media screen and (max-width: 767px) {
        text-align: center;
    }
}

.reward-steps-section {
    .text-start {
        margin: unset !important;

        @media screen and (max-width: 767px) {
            margin: auto !important;
        }
    }
}

.switch-section{
    .buttonBlue {
        width: 300px !important;
        max-width: unset !important;
        position: relative;
        border-radius: 16px !important;
        color: #ffffff !important;
        background: transparent !important;
        cursor: pointer !important;
        border: 1px solid #22aaff;
        transition: .2s;

        &:hover {
            color: #22aaff !important;
        }
    }


    .buttonBlueActive {
        background: #22aaff !important;
        color: #000000 !important;
        // &::after {
        //     content: "";
        //     position: absolute;
        //     width: 90%;
        //     height: 2px;
        //     bottom: -5px;
        //     background-color: #22aaff;
        // }

        &:hover {
            color: #000000 !important;
        }
    }
}