#cornered-cards-ecosystem {
  @media screen and (max-width: 1440px) {
    padding-bottom: 0px;
  }
  .read-more-btn {
    // width: 140px;
    width: fit-content;
    width: -moz-fit-content;
    background: transparent;
    border: none;
    transition: 0.2s;
    text-decoration: none;

    svg {
      margin-left: 10px;
    }

    &:hover {
      color: white;
    }
  }

  p {
    // max-width: 440px;
    line-height: 1.1;
  }

  .left-img {
    margin-right: auto;
    //transform: translateY(-40px);
  }
  .right-img {
    margin: 0;
    //transform: translateY(-40px);
  }
  .rand {
    width: 100%;
  }

  .corners {
    height: 500px;
    padding: 2rem;
    @media screen and (max-width: 991px) {
      padding: 1.5rem;
    }
  }

  .staking-big-img {
    margin: auto;
  }

  .guardian-node-img {
    width: 100%;
    max-width: 520px;
    height: auto;
  }

  .krater-pool-img {
    width: 100%;
    max-width: 500px;
    height: auto;
  }

  .booster-img {
    width: 100%;
    // max-width: 450px;
    max-width: 400px;
    height: auto;

    @media screen and (max-width: 991px) {
      max-width: 350px;
    }
    @media screen and (max-width: 767px) {
      max-width: 270px !important;
    }
  }

  .krater-pool-hotspot-img {
    width: 100%;
    max-width: 550px;
    height: auto;
  }

  .ecosystem-corners-info-col {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      width: 100%;
      max-width: 500px;
    }

    @media screen and (max-width: 767px) {
      align-items: center;
      text-align: center;
    }
  }

  .ecosystem-corners-img {
    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 300px !important;
    }
  }

  @media screen and (max-width: 991px) {
    .img-col {
      padding-left: calc(var(--bs-gutter-x) * 1) !important; //bootstrap container fix
      padding-right: 0;
    }
    .corners {
      height: auto;
      margin: 12px 0 12px 0;
    }
    .left-img,
    .right-img {
      transform: none;
      margin: auto;
      // width: 40%;
    }
    .staking-big-img {
      width: 30%;
    }
    @media screen and (max-width: 768px) {
      .left-img,
      .right-img {
        transform: none;
        margin: auto;
        // width: 60%;
      }
      // .staking-big-img {
      //    width: 40%;
      // }
    }
  }
  @media screen and (max-width: 480px) {
    .left-img,
    .right-img {
      margin-top: 1rem !important;
      transform: none;
      margin: auto;
      width: 100%;
    }
    .staking-big-img {
      width: 70%;
    }
  }
}
