#homepage-hero {
  overflow: hidden;

  #big-bang-era {
    background-image: url("./../../../assets/images/png/big-bang-era.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  // padding-top: 6rem;
  .banner {
    max-width: 2500px;
    width: 100%;

    @media screen and(min-width: 2500px) {
      border-radius: 25px;
    }
  }

  &.page-section {
    // padding-bottom: 3rem;
    padding-top: 6rem;

    @media screen and (max-width: 991px) {
      padding-bottom: 0;
      padding-top: 8rem;
    }
  }

  .homepage-hero-txt {
    @media screen and (max-width: 1600px) {
      // padding-top: 200px;
    }

    @media screen and (max-width: 1400px) {
      // padding-top: 120px;
    }

    @media screen and (max-width: 991px) {
      // padding-top: 0;
    }
  }

  .hero-img {
    max-width: 1442px;
    margin-left: 50px;

    @media screen and (max-width: 1600px) {
      max-height: 900px;
    }

    @media screen and (max-width: 1400px) {
      max-height: 800px;
    }

    @media screen and (max-width: 1100px) {
      max-height: 700px;
    }

    @media screen and (max-width: 991px) {
      margin-left: 0;
      padding-bottom: 2rem;
      max-height: unset;
      width: 70%;
      margin: auto;
    }

    @media screen and (max-width: 767px) {
      width: 90%;
    }

    @media screen and (max-width: 550px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 991px) {
    .description-991 {
      margin: auto;
    }
  }

  @media screen and (max-width: 991px) {
    .xideen-upper-title {
      justify-content: center;
      align-items: center;
    }
  }

  .buttonOutlineBlue {
    @media screen and (max-width: 319px) {
      margin-left: 0;
    }
  }

  .buttons-flex-direction {
    @media screen and (max-width: 991px) {
      width: 100%;
      justify-content: center !important;
    }

    @media screen and (max-width: 319px) {
      flex-direction: column;

      .hero-custom-btn {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }

      .hero-custom-btn:first-child {
        margin-bottom: 1rem;
      }
    }
  }

  .text-align-end {
    text-align: right;
  }

  .line-height-1 {
    line-height: 1;
  }

  .banner {
    min-height: 120px;
    position: relative;
    display: flex;
    color: black;
    background: linear-gradient(to right, #674bdd, #22aaff);
    // background-image: url("../../../assets/images/png/banner-bg.png");
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;

    // @media screen and (max-width: 1010px) {
    //    flex-direction: column;
    //    min-height: unset;

    //    .banner-column {
    //       width: unset !important;
    //    }

    //    .banner-item {
    //       flex-direction: row;
    //       justify-content: space-between;
    //       text-align: left;
    //       border-bottom: 1px solid #000000;
    //    }
    // }

    @media screen and (max-width: 767px) {
      margin-top: 2rem;
    }
  }

  .banner-column {
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 520px) {
      flex-direction: column !important;
    }
  }

  .banner-item {
    flex: 1;
    padding: 2rem;
    display: flex;
    align-items: center;
    min-height: 90px;
    justify-content: space-between;

    &:not(:last-child) {
      border-right: 1px solid #000000;
    }

    @media screen and (max-width: 1450px) {
      padding: 1rem;
    }
    @media screen and (max-width: 850px) {
      min-height: unset;
    }

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      opacity: 1;

      .blur-me {
        color: black;
        text-shadow: none;
      }
    }

    // @media screen and (max-width: 1400px) {
    //    flex-direction: column;
    //    justify-content: center;
    //    text-align: center;
    //    padding: 1rem;
    // }

    @media screen and (max-width: 600px) {
      flex-direction: row;
      //padding: 2rem;
      border-right: 0;
      text-align: left;
      justify-content: space-between;

      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      // &:not(:last-child) {
      // }
    }

    @media screen and (max-width: 520px) {
      &:not(:last-child) {
        border-right: none;
      }
    }
  }

  .w-33 {
    width: 33.3333% !important;
  }

  .font-size-20 {
    font-size: 20px;

    @media screen and (max-width: 1450px) {
      font-size: 18px;
    }

    @media screen and (max-width: 600px) {
      font-size: 16px;
    }

    @media screen and (max-width: 400px) {
      font-size: 15px;
    }
  }

  .number-span {
    font-size: 32px;

    @media screen and (max-width: 1550px) {
      font-size: 30px;
    }
    @media screen and (max-width: 1450px) {
      font-size: 26px;
    }
    @media screen and (max-width: 1400px) {
      font-size: 24px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 22px;
    }
    @media screen and (max-width: 1100px) {
      font-size: 18px;
    }

    @media screen and (max-width: 400px) {
      text-align: right;
    }
  }

  .accordion-button {
    min-height: 80px !important;
    position: relative !important;
    display: flex !important;
    color: black !important;
    background: linear-gradient(to right, #674bdd, #22aaff) !important;
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
  }

  .banners-mobile {
    flex-direction: column;
    .banner-item {
      min-height: 80px !important;
      border: none !important;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      border-bottom: 1px solid #000000;
    }

    .banner-column {
      width: 100% !important;
    }

    .accordion-body {
      padding: 0px !important;
      color: black !important;
      background: linear-gradient(to right, #674bdd, #22aaff) !important;
      width: 100% !important;
      border: none !important;
    }

    .accordion-header svg {
      width: unset !important;
      height: unset !important;
    }

    .banner-column {
      border-right: none !important;
      border-bottom: 1px solid black;
    }

    .acordeon-plus,
    .acordeon-plus-2 {
      svg {
        width: 16px !important;
        height: 16px !important;
      }
    }
    .acordeon-plus-2 {
      svg {
        color: white !important;
      }
    }
  }

  @media screen and (max-width: 550px) {
    .accordion-button {
      min-height: 60px !important;
    }
    .banners-mobile .banner-item {
      height: 60px !important;
      min-height: 60px !important;
    }

    .banners-mobile .accordion-header .banner-item .banner-title svg {
      width: 32px !important;
      height: auto !important;
    }
    .banner-title svg {
      width: 32px !important;
      height: auto !important;
    }
  }

  @media screen and (max-width: 350px) {
    .banner {
      min-height: 100px;
    }

    .banner-item {
      padding: 12px;
    }

    .accordion-button {
      min-height: 45px !important;
    }
    .banners-mobile .banner-item {
      height: 45px !important;
      min-height: 45px !important;
    }
  }
}
