.rewards-computing-section {
  //adi
  // height: 1100px;
  // @media screen and (max-width: 1200px){
  //    height: 950px;
  // }
  // @media screen and (max-width: 991px){
  //    height: 750px;
  // }

  // large screen
  .gauge-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 15rem;
    margin-bottom: 8rem;

    @media screen and (max-width: 1200px) {
      margin-top: 10rem;
      margin-bottom: 7rem;
    }
    @media screen and (max-width: 970px) {
      margin-top: 8rem;
    }
    @media screen and (max-width: 820px) {
      margin-top: 6rem;
      margin-bottom: 5rem;
    }
    @media screen and (max-width: 640px) {
      margin-bottom: 3rem;
    }
    @media screen and (max-width: 575px) {
      display: none;
    }
  }

  .rewards-p-container-lg {
    max-width: 900px;
    @media screen and (max-width: 991px) {
      max-width: 600px;
    }
  }

  .main-card {
    width: 100%;
    height: 374px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    padding: 56px;
    background-color: #161616;
    background-image: url("../../../assets/images/png/computing-gauge-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 1250px) {
      height: 300px;
    }
    @media screen and (max-width: 992px) {
      height: 250px;
    }
    @media screen and (max-width: 830px) {
      height: 200px;
    }
    @media screen and (max-width: 768px) {
      height: 160px;
    }
    @media screen and (max-width: 690px) {
      padding: 30px;
    }
    @media screen and (max-width: 600px) {
      height: 140px;
    }
  }

  .computing-text-container {
    margin-right: 1rem;
  }

  .storage-text-container {
    margin-left: 1rem;
  }

  .gauge-title {
    font-size: 56px;

    @media screen and (max-width: 1400px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1250px) {
      font-size: 30px;
    }

    @media screen and (max-width: 992px) {
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  .gauge-number {
    font-size: 80px;
    color: #22aaff;
    margin-right: 0.5rem;

    @media screen and (max-width: 1400px) {
      font-size: 60px;
    }
    @media screen and (max-width: 1250px) {
      font-size: 50px;
    }
    @media screen and (max-width: 992px) {
      font-size: 40px;
    }
    @media screen and (max-width: 768px) {
      font-size: 30px;
    }
  }

  .gauge-unit {
    font-size: 24px;

    @media screen and (max-width: 1400px) {
      font-size: 18px;
    }

    @media screen and (max-width: 1250px) {
      font-size: 16px;
    }
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  .circle-container {
    position: relative;
  }

  .circle-image {
    width: 100%;
    max-width: 700px;
    height: auto;

    @media screen and (max-width: 1250px) {
      max-width: 600px;
    }
  }

  .small-circles {
    position: absolute;
    top: 60px;
    right: -50px;

    @media screen and (max-width: 1250px) {
      top: 40px;
      right: -40px;
      width: 200px;
    }

    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  // small screen
  .gauge-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    // padding: 1.5rem;
    margin-top: 3rem;
    border-radius: 16px;
    background-image: url("../../../assets/images/png/lg-box-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    @media screen and (min-width: 576px) {
      display: none;
    }
  }

  .small-circle-sm {
    width: 100%;
    max-width: 300px;
    height: auto;
  }

  .power-text-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    @media screen and (max-width: 400px) {
      flex-direction: column;

      div:first-child {
        margin-bottom: 1rem;
      }
    }
  }

  .resources-overlay {
    // z-index: 50;
    border-radius: 16px;
  }
}
