#sdr-card-two {
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  .om-bg {
    background-image: url("./assets/blue-soldier.png");
    background-repeat: no-repeat;
    background-size: 340px;
    background-position: 0% 70%;

    @media screen and (max-width: 991px) {
      background-image: none;
    }
  }

  .plus-icons {
    width: 14px;
  }

  .font-size-28 {
    font-size: 28px;

    @media screen and (max-width: 1200px) {
      font-size: 28px !important;
    }

    @media screen and (max-width: 1100px) {
      font-size: 25px !important;
    }

    @media screen and (max-width: 985px) {
      font-size: 24px !important;
    }

    @media screen and (max-width: 479px) {
      font-size: 20px !important;
    }
  }

  .font-size-40 {
    font-size: 40px;

    @media screen and (max-width: 1430px) {
      font-size: 35px;
    }

    @media screen and (max-width: 1200px) {
      font-size: 33px !important;
    }

    @media screen and (max-width: 767px) {
      font-size: 28px !important;
    }

    @media screen and (max-width: 600px) {
      font-size: 25px !important;
    }

    @media screen and (max-width: 360px) {
      font-size: 20px !important;
    }
  }

  .font-size-80 {
    @media screen and (max-width: 450px) {
      font-size: 32px !important;
    }

    @media screen and (max-width: 320px) {
      font-size: 29px !important;
    }
  }

  .font-size-48 {
    @media screen and (max-width: 600px) {
      font-size: 22px !important;
    }
    @media screen and (max-width: 382px) {
      font-size: 20px !important;
    }
  }

  .font-size-80 {
    font-size: 60px !important;

    @media screen and (max-width: 1250px) {
      font-size: 60px !important;
    }

    @media screen and (max-width: 1100px) {
      font-size: 50px !important;
    }

    @media screen and (max-width: 991px) {
      font-size: 40px !important;
    }

    @media screen and (max-width: 767px) {
      font-size: 32px !important;
    }

    @media screen and (max-width: 305px) {
      font-size: 28px !important;
    }
  }

  .transform-card {
    transform-style: preserve-3d;
    transition: transform 1s;
  }

  .close-btn {
    transform: rotate(45deg);
    transition: transform 0.4s ease-out;
  }

  // .white-color-custom {
  //   h6 {
  //     color: white !important;
  //   }
  // }

  .plus-btn {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-left: auto;
    cursor: pointer;
    align-self: end;
  }

  .xiden-card-box-app {
    height: 100%;
  }

  #xiden-card7-box {
    &.xiden-card7-box-app-card {
      position: relative;
    }

    &.xiden-card7-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 535px;
    }

    .plus-btn-xiden-card7-box {
      background-color: #07f5f7;
    }

    .xiden-card7-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
      background-color: #018d72;
      border-radius: 24px;
    }

    .xiden-card7-box-front-side {
      background-image: url("./assets/blue-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card7-box-back-side {
      transform: rotateY(180deg);
      background-image: url("./assets/blue-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card7-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card7-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card8-box {
    &.xiden-card8-box-app-card {
      position: relative;
    }

    &.xiden-card8-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 979px;

      @media screen and (max-width: 991px) {
        height: 500px;
      }
    }

    .plus-btn-xiden-card8-box {
      background-color: #07f5f7;
    }

    .xiden-card8-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
      background-color: #018d72;
      border-radius: 24px;
    }

    .xiden-card8-box-front-side {
      background-image: url("./assets/all-in-one-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card8-box-back-side {
      transform: rotateY(180deg);
      background-image: url("./assets/all-in-one-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card8-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card8-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card9-box {
    &.xiden-card9-box-app-card {
      position: relative;
    }

    &.xiden-card9-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 388px;
    }

    .plus-btn-xiden-card9-box {
      background-color: #07f5f7;
    }

    .xiden-card9-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
      // background-color: #018d72;
      border-radius: 24px;
    }

    .xiden-card9-box-front-side {
      background-image: url("./assets/crypto-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card9-box-back-side {
      transform: rotateY(180deg);
      background-image: url("./assets/crypto-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card9-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card9-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card10-box {
    &.xiden-card10-box-app-card {
      position: relative;
    }

    &.xiden-card10-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 388px;
    }

    .plus-btn-xiden-card10-box {
      background-color: #07f5f7;
    }

    .xiden-card10-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
      // background-color: #018d72;
      border-radius: 24px;
    }

    .xiden-card10-box-front-side {
      background-image: url("./assets/free-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card10-box-back-side {
      transform: rotateY(180deg);
      background-image: url("./assets/free-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card10-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card10-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card11-box {
    &.xiden-card11-box-app-card {
      position: relative;
    }

    &.xiden-card11-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 567px;

      @media screen and (max-width: 750px) {
        height: 600px;
      }
    }

    .plus-btn-xiden-card11-box {
      background-color: #07f5f7;
    }

    .xiden-card11-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
      // background-color: #018d72;
      border-radius: 24px;
    }

    .xiden-card11-box-front-side {
      background-image: url("./assets/transition-blue-color.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card11-box-back-side {
      transform: rotateY(180deg);
      background-image: url("./assets/transition-blue-color.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card11-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card11-box-back-side {
      transform: rotateY(0deg);
    }

    .cube-img {
      transform: translate(100px, -57px);
      mix-blend-mode: luminosity;

      @media screen and (max-width: 1546px) {
        transform: unset;
      }

      @media screen and (max-width: 1300px) {
        width: 45%;
      }

      // @media screen and (max-width: 768px) {
      //   width: 80%;
      // }

      // @media screen and (max-width: 719px) {
      //   width: 44%;
      // }
      @media screen and (max-width: 350px) {
        width: 79%;
      }
    }

    @media screen and (max-width: 750px) {
      .mobile-flex {
        flex-direction: column;
        align-items: start !important;
      }
    }

    .custom-mobile-margin {
      @media screen and (max-width: 555px) {
        margin-right: 30px;
      }

      @media screen and (max-width: 350px) {
        margin-right: 0px;
      }
    }
  }

  .line-height-1 {
    line-height: 1 !important;
  }

  @media screen and (max-width: 991px) {
    #xiden-card7-box {
      height: 390px !important;
    }
    #xiden-card8-box {
      height: 390px !important;
    }
    #xiden-card9-box {
      height: 300px !important;
    }
    #xiden-card10-box {
      height: 300px !important;
    }
    #xiden-card11-box {
      height: 430px !important;
    }
    .cube-img {
      max-width: 240px;
      width: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    #xiden-card7-box {
      height: 480px !important;
    }
    #xiden-card10-box {
      height: 320px !important;
    }
  }
  @media screen and (max-width: 750px) {
    .cube-img {
      max-width: 140px;
    }
  }
  @media screen and (max-width: 600px) {
    #xiden-card8-box {
      height: 340px !important;
    }
  }
}
