.minter-support-section{
    .buttonOutlineBlue{
        min-width: 100% !important;
        padding: 12px !important;

        @media screen and (max-width: 1200px){
            span{
                font-size: 18px !important;
            }

            height: 60px !important;
        }
        @media screen and (max-width: 767px){
            span{
                font-size: 16px !important;
            }
        }
    }

    .router-img{
        width: 120px;
    }

    .hero-img{
        @media screen and (max-width: 991px){
            width: 800px !important;
        }
    }
}