#disclaimer-card-section {

    .container-main {
        max-width: 1300px;
    }

    .rotate-uppertitle {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%) rotate(-90deg);
        left: -30px;

        .xideen-upper-title {
            justify-content: center !important;
        }

        &.right-0 {
            right: -40px;
            left: unset;
        }

        @media screen and (max-width: 767px) {
            left: -27px;
        }
    }

    .disclaimer-card {
        background-image: url("../../../assets/images/png/disclaimer-card-bg.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 23px;
        padding: 3rem;
        margin-left: 3rem;

        @media screen and (max-width: 1200px) {
            margin-left: 2rem;
        }

        @media screen and (max-width: 991px) {
            padding: 1.5rem;
        }

        @media screen and (max-width: 530px) {
            br {
                display: none;
            }

            .disclaimer-card-text {
                line-height: 1.1;
            }

            .font-size-56 {
                font-size: 28px !important;
            }
        }

        @media screen and (max-width: 380px) {
            margin-left: 1.5rem;
        }
    }
}