.toggleWrapper {
   position: relative;

   .toggleInput {
      position: absolute;
      opacity: 0;

      &:checked + span {
         background-color: #060606;

         &::before {
            left: calc(100% - 2px);
            transform: translateX(-100%);
         }
      }

      &:focus + span {
         box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      }

      &:focus:checked + span {
         box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }
   }

   .toggleSlider {
      position: relative;
      display: flex;
      width: 50px;
      height: 25px;
      border-radius: 100px;
      background-color: #060606;
      cursor: pointer;
      transition: all 0.2s;

      &::before {
         content: "";
         position: absolute;
         top: 2px;
         left: 2px;
         width: 21px;
         height: 21px;
         border-radius: 21px;
         background-color: #22aaff;
         transition: 0.2s;
      }

      &:active::before {
         width: 28px;
      }
   }
}
