.performer-guardian-page {
    .description-991{
        @media screen and (max-width: 991px){
            width: 450px;
        }

        @media screen and (max-width:550px){
            width: unset;
        }
    }

    //##############################
    //first SECTION FROM ALL DEVICES
    .first-section {
        p {
            max-width: 490px;
            line-height: 1.2;
        }

        .buttons-container {
            .buttonOutlineBlue {
                svg {
                    transform: rotate(-45deg);
                }
            }
            @media screen and (max-width: 768px) {
                flex-direction: column;

                .buttonBlue {
                    margin-bottom: 1.5rem;
                }
            }
            @media screen and (max-width: 400px) {
                .buttonBlue {
                    margin-bottom: unset;
                }
            }
        }
    }
    //END first SECTION FROM ALL DEVICES
    //##################################

    //###############################
    //SECOND SECTION FROM ALL DEVICES
    .second-section{
        margin-top: 7rem!important;
        p{
            max-width: 450px;
            line-height: 1.2;
        }
    }

    @media screen and(max-width: 991px) {
        .corners {
            height: unset;
        }
    }
    //END SECOND SECTION FROM ALL DEVICES
    //###################################

    //###################################
    //DIAGONAL CORNERS RESPONSIVE CUSTOM
    @media screen and (max-width: 991px) {

        .box {
            width: 240px;
        }
        .diagonal-corners .text {
            bottom: -45px;
        }
    }
    @media screen and (max-width: 768px) {
        .diagonal-corners .text {
            bottom: -38px;
        }
    }
    @media screen and (max-width: 768px) {
        .box {
            width: 200px!important;
        }
    }

    @media screen and (max-width: 400px) {
        .diagonal-corners {
            width: unset;
        }
    }
    //END DIAGONAL CORNERS RESPONSIVE CUSTOM
    //######################################

    //########################################
    //UN ARTIFICIU PENTRU UN VIZUAL MAI PLACUT
    .desktop-header{
        @media screen and (max-width: 991px){
            display: none!important;
        }
    }

    .mobile-header{
        @media screen and (max-width: 991px){
            display: block!important;
        }
    }
    //END ARTIFICIU
    //#############
}