#sdr-card {
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  .om-bg {
    background-image: url("./assets/green-soldier.png");
    background-repeat: no-repeat;
    background-size: 370px;
    background-position: 100% 70%;

    @media screen and (max-width: 991px) {
      background-image: none;
    }
  }

  .plus-icons {
    width: 14px;
  }

  .font-size-28 {
    font-size: 28px;

    @media screen and (max-width: 1200px) {
      font-size: 28px !important;
    }

    @media screen and (max-width: 1100px) {
      font-size: 25px !important;
    }

    @media screen and (max-width: 985px) {
      font-size: 24px !important;
    }

    @media screen and (max-width: 479px) {
      font-size: 20px !important;
    }

    // @media screen and (max-width: 991px) {
    //    font-size: 22px !important;
    // }

    // @media screen and (max-width: 600px) {
    //    font-size: 20px;
    // }
  }

  .font-size-56 {
    @media screen and (max-width: 768px) {
      font-size: 25px !important;
    }
    // @media screen and (max-width: 370px) {
    //   font-size: 25px !important;
    // }
  }

  .font-size-80 {
    font-size: 80px !important;

    @media screen and (max-width: 1400px) {
      font-size: 70px !important;
    }

    @media screen and (max-width: 1250px) {
      font-size: 60px !important;
    }

    @media screen and (max-width: 1100px) {
      font-size: 50px !important;
    }

    @media screen and (max-width: 991px) {
      font-size: 40px !important;
    }

    @media screen and (max-width: 767px) {
      font-size: 30px !important;
    }

    @media screen and (max-width: 305px) {
      font-size: 28px !important;
    }
  }

  .developers-gradient {
    background: linear-gradient(to right, #0071b7, #0e688f, #0088c6);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    width: fit-content;
    width: -moz-fit-content;
  }

  .users-gradient {
    background: linear-gradient(to right, #00b191, #007d65);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    width: fit-content;
    width: -moz-fit-content;
  }

  .transform-card {
    transform-style: preserve-3d;
    transition: transform 1s;
  }

  .close-btn {
    transform: rotate(45deg);
    transition: transform 0.4s ease-out;
  }

  .plus-btn {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-left: auto;
    cursor: pointer;
    align-self: end;
  }

  .xiden-card-box-app {
    height: 100%;
  }

  #xiden-card1-box {
    .plus-btn-xiden-card1-box {
      background-color: #f9ca53;
    }

    &.xiden-card1-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 388px;
    }

    .xiden-card1-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
    }

    .xiden-card1-box-front-side {
      background-image: url("./assets/eco-friendly-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;

      // @media screen and (max-width: 991px) {
      //     margin-bottom:
      // }
    }

    .xiden-card1-box-back-side {
      transform: rotateY(180deg);
      background-image: url("./assets/eco-friendly-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card1-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card1-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card2-box {
    &.xiden-card2-box-app-card {
      position: relative;
    }

    &.xiden-card2-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 388px;
    }

    .plus-btn-xiden-card2-box {
      background-color: #20deb0;
    }

    .xiden-card2-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
    }

    .xiden-card2-box-front-side {
      background-image: url("./assets/benefits-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card2-box-back-side {
      transform: rotateY(180deg);
      background-image: url("./assets/benefits-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card2-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card2-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card3-box {
    &.xiden-card3-box-app-card {
      position: relative;
    }

    &.xiden-card3-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 450px;

      @media screen and (max-width: 1032px) {
        height: 500px;
      }
    }

    .plus-btn-xiden-card3-box {
      background-color: #30deb0;
    }

    .xiden-card3-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
    }

    .xiden-card3-box-front-side {
      background-image: url("./assets/green-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card3-box-back-side {
      transform: rotateY(180deg);
      background-image: url("./assets/green-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card3-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card3-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card4-box {
    &.xiden-card4-box-app-card {
      position: relative;
    }

    &.xiden-card4-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 450px;

      @media screen and (max-width: 1032px) {
        height: 500px;
      }
    }

    .plus-btn-xiden-card4-box {
      background-color: #30deb0;
    }

    .xiden-card4-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
    }

    .xiden-card4-box-front-side {
      background-image: url("./assets/pointer-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card4-box-back-side {
      transform: rotateY(180deg);
      background-image: url("./assets/pointer-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card4-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card4-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card5-box {
    &.xiden-card5-box-app-card {
      position: relative;
    }

    &.xiden-card5-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 450px;

      @media screen and (max-width: 1032px) {
        height: 500px;
      }
    }

    .plus-btn-xiden-card5-box {
      background-color: #30deb0;
    }

    .xiden-card5-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
    }

    .xiden-card5-box-front-side {
      background-image: url("./assets/xiden-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .xiden-card5-box-back-side {
      transform: rotateY(180deg);
      background-image: url("./assets/xiden-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card5-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card5-box-back-side {
      transform: rotateY(0deg);
    }
  }

  #xiden-card6-box {
    &.xiden-card6-box-app-card {
      position: relative;
    }

    &.xiden-card6-box-card {
      perspective: 150rem;
      -moz-perspective: 150rem;
      position: relative;
      height: 535px;
    }

    .plus-btn-xiden-card6-box {
      background-color: #30deb0;
    }

    .xiden-card6-box-card-side {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.5rem;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: all 0.8s ease-out;
      width: 100%;
      background-color: #018d72;
      border-radius: 24px;
    }

    .xiden-card6-box-front-side {
      background-image: url("./assets/xiden-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      border-radius: 15px;
      cursor: pointer;

      & > div:not(:first-child) {
        position: relative;
        z-index: 10;
      }
    }

    .line-height-1 {
      line-height: 1 !important;
    }

    .xiden-card6-box-back-side {
      transform: rotateY(180deg);
      background-image: url("./assets/xiden-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      cursor: pointer;
    }

    .rotate-xiden-card6-box-front-side {
      transform: rotateY(-180deg);
    }

    .rotate-xiden-card6-box-back-side {
      transform: rotateY(0deg);
    }
  }

  @media screen and (max-width: 991px) {
    #xiden-card6-box {
      height: 320px !important;
    }
    #xiden-card1-box {
      height: 270px !important;
    }

    #xiden-card2-box {
      height: 300px !important;
    }
    #xiden-card3-box,
    #xiden-card4-box,
    #xiden-card5-box {
      height: 280px !important;
    }
  }

  @media screen and (max-width: 500px) {
    #xiden-card6-box {
      height: 390px !important;
    }
    #xiden-card1-box {
      height: 290px !important;
    }
  }
  @media screen and (max-width: 350px) {
    #xiden-card2-box {
      height: 340px !important;
    }
  }
}
