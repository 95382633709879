$blue_color: #22aaff;
$breakpoint-tablet: 1168px;

.container-nav {
  .xiden-logo {
    cursor: pointer;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  position: relative;

  .tabletMenuBtn {
    width: 122px;
    justify-content: flex-end;
  }

  .tabletMenuBtn {
    display: none;
  }

  .line-height-1 {
    line-height: 1;
  }

  .navigation {
    height: 80px;

    @media screen and (max-width: 570px) {
      height: 65px;
    }
  }

  .xiden-logo {
    height: 42px;
  }

  .NavBar {
    //BLUR

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }

    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

    //###############################################

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    &.gray-bg-color {
      background: rgba(22, 22, 22, 0.85);
    }

    &.blue-bg-color {
      background: rgba(19, 4, 95, 0.85);
    }
  }

  .navigation-menu {
    display: flex;
    width: 477px;
    height: 80px;

    .nav-item {
      text-decoration: none;
    }

    .nav-item.dropdown {
      padding: 0.25rem 1rem;
    }

    img {
      margin: 10px;
    }
  }

  .explorer-link {
    &:hover {
      color: #fff;
    }
  }

  .getXDN-btn-gold {
    // background: #000000;
    background-color: transparent;
    padding: 10px;
    height: 48px;
    width: 160px;
    border: 1.5px solid #fda544;
    border-radius: 24px;
    color: #fda544;
    transition: 0.2s;
    text-decoration: none;

    svg {
      height: 24px;
      width: 24px;
      margin: 0 0 0 5px;
      padding: 0 0 5px 0;
    }

    &:hover {
      background-color: #fda544;
      color: #191919;
    }
  }

  .getXDN-btn {
    // background: #161616;
    background-color: transparent;
    padding: 10px;
    height: 48px;
    width: 160px;
    border: 1.5px solid $blue_color;
    border-radius: 24px;
    color: $blue_color;
    transition: 0.2s;
    text-decoration: none;

    svg {
      height: 24px;
      width: 24px;
      margin: 0 0 0 5px;
      padding: 0 0 5px 0;
    }

    &:focus,
    &:focus-visible {
      box-shadow: none !important;
    }
  }

  .getXDN-btn:hover {
    background-color: $blue_color;
    color: black;

    svg {
      filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%)
        contrast(1000%);
    }
  }

  .getXDN-btn:focus {
    box-shadow: 0px 0px 0px 4px rgba(34, 170, 255, 0.2);
  }

  //ZONA CUSTOMIZARE CLASE BOOTSTRAP

  .dropdown-toggle::after {
    display: none !important;
  }

  .nav-link,
  .nav-item a {
    padding: 0 !important;
    margin: 0 !important;
    color: #86868b;
  }

  .navigation-menu div {
    background: #161616 !important;
  }

  .nav-item:hover {
    background-color: transparent !important;
  }

  .dropdown-menu {
    margin-top: 16px;
  }

  .dropdown-menu.show {
    border: 1px solid rgb(41, 39, 39);
  }

  .dropdown-menu.show a {
    padding-left: 15px !important;
    padding-right: 15px !important;

    &:not(:first-child) {
      padding-top: 10px !important;
    }
  }

  .dropdown-menu.show a:hover {
    //   color: $blue_color !important;
    background: unset !important;
    color: #d1d1d1 !important;
  }

  //END ZONA CUSTOMIZARE BOOTSTRAP

  //ZONA media 991px
  @media screen and (max-width: $breakpoint-tablet) {
    .tabletMenuBtn {
      display: flex;
      background: transparent;
      color: white;
      justify-content: flex-end;
    }

    .getXDN-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 140px;
      height: 40px;
    }

    .navigation-menu {
      border: 1px solid red;
      display: none !important;
    }

    // .NavBar {
    //    padding-left: 10px;
    //    padding-right: 10px;
    // }
  }

  @media screen and (max-width: 991px) {
    .xiden-logo {
      height: 36px;
      margin-top: 0.6rem;
    }
  }

  //zona media 570px
  @media screen and (max-width: 570px) {
    .tabletMenuBtn {
      width: 61px !important;
    }

    .getXDN-btn,
    .getXDN-btn-gold {
      //  background: #161616;
      padding: 10px;
      height: 34px;
      width: 126px;
      //  border: 1.5px solid $blue_color;
      //  border-radius: 21px;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    .xiden-logo {
      // height: 12px;
      width: 100px;
    }

    .NavBar {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .buy-mobile-btn {
      font-size: 14px !important;
    }

    .menu-btn {
      width: 40px !important;
      height: 40px !important;
    }
  }

  @media screen and (max-width: 450px) {
    .xiden-logo {
      width: 85px;
    }
  }

  @media screen and (max-width: 380px) {
    .xiden-logo {
      width: 70px;
    }
  }

  //zona small 320px
  @media screen and (max-width: 320px) {
    .getXDN-btn,
    .getXDN-btn-gold {
      //  background: #161616;
      padding: 8px;
      height: 28px;
      width: 105px;

      //  border: 1.5px solid $blue_color;
      //  border-radius: 21px;
      svg {
        // width: 14px;
        // height: 14px;
        width: 16px;
        height: 16px;
      }
    }

    .buy-mobile-btn {
      // font-size: 10px !important;
      font-size: 12px !important;
    }

    svg {
      height: 17px;
      width: 17px;
    }

    @media screen and (max-width: 450px) {
      .xiden-logo {
        width: 61px;
      }
    }
  }

  //ZONA MENIU MOBILE
  .tabletMenuBtn {
    .menu-collapsed {
      position: absolute;
      top: 80px;
      left: 0px;
      width: 100%;
      height: 0;
      background-color: transparent;
      transition: 0.3s;
      overflow: hidden;
      z-index: 99999 !important;

      @media screen and (max-width: 570px) {
        top: 65px;
      }
    }

    .show-small {
      height: 700px;
    }

    .show-big {
      //height: 210px;
      height: 415px;
    }

    .accordion-button {
      // padding-right: 44px !important;
      // padding-left: 24px;
      padding-right: 2rem !important;
      padding-left: 1.5rem;
      background-color: #161616 !important;
      color: #86868b;
    }

    .meta-realm-accordion-button {
      .accordion-button {
        padding-right: 44px !important;
        padding-left: 24px;
        background-color: #0e014e !important;
        color: #86868b;
      }
    }

    .accordion-body {
      // background-color: #191919 !important;
      color: #86868b;

      &.gray-bg-color {
        background-color: #191919 !important;
      }

      &.blue-bg-color {
        background-color: #0e014e !important;
      }

      a {
        margin-left: 10px;
      }

      a:hover {
        // color: $blue_color;
        color: #d1d1d1;
      }
    }

    .accordion-item {
      // background-color: #161616 !important;
      border: none !important;

      &.gray-bg-color {
        background-color: #161616 !important;
      }

      &.blue-bg-color {
        background-color: #0e014e !important;
      }
    }

    .accordion-button:focus {
      box-shadow: none;
    }

    svg {
      width: 12px;
      height: 12px;
      //  filter: invert(55%) sepia(8%) saturate(172%) hue-rotate(201deg) brightness(95%) contrast(86%);
    }

    .menu-btn {
      background: transparent;
      width: 30px;
      height: 30px;
      border: none;

      svg {
        width: 30px;
        height: 30px;
        transition: 0.2s;

        @media screen and (max-width: 570px) {
          width: 20px;
          height: 20px;
        }
      }
    }

    .button-transition {
      transform: rotate(45deg) !important;
      transition: 0.5s !important;
    }

    // .button-transition-redo {
    //    // transform: rotate(0deg) !important;
    //    // transition: 0.5s !important;
    // }
  }
}

.accordion-button {
  .button-transition {
    transform: rotate(45deg) !important;
  }
}

.accordion-button.collapsed {
  .button-transition {
    transform: rotate(0deg) !important;
  }
}

.nav-item {
  cursor: pointer;
}

.activeLink {
  .nav-link,
  .nav-item a,
  .accordion-button,
  svg {
    color: #22aaff !important;
  }
}

.yellowActiveLink {
  .nav-link,
  .nav-item a,
  .accordion-button,
  svg {
    color: #fda544 !important;
  }
}

.container-nav .navigation-menu div {
  background: unset !important;
}

.container-nav .dropdown-menu.show {
  background: rgba(41, 39, 39, 0.85) !important;
  // background: rgba(19, 4, 95, 0.85) !important;
}

.meta-dropdown {
  .dropdown-menu.show {
    background: rgba(19, 4, 95, 0.85) !important;
    border: 1px solid #070029;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: unset !important;
}

.container-nav .navigation-menu .nav-item:focus,
.dropdown-item:focus,
.nav-link:focus,
.container-nav .navigation-menu .nav-item:active,
.dropdown-item:active,
.nav-link:active {
  outline: none !important;
  background-color: unset !important;
  box-shadow: none !important;
  border: none !important;
}

.mining-competition-banner {
  background-color: rgba(34, 170, 255, 0.9);
  position: absolute;
  top: 80px;
  left: 0;
  padding-block: 0.25rem;
  cursor: pointer;

  @media screen and (max-width: 570px) {
    top: 65px;
  }

  .font-size-24 {
    line-height: 1;
    font-size: 24px;

    @media screen and (max-width: 1200px) {
      font-size: 22px;
    }

    @media screen and (max-width: 991px) {
      font-size: 20px;
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }

    @media screen and (max-width: 580px) {
      font-size: 16px;
    }

    @media screen and (max-width: 350px) {
      font-size: 14px;
    }
  }
}
