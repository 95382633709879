#booster-section {
  .corners {
    height: 557px;
    padding: 1.5rem;
    margin-bottom: 0;
    margin-top: 0;

    @media screen and (max-width: 1470px){
      height: 100%;
    }
  }

  .booster-container {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  .info-col,
  .image-col {
    flex: 1;
  }

  .info-col {
    margin-right: 2rem;
    max-width: 500px;
    p {
      line-height: 1.1;
      margin-bottom: 53px;
    }
    h2 {
      margin-bottom: 31px;
    }

    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 3rem;
      margin-left: auto;
      margin-right: auto;
      p, h2{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      .button-container{
        justify-content: center;
      }
    }
  }

  .booster-image {
    width: 100%;
    max-width: 600px;
    height: auto;

    @media screen and (max-width: 767px) {
      width: 70%;
    }
   
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .paragraph-container {
    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 600px;
      margin: auto;
    }
  }

  .button-container {
    width: 100%;
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .buttonBlue {
    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }
}
