.ecosystem-slider-section {
  overflow: hidden;
  @media screen and (max-width: 991px) {
    padding-bottom: 0px;
  }

  .slider-col-height {
    min-height: 480px;
  }

  .gradient1 {
    background: -webkit-linear-gradient(#20deb0 30%, #22aaff 70%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient2 {
    background: -webkit-linear-gradient(#af4aff 30%, #477bff 70%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient3 {
    background: -webkit-linear-gradient(#f9ca53 30%, #ee694b 70%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (max-width: 991px) {
    height: auto;
    .row {
      --bs-gutter-x: 0;
    }
  }

  .container-main {
    @media screen and (max-width: 404px) {
      height: fit-content;

      .position-relative {
        padding-top: 10px !important;
      }
    }
  }

  .buttons-flex-direction {
    @media screen and (max-width: 320px) {
      flex-direction: column !important;
      button:first-child {
        margin-right: 0 !important;
        margin-bottom: 15px;
      }
      button:last-child {
        margin-left: 0 !important;
      }
    }
  }

  .properties-container {
    @media screen and (max-width: 991px) {
      max-width: 530px;
      h4 {
        padding-bottom: 0 !important;
        line-height: 1;
      }
      .container-values {
        height: 40px;
      }
    }
    @media screen and (max-width: 340px) {
      flex-direction: column !important;
      align-items: flex-start !important;
      margin-top: 1rem !important;
      h4 {
        margin-bottom: 0;
        margin-top: 1rem;
      }
    }
  }
  .slider-col {
    min-height: 650px;
    @media screen and (max-width: 600px) {
      min-height: 550px;
    }
  }

  .active-box-title {
    width: 166px;
  }
  .bottom-0 {
    //bottom: -50px;
    position: absolute;
  }
  .pos-absolute-slider {
    height: 580px;
    min-height: 580px;
    position: relative;
    @media screen and (max-width: 991px) {
      height: 540px;
      min-height: 540px;
    }
    @media screen and (max-width: 600px) {
      height: 580px;
      min-height: 580px;
    }
    @media screen and (max-width: 450px) {
      min-height: 490px;
      height: 490px;
    }
  }

  @media screen and (min-width: 992px) {
    .pos-absolute-slider {
      position: absolute;
      top: 50% !important;
      transform: translate(0, -50%);
      width: 100%;
      left: 60px;
    }
  }

  .slick-track {
    height: 100%;
  }

  @media screen and (min-width: 1470px) {
    .pos-absolute-slider {
      width: 128%;
    }
  }

  .what-contianer {
    display: flex;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  .info-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 991px) {
      align-items: center;
      margin-right: 0;
    }
  }

  .buttons-container {
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
  }

  .coloana-stanga {
    padding-right: 5rem;
    @media screen and (max-width: 991px) {
      padding-right: 0;
    }
  }

  .slider-box {
    position: relative;
    height: 293px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #161616;
    padding: 2rem 3rem;
    margin: 1rem;
    border-radius: 16px;
    transition: all 0.2s ease-in-out;
    transition-delay: 0.5s;

    @media screen and (max-width: 600px) {
      margin: 1rem;
    }
    @media screen and (max-width: 350px) {
      padding: 2rem;
    }
  }

  .title-dot {
    color: #86868b;
  }

  .slider-image {
    position: absolute;
  }

  .active-box-title {
    font-size: 32px;
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
  }

  .slick-active {
    .slider-box {
      background-image: url("../../../assets/images/png/slide-box-bg.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 350px;
      overflow: visible;
      @media screen and (max-width: 450px) {
        height: 325px;
      }

      .title-dot {
        color: #22aaff;
      }
    }
  }

  .slick-slide {
    min-height: 480px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 991px) {
      min-height: 500px;
    }
    @media screen and (max-width: 450px) {
      min-height: 450px;
    }
  }

  .slick-list {
    overflow: hidden;
  }
  .slick-track {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .slick-list {
    @media screen and (max-width: 991px) {
      max-height: 535px;
    }
  }

  .apply-effect {
    animation: fadeEffect ease-in 0.5s forwards;
  }

  @keyframes fadeEffect {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }

  .basm-icon {
    top: -50px;
    max-width: unset;
    width: 90%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .pc-icon {
    top: -100px;
    max-width: unset;
    left: 50%;
    transform: translate(-50%, 0);
    width: 90%;
  }
  .router-icon {
    top: -80px;
    max-width: unset;
    left: 50%;
    transform: translate(-50%, 0);
    width: 55%;
  }
  .hidr-icon {
    width: 40%;
    max-width: unset;
    top: -80px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
