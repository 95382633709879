.xidenfeatures-page{
    .description-991{
        @media screen and (max-width: 991px){
           width: 60%;
        }
        @media screen and (max-width: 767px){
           width: 80%;
        }
        @media screen and (max-width: 600px){
           width: 90%;
        }
        @media screen and (max-width: 500px){
           width: 100%;
        }
    }
    h2{
        line-height: 1;
    }
}