.footer-section {
  //   background-color: #000000;

  .discord-icon {
    color: #fff;
    cursor: pointer;
    margin-left: 30px;
    width: 25px;
    height: 20px;

    @media screen and (max-width: 525px) {
      margin-left: 0;
    }
  }

  .no-hov-effect-a {
    text-decoration: none;
  }

  .no-hov-effect-a:hover {
    color: #86868b;
    text-decoration: none;
  }

  &.gray-bg-color {
    background: #000000;
  }

  &.blue-bg-color {
    background: #070029;
  }

  .mb-10px {
    margin-bottom: 10px;
  }

  ul {
    padding: 0px !important;
  }

  ul > li {
    list-style-type: none;
    cursor: pointer;
  }

  // .privacy-policy {
  //   border-right: 2px solid #22aaff;
  //   padding-right: 10px;
  // }

  // .user-agreements {
  //   border-left: 2px solid #22aaff;
  //   padding-left: 10px;
  // }

  .utility-space {
    width: 300px;
  }

  .utility-space-two {
    width: 175px;
  }

  .fixed-width {
    width: 90%;
  }

  .margin-top-bottom {
    margin-top: 29px;
    margin-bottom: 32.5px;
  }

  .footer-title {
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .footer-final-section {
    border-top: 1px solid #333333;
    padding-top: 27.5px;
    margin-bottom: 31px;
  }

  .logo-container-xid {
    .xiden-logo {
      width: 165px;
      height: 42px;

      @media screen and (max-width: 525px) {
        width: 220px;
        height: 50px;
        margin-bottom: 1rem;
      }
      @media screen and (max-width: 370px) {
        width: 210px;
        height: 45px;
      }
    }
    @media screen and (max-width: 700px) {
      flex-direction: column !important;
    }

    .moto-gp-logo {
      width: 182px;
      height: 48px;
      @media screen and (max-width: 700px) {
        margin: 1rem 0 1rem 0 !important;
      }

      @media screen and (max-width: 525px) {
        margin: 0 0 1rem 0 !important;
        width: 260px;
        height: 60px;
      }
      @media screen and (max-width: 370px) {
        margin: 0 0 1rem 0 !important;
        width: 210px;
        height: 45px;
      }
    }
  }

  .social-media {
    width: 342px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @media screen and (max-width: 850px) {
      width: auto;
    }

    img {
      margin-left: 30px;
    }

    button {
      border: none;
      background: transparent;
    }
  }

  .sitemap-and-language {
    opacity: 0;
  }
}

.accordion-button::after {
  display: none !important;
}

.accordion-button {
  background-color: white;
}

.button-transition {
  transform: rotate(45deg);
  transition: 0.5s;
}

.mobile-footer {
  display: none !important;
}

.accordion-button {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}

.accordion-footer {
  display: none !important;
}

.accordion-button {
  background-color: #000000 !important;
  color: #86868b !important;
}

.accordion-body {
  background-color: #000000 !important;
  color: #86868b !important;
  padding: 0px !important;
  padding-left: 35px !important;
}

.accordion-item {
  background-color: #000000 !important;
  border: none !important;
}

.accordion-header:focus {
  border-color: #000000 !important;
}

// svg {
//   width: 12px;
//   height: 12px;
// }

.accordion-body {
  li {
    list-style-type: none;
    cursor: pointer;
  }
}

// media queries ______________________________________________________________________

@media screen and (max-width: 1000px) {
  .footer-visibility {
    display: none !important;
  }

  .accordion-footer {
    display: inherit !important;
  }

  .mobile-footer {
    display: inherit !important;
  }

  li:hover {
    color: #fff !important;
    transition: 0s;
  }

  .accordion-header {
    svg {
      width: 12px;
      height: 12px;
    }
  }

  .accordion-button:hover {
    color: #fff !important;
  }
}

@media screen and (max-width: 886px) {
  .footer-final-section {
    flex-direction: column-reverse !important;
    align-items: center !important;
  }

  .mb-10px {
    margin-bottom: 5px !important;
  }
}

@media (max-width: 525px) {
  .footer-title {
    flex-direction: column !important;
    align-items: center;
  }

  .social-media {
    width: 100% !important;
    justify-content: center !important;
  }

  .footer-logo {
    margin-bottom: 30px;
  }
}

// @media screen and (max-width: 445px) {
//    .utility-space {
//       width: 300px !important;
//    }
// }

@media screen and (max-width: 525px) {
  .footer-section .social-media img {
    margin-left: 0px;
  }

  .social-media {
    display: flex;
    justify-content: space-around !important;
  }
}

@media screen and (max-width: 412px) {
  .utility-space {
    width: 100% !important;
  }

  .footer-list img {
    display: none;
  }

  .utility-space {
    flex-direction: column;
    align-items: center;
  }

  .privacy-policy {
    border-right: 2px solid #22aaff;
    margin-bottom: 10px;
  }

  .terms-of-use {
    border-right: 2px solid #22aaff;
    margin-bottom: 10px;
  }

  .sitemap-icon {
    margin-right: 10px;
  }

  .lang-icon {
    margin-right: 10px;
  }

  .utility-space-two {
    width: 183px !important;
  }

  .footer-section {
    .privacy-policy,
    .terms-of-use {
      border-right: none;
    }
  }
}

.meta-realm {
  .accordion-button,
  .accordion-body,
  &.accordion-item {
    background-color: #070029 !important;
  }

  h2 {
    margin-bottom: 0 !important;
  }
}

.coin-market-svg {
  width: 20px;
  height: 20px;
}
