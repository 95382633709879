.transparent-card {
  width: 100%;
  max-width: 268px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.95);

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }

  @media screen and (max-width: 767px) {
    background-color: #161616;
  }

  .span-text-size {
    font-size: 16px;

    @media screen and (max-width: 1360px) {
      font-size: 14px;
    }
    @media screen and (min-width: 992px) and (max-width: 1250px) {
      font-size: 12px;
    }
  }

  .heading-text-size {
    font-size: 32px;

    @media screen and (max-width: 1450px) {
      font-size: 28px;
    }
    @media screen and (max-width: 1360px) {
      font-size: 20px;
    }
    @media screen and (min-width: 992px) and (max-width: 1250px) {
      font-size: 19px;
    }
  }
}

.data-container {
  width: 100%;
  max-width: 268px;
  min-height: 120px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.95);
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 991px) {
    max-width: 100%;
    margin-bottom: 12px;
  }

  @media screen and (max-width: 767px) {
    background-color: #161616;
  }

  @media screen and (max-width: 410px) {
    padding: 1rem;
    min-height: 180px;
  }

  @media screen and (max-width: 385px) {
    gap: 0.5rem !important;
  }

  .uppertitle-text-size {
    font-size: 16px;

    @media screen and (max-width: 1360px) {
      font-size: 14px;
    }
    @media screen and (min-width: 992px) and (max-width: 1250px) {
      font-size: 10px;
    }

    @media screen and (max-width: 385px) {
      font-size: 16px;
    }
  }

  .profit-text-size {
    font-size: 32px;

    @media screen and (max-width: 1450px) {
      font-size: 28px;
    }
    @media screen and (max-width: 1360px) {
      font-size: 20px;
    }
    @media screen and (min-width: 992px) and (max-width: 1250px) {
      font-size: 19px;
    }
    @media screen and (max-width: 575px) {
      font-size: 18px;
    }

    @media screen and (max-width: 385px) {
      font-size: 18px;
    }
  }

  .private-container {
    border: 1px solid #22aaff;
    padding: 10px;
    border-radius: 16px;
    min-width: 180px;
    @media screen and (max-width: 880px) {
      flex-basis: 45%;
      margin-bottom: 0.5rem;
    }
    @media screen and (max-width: 470px) {
      min-width: 145px;
      padding: 7px;
      flex-basis: 48%;
      margin-bottom: 0.5rem;

      &:last-child,
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 385px) {
      flex-basis: 100%;
      margin-bottom: 0;
    }
  }
}
