.feature-card-mobile {
  width: 100%;
  max-width: 340px;
  height: 400px;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  border-radius: 16px;
  background-color: #161616;
  margin-bottom: 1.5rem;
  overflow: hidden;

  @media screen and (max-width: 1450px) {
    padding: 2rem;
    min-width: 300px;
  }

  @media screen and (max-width: 991px) {
    max-width: unset !important;
    height: 350px;
  }

  @media screen and (max-width: 767px) {
    min-width: 250px;
    height: 300px;
  }
  @media screen and (max-width: 310px) {
    min-width: 210px;
    height: 270px;
  }

  .card-content {
    margin-top: 4rem;

    @media screen and (min-width: 1201px) and (max-width: 1399px) {
      margin-top: 3rem;
    }
  }

  .feature-card-title {
    font-size: 35px;

    @media screen and (max-width: 1380px) {
      font-size: 32px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 28px;
    }

    @media screen and (max-width: 991px) {
      max-width: 190px;
      font-size: 26px;
    }
    @media screen and (max-width: 580px) {
      max-width: 190px;
      font-size: 24px;
    }
    @media screen and (max-width: 310px) {
      font-size: 20px;
    }
  }
}

.internet-card-bg {
  background-image: url("../../../../assets/images/png/small-cards/internet-card-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.connection-card-bg {
  background-image: url("../../../../assets/images/png/small-cards/connection-card-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.security-card-bg {
  background-image: url("../../../../assets/images/png/small-cards/security-card-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.price-card-bg {
  background-image: url("../../../../assets/images/png/small-cards/price-card-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
