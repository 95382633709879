.mh-432 {
  max-height: 432px;
}

.krater-guide-steps-section {
  .text-start {
    margin: unset !important;
    max-width: 850px !important;

    @media screen and (max-width: 767px) {
      margin: auto !important;
    }
  }
}

.link-redirect {
  text-decoration: none !important;
}

.pools-link-support {
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #af4aff !important;
  }
}

.step-1-card {
  height: auto;
}

.krater-app-steps-section {
  .corners {
    height: auto !important;
  }
}

#guardian-steps-section {
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }

  p {
    text-align: start !important;

    @media screen and (max-width: 767px) {
      text-align: center !important;
    }
  }

  .cyan-color {
    color: cyan;
  }

  .red-color {
    color: red;
  }
  .green-color {
    color: green;
  }

  .tutorial-link {
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      color: #af4aff;
    }
  }

  .warning-svg {
    width: 23px;
    height: 23px;
    color: orange;
  }
  .info-svg {
    width: 23px;
    height: 23px;
    color: #22aaff;
  }

  .tutorial-link-pools {
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      color: #ffffff;
    }
  }
}
