#what-contains {
  background-color: black;
  line-height: 1.3;

  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }

  .buttonBlue {
    svg {
      margin-left: 10px;
    }
  }

  .row {
    margin-bottom: 58px;

    @media screen and (max-width: 991px) {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .coloana {
    p {
      width: 586px;
    }
  }

  .present-photo {
    margin: 0 50px;
  }

  .present-photo-left {
    transform: translateX(-100px);
  }

  h2 {
    margin-bottom: 8rem;
    line-height: 1;
  }

  h4 {
    line-height: 1;
  }

  .coloana-img {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  @media screen and (max-width: 1875px) {
    img {
      max-width: 100%;
      height: auto;
      width: 80%;
    }
    .present-photo {
      margin: 0;
    }
    .present-photo-left {
      transform: none;
    }
  }

  @media screen and (max-width: 1480px) {
    .coloana {
      p {
        max-width: 586px;
        width: unset;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .coloana-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      margin-top: 2rem;
    }

    .present-photo-left,
    .present-photo {
      width: 70% !important;
    }

    button {
      margin-left: 0;
    }
    h2 {
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 767px) {
    .present-photo-left,
    .present-photo {
      width: 50% !important;
    }

    .mobile-column-reverse {
      flex-direction: column-reverse !important;
      h2,
      h4,
      p {
        text-align: center;
        margin: auto;
      }
      button {
        margin: auto;
      }
      .xideen-upper-title {
        margin: 1rem 0 0 0;
        justify-content: center !important;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .present-photo-left,
    .present-photo {
      width: 60% !important;
    }
  }

  @media screen and (max-width: 480px) {
    p {
      margin-bottom: 1.5rem !important;
      width: unset !important;
    }

    .present-photo-left,
    .present-photo {
      width: 70% !important;
    }
  }
  @media screen and (max-width: 400px) {
    .present-photo-left,
    .present-photo {
      width: 80% !important;
    }
  }
  @media screen and (max-width: 350px) {
    .present-photo-left,
    .present-photo {
      width: 100% !important;
    }
  }
}
