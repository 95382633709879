#sdr-smart-device {
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  .horizontal-line {
    width: fit-content;
    width: -moz-fit-content;
  }

  .read-more-link {
    // width: 160px;
    width: fit-content;
    width: -moz-fit-content;
    color: #22aaff;
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.2s;
    text-decoration: none;

    svg {
      margin-left: 10px;
    }

    &:hover {
      color: white;
    }

    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }

  .smart-devices-description {
    max-width: 1000px;
    width: 100%;
  }

  .smart-devices-title-1 {
    padding-right: 11rem;

    @media screen and (max-width: 1200px) {
      padding-right: 8rem;
    }

    @media screen and (max-width: 767px) {
      padding-right: 0;
    }
  }

  .smart-devices-title-2 {
    padding-left: 11rem;

    @media screen and (max-width: 1200px) {
      padding-left: 8rem;
    }

    @media screen and (max-width: 767px) {
      padding-left: 0;
    }
  }

  .custom-text {
    @media screen and (max-width: 767px) {
      text-align: center;
      max-width: 500px;
      width: 100%;
    }
  }

  .content-image {
    @media screen and (max-width: 767px) {
      max-width: 300px;
      width: 100%;
    }
  }

  .smart-devices-2 {
    mix-blend-mode: luminosity;
  }
}

// #sdr-smart-device {

//     .font-size-28 {
//         font-size: 28px;
//         @media screen and (max-width: 1200px) {
//            font-size: 26px;
//         }
//         @media screen and (max-width: 991px) {
//            font-size: 24px;
//         }
//         @media screen and (max-width: 600px) {
//            font-size: 20px;
//         }
//      }
//     // .integrated {
//     //     margin-left: 350px;

//     // }
//     .sdr-text-container {
//         max-width: 1000px;

//     }

//         .horizontal-line{
//             width: fit-content;
//             width: -moz-fit-content;
//         }

//         .custom-text{

//             max-width: 600px;

//             @media screen and (max-width: 991px){
//                 text-align: center;
//             }
//         }

//         .connectivity-container {
//             margin-left: 4rem;
//         }

//         .content-image {

//             @media screen and (max-width: 1550px) {
//                 max-width: 150px;
//             }
//             @media screen and (max-width: 1310px) {
//                 max-width: 100px;
//             }
//             @media screen and (max-width: 991px) {
//                 max-width: 250px;

//             }
//             @media screen and (max-width: 767px) {
//                 max-width: 180px;

//             }
//             @media screen and (max-width: 530px) {
//                 max-width: 150px;
//             }
//             @media screen and (max-width: 470px) {
//                 max-width: 100px;
//             }
//             @media screen and (max-width: 350px) {
//                 max-width: 80px;
//             }
//             @media screen and (max-width: 308px) {
//                 max-width: 60px;
//             }

//         }

//         .stacking-container, .connectivity-container {
//             @media screen and (max-width: 991px) {
//                 margin-left: 0px !important;

//                 padding: 0px !important;
//             }
//         }

//         .read-more-link {
//             // width: 160px;
//             width: fit-content;
//             width: -moz-fit-content;
//             color: #22aaff;
//             background: transparent;
//             border: none;
//             outline: none;
//             display: flex;
//             justify-content: flex-start;
//             align-items: center;
//             transition: 0.2s;

//             svg {
//                margin-left: 10px;
//             }

//             &:hover {
//                color: white;
//             }

//             @media screen and (max-width: 767px) {
//                justify-content: center;
//             }
//          }

//          .horizontal-first-img {
//              @media screen and (max-width: 991px) {
//                  display: none;
//              }
//          }

// }
