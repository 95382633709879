.krater-support-guide-section {
  .buttonOutlineBlue {
    min-width: 220px !important;
    width: 100% !important;
    padding: 12px !important;

    @media screen and (max-width: 1200px) {
      span {
        font-size: 18px !important;
      }

      height: 60px !important;
    }

    @media screen and (max-width: 767px) {
      span {
        font-size: 16px !important;
      }
      height: 50px !important;
    }
  }

  .col {
    @media screen and (max-width: 1300px) {
      padding-left: 0px !important;
    }
    @media screen and (max-width: 991px) {
      padding-right: 0px !important;
    }
  }

  .krater-row {
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
  }
  .krater-col {
    @media screen and (max-width: 991px) {
      max-width: 220px;
    }
  }

  .hero-img {
    @media screen and (max-width: 991px) {
      width: 250px !important;
    }
    @media screen and (max-width: 350px) {
      width: 220px !important;
    }
  }

  .krater-img {
    @media screen and (max-width: 991px) {
      max-width: 400px !important;
    }
    @media screen and (max-width: 767px) {
      max-width: 300px !important;
    }
    @media screen and (max-width: 567px) {
      max-width: 100px !important;
    }
    @media screen and (max-width: 350px) {
      max-width: 85px !important;
    }
  }
}
