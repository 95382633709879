#human-cards-section {
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  .column-wrapper {
    border-radius: 24px;
    background-color: #161616;
    height: 600px;
    max-width: 586px;
    min-height: 450px;

    @media screen and (max-width: 1100px) {
      height: 500px;
    }

    @media screen and (max-width: 991px) {
      min-height: unset;
      max-width: unset;
      height: auto;
    }
  }

  .minimum-height {
    min-height: 420px;
    @media screen and (max-width: 991px) {
      min-height: unset;
    }
  }

  .custom-flex-1 {
    @media screen and (max-width: 370px) {
      flex-direction: column-reverse !important;
    }
  }

  .custom-flex-2 {
    @media screen and (max-width: 370px) {
      flex-direction: column !important;
    }
  }

  background-image: url("../../../assets/images/png/private-network-page/card-bg-spectralis2.png");
  background-repeat: no-repeat;
  background-position: calc(50% - 10px) 50%;

  @media screen and (max-width: 1400px) {
    background-image: none;
  }

  .svg-img {
    @media screen and (max-width: 768px) {
      width: 55px;
      height: 55px;
    }
    @media screen and (max-width: 450px) {
      width: 35px;
      height: 35px;
    }
  }
  .btn-get-started {
    border: none;
    background-color: transparent;
    transition: 0.2s;
    min-width: 150px;
    svg {
      margin-left: 10px;
    }
  }

  .btn-get-started:hover {
    color: white;
  }

  .text-right {
    text-align: right;

    @media screen and (max-width: 370px) {
      text-align: start;
    }
  }

  .bg-card {
    background-image: url("../../../assets/images/png/private-network-page/card-bg-spectralis.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .custom-padding {
    @media screen and (max-width: 991px) {
      padding: 0;
    }
  }

  .card-img {
    @media screen and (max-width: 991px) {
      max-width: 220px;
    }

    @media screen and (max-width: 650px) {
      max-width: 180px;
    }
    @media screen and (max-width: 370px) {
      width: 140px;
      margin-bottom: 2rem;
    }
  }

  .custom-align {
    @media screen and (max-width: 370px) {
      align-items: start !important;
    }
  }

  .custom-min-width {
    @media screen and (max-width: 370px) {
      min-width: 200px;
    }
  }
}
