.calculator-section {
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  .reward-container {
    display: flex;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  .info-col,
  .calculator-col {
    flex: 1;
  }

  .line-height-1 {
    line-height: 1;
  }

  .paragraph-container {
    max-width: 590px;
  }

  .info-col {
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 991px) {
      margin-right: 0;
      flex-direction: row;
      align-items: center;
    }
    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .calculator-img {
    @media screen and (max-width: 991px) {
      max-width: 300px;
      margin-right: 24px;
      margin-bottom: 0 !important;
    }
    @media screen and (max-width: 650px) {
      max-width: 250px;
    }
    @media screen and (max-width: 580px) {
      max-width: 200px;
    }
    @media screen and (max-width: 500px) {
      max-width: 80%;
      margin-right: 0;
    }
    @media screen and (max-width: 400px) {
      max-width: 90%;
    }
  }
  @media screen and (max-width: 991px) {
    .reward-calculator {
      margin-bottom: 0;
    }
  }
}
