#hero-section-staking {
  position: relative;

  .highlight-content {
    z-index: 99;
  }

  .consensus-uppertitle-container {
    margin: 10px 0 15px;
  }

  .page-section {
    padding-top: 10rem;
    padding-bottom: 8rem;
    overflow: hidden;

    @media screen and (max-width: 1207px) {
      padding-bottom: 5rem;
    }

    @media screen and (max-width: 991px) {
      padding-bottom: 0;
      padding-top: 20rem;
    }
    @media screen and (max-width: 768px) {
      padding-top: 16rem;
    }
  }

  h2 {
    line-height: 1;
  }

  .staking-hero-img {
    @media screen and (max-width: 991px) {
      max-width: 50%;
      margin: auto;
    }
    @media screen and (max-width: 650px) {
      max-width: 70%;
    }
    @media screen and (max-width: 450px) {
      max-width: 100%;
    }
  }

  .buttons-flex-direction {
    @media screen and (max-width: 400px) {
      flex-direction: row;
      align-items: center !important;
      padding-top: 0 !important;
    }
  }

  .buttonBlue {
    @media screen and (max-width: 991px) {
      margin-right: 0px;
    }
  }

  .z-index-1 {
    z-index: 1;
  }

  .video-container {
    overflow: hidden;
  }

  .staking-hero-video {
    width: 1900px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 0;

    @media screen and (max-width: 1400px) {
      width: 1700px;
    }
    @media screen and (max-width: 1230px) {
      width: 1400px;
      top: 200px;
    }
    @media screen and (max-width: 1200px) {
      width: 1400px;
      top: 70px;
      left: 70%;
      transform: translate(-70%, 0);
    }
    @media screen and (max-width: 1110px) {
      top: 100px;
    }
    @media screen and (max-width: 1100px) {
      top: 90px;
    }
    @media screen and (max-width: 991px) {
      width: 800px;
      top: -80px;
      left: 50%;
    }
    @media screen and (max-width: 768px) {
      width: 600px;
      top: -60px;
    }
  }
}
