.xiden-page {
   background-color: #000;

   .description-991 {
      @media screen and (max-width: 991px) {
         max-width: 60%;
      }
      @media screen and (max-width: 767px) {
         max-width: 75%;
      }
      @media screen and (max-width: 600px) {
         max-width: 90%;
      }
      @media screen and (max-width: 450px) {
         max-width: 100%;
      }
   }

   .ecosystem-heading-section {
      @media screen and (max-width: 767px) {
         width: 100% !important;
         max-width: 600px !important;
      }
   }
}
