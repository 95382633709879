#private-network-section {
  @media screen and (max-width: 991px) {
    padding-bottom: 0;
  }
  padding-top: 1rem;
  background-color: #000;

  .main-col {
    margin-bottom: 0;
    margin-right: 0;
  }

  .container-main {
    z-index: 1;
  }

  .section-image {
    max-width: 681px;

    @media screen and (max-width: 1220px) {
      max-width: 481px;
    }

    @media screen and (max-width: 991px) {
      max-width: 420px;
    }

    @media screen and (max-width: 900px) {
      max-width: 381px;
    }

    @media screen and (max-width: 767px) {
      max-width: 341px;
    }

    @media screen and (max-width: 675px) {
      max-width: 300px;
    }
    @media screen and (max-width: 585px) {
      max-width: 250px;
    }
  }
  .hero-section-container .section-image-col {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 525px) {
      flex-direction: column;
    }
  }

  br {
    @media screen and (max-width: 525px) {
      display: none;
    }
  }

  .spectralis-title {
    @media screen and (max-width: 525px) {
      text-align: center;
      margin-top: 1rem;
    }
  }

  .buttonBlue {
    margin-right: 0;
  }

  .network-icon {
    width: 100%;
    max-width: 280px;

    @media screen and (max-width: 460px) {
      max-width: 150px;
    }
  }

  .icon-5g {
    @media screen and (max-width: 991px) {
      max-width: 220px;
    }
    @media screen and (max-width: 767px) {
      max-width: 200px;
    }

    @media screen and (max-width: 660px) {
      margin-right: 1rem !important;
      max-width: 180px;
    }
    @media screen and (max-width: 460px) {
      max-width: 150px;
    }

    @media screen and (max-width: 380px) {
      max-width: 140px;
      margin-right: 0.25rem !important;
    }
    @media screen and (max-width: 320px) {
      max-width: 120px;
    }
    @media screen and (max-width: 290px) {
      max-width: 110px;
    }
  }

  .icon-2g {
    @media screen and (max-width: 991px) {
      max-width: 220px;
    }

    @media screen and (max-width: 767px) {
      max-width: 200px;
    }
    @media screen and (max-width: 660px) {
      margin-left: 1rem !important;
      max-width: 180px;
    }

    @media screen and (max-width: 460px) {
      max-width: 150px;
    }
    @media screen and (max-width: 380px) {
      max-width: 140px;
      margin-left: 0.25rem !important;
    }
    @media screen and (max-width: 320px) {
      max-width: 120px;
    }
    @media screen and (max-width: 290px) {
      max-width: 110px;
    }
  }

  .pe-custom {
    padding-right: 11rem;

    @media screen and (max-width: 991px) {
      padding-right: 0;
    }
  }

  .ps-custom {
    padding-left: 10rem;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }

  .hotspot-titles {
    max-width: 750px;

    @media screen and (max-width: 500px) {
      max-width: 310px !important;
    }
    @media screen and (max-width: 400px) {
      max-width: 280px !important;
    }
  }
}
